@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap");

.kr-embedded .kr-pan,
.kr-embedded .kr-field.kr-text,
.kr-embedded .kr-field.kr-checkbox,
.kr-embedded .kr-expiry,
.kr-embedded .kr-security-code,
.kr-embedded .kr-identity-document-number,
.kr-embedded .kr-card-holder-name,
.kr-embedded .kr-card-holder-mail,
.kr-embedded .kr-identity-document-type,
.kr-embedded .kr-installment-number,
.kr-embedded .kr-first-installment-delay,
.kr-embedded > .kr-field-wrapper {
 height: auto;
 background-color: #fff;
 border: none;
 border-radius: 10px;
 padding: 8px 0px;
 margin: 13px 0px;
}

.kr-embedded[krvue] .kr-pan,
.kr-embedded[krvue] .kr-expiry,
.kr-embedded[krvue] .kr-security-code {
 background-color: #fff;
 padding: 9px 1px;
 border-radius: 10px;
 height: auto;
 border-color: #fff;
 margin: 12px 0px;
}

@media (max-width: 400px) {
 .selectpais {
  height: 40px;
 }
}
