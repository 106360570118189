@font-face {
    font-family: 'avenirblack';
    src: url('AvenirLTStd-Black.woff') format('woff');
    font-weight:normal;
    font-style: normal;
}


@font-face {
    font-family: 'avenirbook';
    src: url('AvenirLTStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'avenirroman';
    src: url('AvenirLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}