/* CSS Document */

/**

====================================================================
  Loading Transition
====================================================================

 ***/
.ctn-preloader {
 align-items: center;
 -webkit-align-items: center;
 display: flex;
 display: -ms-flexbox;
 height: 100%;
 justify-content: center;
 -webkit-justify-content: center;
 position: fixed;
 left: 0;
 top: 0;
 width: 100%;
 z-index: 999999;
 background: #fff;
}

.ctn-preloader .animation-preloader {
 position: absolute;
 z-index: 100;
 text-align: center;
}

.ctn-preloader .animation-preloader .icon {
 display: inline-block;
 position: relative;
}

.ctn-preloader .animation-preloader .icon span {
 animation: spinner 1.5s infinite linear;
 border-radius: 50%;
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 z-index: 1;
}

.ctn-preloader .animation-preloader .icon span:after {
 content: "";
 position: absolute;
 width: 9px;
 height: 9px;
 top: 12px;
 left: 0;
 box-shadow: 0 0 10px var(--red-two);
 border-radius: 50%;
 background: var(--red-one);
}

.ctn-preloader .animation-preloader .txt-loading {
 user-select: none;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
 animation: letters-loading 4s infinite;
 color: var(--heading);
 content: attr(data-text-preloader);
 left: 0;
 opacity: 0;
 top: 0;
 line-height: 70px;
 position: absolute;
}

.ctn-preloader .animation-preloader .txt-loading .letters-loading {
 font-family: "Recoleta";
 font-weight: 500;
 letter-spacing: 15px;
 display: inline-block;
 color: rgb(0 0 0 / 14%);
 position: relative;
 font-size: 70px;
 line-height: 70px;
}

.ctn-preloader
 .animation-preloader
 .txt-loading
 .letters-loading:nth-child(2):before {
 animation-delay: 0.2s;
}

.ctn-preloader
 .animation-preloader
 .txt-loading
 .letters-loading:nth-child(3):before {
 animation-delay: 0.4s;
}

.ctn-preloader
 .animation-preloader
 .txt-loading
 .letters-loading:nth-child(4):before {
 animation-delay: 0.6s;
}

.ctn-preloader
 .animation-preloader
 .txt-loading
 .letters-loading:nth-child(5):before {
 animation-delay: 0.8s;
}

.ctn-preloader
 .animation-preloader
 .txt-loading
 .letters-loading:nth-child(6):before {
 animation-delay: 1s;
}

.ctn-preloader
 .animation-preloader
 .txt-loading
 .letters-loading:nth-child(7):before {
 animation-delay: 1.2s;
}

.ctn-preloader
 .animation-preloader
 .txt-loading
 .letters-loading:nth-child(8):before {
 animation-delay: 1.4s;
}

.loaded .animation-preloader {
 opacity: 0;
 transition: 0.3s ease-out;
}

@keyframes spinner {
 to {
  transform: rotateZ(360deg);
 }
}

@keyframes letters-loading {
 0%,
 75%,
 100% {
  opacity: 0;
  transform: rotateY(-90deg);
 }

 25%,
 50% {
  opacity: 1;
  transform: rotateY(0deg);
 }
}

@media screen and (max-width: 500px) {
 .ctn-preloader .animation-preloader .txt-loading .letters-loading {
  font-size: 40px;
  letter-spacing: 10px;
 }
}

/*^^^^^^^^^^^^^^^^^^^^ Scroll Top ^^^^^^^^^^^^^^^^^^^^^*/
.scroll-top {
 width: 35px;
 height: 35px;
 line-height: 35px;
 position: fixed;
 bottom: 20px;
 right: 5px;
 z-index: 99;
 text-align: center;
 color: #fff;
 font-size: 25px;
 cursor: pointer;
 border-radius: 50%;
 background: var(--red-one);
 transition: all 0.3s ease-in-out;
}

.scroll-top:after {
 position: absolute;
 z-index: -1;
 content: "";
 top: 100%;
 left: 5%;
 height: 10px;
 width: 90%;
 opacity: 1;
 background: radial-gradient(
  ellipse at center,
  rgba(0, 0, 0, 0.25) 0%,
  rgba(0, 0, 0, 0) 80%
 );
}

/*^^^^^^^^^^^^^^^^^^^^ Global Prefix ^^^^^^^^^^^^^^^^^^^^^*/
::-webkit-scrollbar {
 width: 5px;
}

::-webkit-scrollbar-track {
 background: #fff;
}

::-webkit-scrollbar-thumb {
 background: #2a2a2a;
 border-radius: 0;
}

:root {
 --text-color: #292929;
 --heading: #000;
 --red-one: #ff2759;
 --red-two: #fb1b1d;
 --orange-one: #ff6839;
 --deep-cyan: #ebf1ee;
 --light-cyan: #f4fafe;
 --light-blue: #f1f7fd;
 --deep-blue: #ebf6ff;
}

::selection {
 background-color: #2a2a2a;
 color: #fff;
}

body {
 font-family: "gorditaregular";
 font-weight: normal;
 font-size: 18px;
 position: relative;
 line-height: 1.66em;
 color: var(--text-color);
}

.main-page-wrapper {
 overflow-x: hidden;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
 color: var(--heading);
 font-weight: normal;
}

.h1,
h1 {
 font-size: 100px;
}

.h2,
h2 {
 font-size: 52px;
}

.h3,
h3 {
 font-size: 36px;
}

.h4,
h4 {
 font-size: 24px;
 font-family: "gorditamedium";
}

.h5,
h5 {
 font-size: 20px;
}

.h6,
h6 {
 font-size: 15px;
}

::-webkit-input-placeholder {
 color: rgba(0, 0, 0, 0.6);
}

:-ms-input-placeholder {
 color: rgba(0, 0, 0, 0.6);
}

::placeholder {
 color: rgba(0, 0, 0, 0.6);
}

.dark-bg ::-webkit-input-placeholder {
 color: rgba(255, 255, 255, 0.5);
}

.dark-bg :-ms-input-placeholder {
 color: rgba(255, 255, 255, 0.5);
}

.dark-bg ::placeholder {
 color: rgba(255, 255, 255, 0.5);
}

.dark-style ::-webkit-input-placeholder {
 color: rgba(255, 255, 255, 0.5);
}

.dark-style :-ms-input-placeholder {
 color: rgba(255, 255, 255, 0.5);
}

.dark-style ::placeholder {
 color: rgba(255, 255, 255, 0.5);
}

/*^^^^^^^^^^^^^^^^^^^^ Prefix Classes ^^^^^^^^^^^^^^^^^^^^^*/
.tran3s,
.svg path {
 transition: all 0.3s ease-in-out;
}

.tran4s {
 transition: all 0.4s ease-in-out;
}

.tran5s {
 transition: all 0.5s ease-in-out;
}

.tran6s {
 transition: all 0.6s ease-in-out;
}

.style-none {
 list-style: none;
 padding-left: 0;
 margin-bottom: 0;
}

.text-lg {
 font-size: 24px;
 line-height: 1.7em;
}

.cs-screen {
 position: absolute;
}

.shapes {
 position: absolute;
 z-index: -1;
}

.font-recoleta {
 font-family: "Recoleta";
}

.font-zen {
 font-family: "Zen Dots", cursive;
}

.light-bg {
 background-color: #fffbf9;
}

.dark-bg {
 background-color: #121212;
}

.hero-spacing {
 margin: 135px 65px 0;
}

.box-layout {
 padding-left: 65px;
 padding-right: 65px;
}

.slick-dots {
 padding-left: 0;
 margin-bottom: 0;
}

.border-bottom {
 border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.border-top {
 border-top: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.border-left {
 border-left: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.dark-bg .border-bottom,
.dark-bg.border-bottom {
 border-bottom: 1px solid rgba(255, 255, 255, 0.08) !important;
}

.dark-bg .border-top,
.dark-bg.border-top {
 border-top: 1px solid rgba(255, 255, 255, 0.08) !important;
}

.dark-bg .border-left,
.dark-bg.border-left {
 border-left: 1px solid rgba(255, 255, 255, 0.08) !important;
}

.dark-footer.border-top {
 border-top: 1px solid rgba(255, 255, 255, 0.08) !important;
}

/*^^^^^^^^^^^^^^^^^^^^ Section Title ^^^^^^^^^^^^^^^^^^^^^^*/
.title-style-one .upper-title {
 font-size: 16px;
 text-transform: uppercase;
 color: rgba(0, 0, 0, 0.4);
 letter-spacing: 2px;
 padding-bottom: 10px;
}

.title-style-one .title {
 font-family: "Recoleta";
 font-weight: normal;
 font-size: 72px;
 line-height: 1.16em;
 margin-bottom: 0;
}

.title-style-one.white-vr .title {
 color: #fff;
}

.title-style-one.white-vr .upper-title {
 color: rgba(255, 255, 255, 0.5);
}

.title-style-one .title span {
 color: var(--red-one);
 position: relative;
 display: inline-block;
}

.title-style-one .title span:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 3px;
 background: var(--red-one);
 left: 0;
 bottom: 2px;
}

.title-style-two .title {
 font-family: "Recoleta";
 font-weight: normal;
 font-size: 68px;
 line-height: 1.25em;
 margin-bottom: 0;
}

.title-style-two.white-vr .title {
 color: #fff;
}

.title-style-two .title span {
 color: var(--red-one);
 position: relative;
 display: inline-block;
}

.title-style-two .title span:before {
 content: "";
 position: absolute;
 width: 98%;
 height: 3px;
 background: var(--red-one);
 left: 2px;
 bottom: 4px;
}

.title-style-three .title {
 font-family: "Recoleta";
 font-weight: normal;
 font-size: 52px;
 margin-bottom: 0;
}

.title-style-three.white-vr .title {
 color: #fff;
}

.title-style-three .title span {
 color: var(--red-one);
 position: relative;
 display: inline-block;
}

.title-style-three .title span:before {
 content: "";
 position: absolute;
 width: 98%;
 height: 3px;
 background: var(--red-one);
 left: 2px;
 bottom: 4px;
}

.title-style-four .title {
 font-family: "gorditabold";
 font-size: 58px;
 line-height: 1.24em;
 margin-bottom: 0;
}

.title-style-four.white-vr .title {
 color: #fff;
}

.title-style-five .title {
 font-family: "gorditabold";
 font-size: 68px;
 line-height: 1.18em;
 margin-bottom: 0;
}

.title-style-five.white-vr .title {
 color: #fff;
}

.title-style-five .upper-title {
 font-size: 15px;
 text-transform: uppercase;
 color: rgba(0, 0, 0, 0.4);
 letter-spacing: 2px;
 padding-bottom: 12px;
}

.title-style-six .title {
 font-family: "gorditamedium";
 font-size: 58px;
 line-height: 1.24em;
 letter-spacing: 0px;
 margin-bottom: 0;
}

.title-style-six .upper-title {
 font-size: 15px;
 text-transform: uppercase;
 color: rgba(0, 0, 0, 0.4);
 letter-spacing: 3px;
 padding-bottom: 12px;
}

.dark-bg .title-style-six .upper-title,
.dark-bg .title-style-six .title {
 color: #fff;
}

.title-style-seven .title {
 font-family: "gorditablack";
 font-size: 100px;
 line-height: 1em;
 letter-spacing: -2px;
 margin-bottom: 0;
}

.title-style-seven .title span {
 display: inline-block;
 position: relative;
}

.title-style-seven .title span img {
 position: absolute;
 bottom: -45px;
 left: 50%;
 transform: translateX(-50%);
}

.title-style-seven p {
 font-size: 22px;
 line-height: 1.68em;
 padding-top: 80px;
 color: #454545;
}

.title-style-seven.white-vr .title {
 color: #fff;
}

.title-style-seven .title .bg-shape:before {
 content: "";
 width: 100%;
 height: 100%;
 position: absolute;
 left: 0;
 top: 10px;
 background: url(../images/shape/shape_52.svg) no-repeat center;
 background-size: contain;
 z-index: 1;
}

.title-style-eight .title {
 font-family: "gorditablack";
 font-size: 82px;
 line-height: 1.15em;
 letter-spacing: -2px;
 margin-bottom: 0;
}

.title-style-eight.white-vr .title {
 color: #fff;
}

.title-style-eight .title span {
 display: inline-block;
 position: relative;
}

.title-style-eight .title span img {
 position: absolute;
 bottom: -65px;
 left: 50%;
 transform: translateX(-50%);
}

.title-style-nine .title {
 font-family: "avenirblack";
 font-size: 62px;
 line-height: 1.22em;
 letter-spacing: -1px;
 margin-bottom: 0;
}

.title-style-nine .title span {
 color: #8163ff;
}

.title-style-nine.white-vr .title {
 color: #fff;
}

.title-style-nine .upper-title {
 font-size: 28px;
 // text-transform: uppercase;
 color: rgba(0, 0, 0, 0.4);
 letter-spacing: 2px;
 padding-bottom: 18px;
 font-family: "avenirblack";
}

.title-style-ten .upper-title {
 font-size: 13px;
 color: #bdbdbd;
 text-transform: uppercase;
 letter-spacing: 1.4px;
 padding-bottom: 13px;
}

.title-style-ten .title {
 font-family: "gorditamedium";
 font-size: 42px;
 line-height: 1.22em;
 letter-spacing: -1px;
 margin-bottom: 0;
}

.title-style-ten .title span {
 color: var(--red-one);
}

.dark-bg .title-style-ten .upper-title,
.dark-bg .title-style-ten .title {
 color: #fff;
}

.title-style-eleven .upper-title {
 font-size: 20px;
 color: rgba(255, 255, 255, 0.25);
 text-transform: uppercase;
 letter-spacing: 3.6px;
}

.title-style-eleven .title {
 font-family: "Recoleta";
 font-weight: 300;
 font-size: 100px;
 line-height: 1.09em;
 color: #fff;
}

.title-style-twelve .title {
 font-family: "Recoleta";
 font-weight: 300;
 font-size: 80px;
 line-height: 1em;
 margin-bottom: 0;
}

.title-style-twelve .title span {
 font-family: "gorditaregular";
 font-style: italic;
 text-decoration: underline;
 text-decoration-thickness: 5px;
}

.title-style-thirteen .upper-title {
 font-size: 14px;
 font-weight: 500;
 text-transform: uppercase;
 letter-spacing: 1.68px;
 margin-bottom: 10px;
 color: #ff7961;
}

.title-style-thirteen .title {
 font-family: "Zen Dots", cursive;
 font-size: 58px;
 line-height: 1.22em;
 margin-bottom: 0;
}

.title-style-thirteen.white-vr .upper-title,
.title-style-thirteen.white-vr .title {
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Button ^^^^^^^^^^^^^^^^^^^^^^*/
.ripple-btn {
 position: relative;
}

.ripple-btn:before {
 content: "";
 position: absolute;
 width: 100px;
 height: 100px;
 left: 50%;
 top: 50%;
 margin: -50px 0 0 -50px;
 transform: scale(0);
 border-radius: 50%;
 background: rgba(255, 255, 255, 0.5);
}

.ripple-btn:hover:before {
 transform: scale(1);
 opacity: 0;
 transition: all 0.5s ease-in-out;
}

.ripple-btn.theme-btn-one:hover {
 background: #161616;
}

body .theme-btn-one {
 font-family: "avenirblack";
 font-size: 25px;
 color: #fff;
 padding: 5px 60px;
 text-align: center;
 line-height: 58px;
 // background: #161616;
 border-radius: 50px;
 position: relative;
 overflow: hidden;
 transition: all 0.3s ease-in-out;
 background: #8163ff;
}

body .theme-btn-onev2 {
 font-family: "avenirblack";
 font-size: 22px;
 color: #000;
 padding: 0 42px;
 text-align: center;
 line-height: 58px;
 // background: #161616;
 border-radius: 20px;
 position: relative;
 overflow: hidden;
 transition: all 0.3s ease-in-out;
 background: #00fbc8;
 margin: 15px 0 0 30px;
}

body .theme-btn-one.border0 {
 border-radius: 0;
}

body .theme-btn-one.xs {
 font-size: 14px;
 text-transform: uppercase;
}

body .theme-btn-one img {
 vertical-align: 0;
 margin-left: 10px;
 display: inline-block;
}

body .theme-btn-one:active,
// body .theme-btn-one:hover {
//   opacity: 80%;
//   background: var(--red-one);
// }
body .theme-btn-two {
 font-family: "gorditamedium";
 font-size: 17px;
 color: var(--heading);
 padding: 0 40px;
 text-align: center;
 line-height: 51px;
 border: 2px solid var(--heading);
 border-radius: 6px;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-two:hover {
 background: var(--red-one);
 border-color: var(--red-one);
 color: #fff;
}

body .theme-btn-three {
 font-family: "gorditamedium";
 font-size: 18px;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-three.white-vr {
 color: #fff;
}

body .theme-btn-three.btn-color {
 color: var(--red-one);
}

body .theme-btn-three i {
 margin-left: 3px;
 font-size: 17px;
 vertical-align: -1px;
}

body .theme-btn-three:hover,
body .theme-btn-three.white-vr:hover {
 color: var(--red-one);
 text-decoration: underline;
}

body .theme-btn-four {
 font-family: "avenirblack";
 font-size: 22px;
 color: #000;
 padding: 0 28px;
 min-width: 180px;
 text-align: center;
 line-height: 51px;
 border: 2px solid; //var(--red-one);
 background: #00fbc8; //var(--green-one);
 transition: all 0.3s ease-in-out;
}

// body .theme-btn-four:hover {
//   background: transparent;
//   color: var(--red-one);
// }
body .theme-btn-five {
 font-family: "gorditamedium";
 color: #292929;
 padding-bottom: 3px;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-five:before {
 content: "";
 position: absolute;
 height: 2px;
 width: 100%;
 background: var(--heading);
 left: 0;
 bottom: 0;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-five:hover {
 color: var(--red-two);
}

body .theme-btn-five:hover:before {
 background: var(--red-two);
}

body .theme-btn-six {
 font-family: "gorditamedium";
 font-size: 16px;
 color: #fff;
 text-align: center;
 padding: 0 32px;
 line-height: 55px;
 border-radius: 5px;
 background: var(--red-two);
 transition: all 0.3s ease-in-out;
}

body .theme-btn-six:hover {
 background: var(--heading);
}

body .theme-btn-seven {
 font-family: "gorditamedium";
 font-size: 14px;
 letter-spacing: 2px;
 text-transform: uppercase;
 color: #fff;
 padding: 0 38px;
 text-align: center;
 line-height: 55px;
 background: #161616;
 position: relative;
 overflow: hidden;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-seven:hover {
 background: var(--red-one);
}

body .theme-btn-eight {
 font-size: 15px;
 text-transform: uppercase;
 color: var(--heading);
 padding: 0 31px;
 text-align: center;
 letter-spacing: 1px;
 line-height: 48px;
 border: 1px solid var(--heading);
 transition: all 0.3s ease-in-out;
}

body .theme-btn-eight:hover {
 background: var(--red-one);
 border-color: var(--red-one);
 color: #fff;
}

body .theme-btn-nine {
 font-family: "gorditamedium";
 font-size: 15px;
 text-transform: uppercase;
 color: var(--heading);
 padding: 0 35px;
 text-align: center;
 letter-spacing: 2px;
 line-height: 55px;
 border: 1px solid var(--heading);
 transition: all 0.3s ease-in-out;
}

body .theme-btn-nine:hover {
 background: var(--heading);
 color: #fff;
}

body .theme-btn-ten {
 font-size: 16px;
 text-transform: uppercase;
 color: #fff;
 letter-spacing: 4px;
 position: relative;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-ten:before {
 content: "";
 position: absolute;
 left: 0;
 right: 0;
 bottom: -1px;
 height: 1px;
 background: rgba(255, 255, 255, 0.26);
}

body .theme-btn-ten:hover {
 letter-spacing: 6px;
 color: var(--red-one);
}

body .theme-btn-eleven {
 font-family: "avenirblack";
 font-size: 22px;
 color: var(--heading);
 // padding: 0 40px;
 // text-align: center;
 letter-spacing: 0;
 line-height: 58px;
 // border: 3px solid var(--heading);
 transition: all 0.3s ease-in-out;
 // border-radius: 20px;
}

body .theme-btn-eleven span {
 color: #8163ff;
 padding-left: 5px;
}

// body .theme-btn-eleven:hover {
//  background: var(--red-one);
//  border-color: var(--red-one);
//  color: #fff;
// }
body .theme-btn-twelve {
 font-family: "gorditamedium";
 font-size: 18px;
 background: var(--heading);
 color: #fff;
 padding: 0 48px;
 text-align: center;
 letter-spacing: 0;
 line-height: 62px;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-twelve:hover {
 background: var(--red-one);
}

body .theme-btn-twelve.outline {
 border: 2px solid #fff;
 background: transparent;
 line-height: 58px;
}

body .theme-btn-twelve.outline:hover {
 background: var(--red-one);
 border-color: var(--red-one);
}

body .theme-btn-thirteen {
 font-family: "gorditamedium";
 font-size: 17px;
 line-height: 60px;
 letter-spacing: -0.36px;
 color: #fff;
 background: var(--red-one);
 padding: 0 30px;
 border-radius: 10px;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-thirteen:hover {
 background: var(--heading);
}

body .theme-btn-fourteen {
 width: 60px;
 height: 60px;
 background: #000;
 text-align: center;
 line-height: 60px;
 color: #fff;
 font-size: 30px;
 border-radius: 0 50% 0 0;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-fourteen:hover {
 border-radius: 50%;
 background: var(--orange-one);
}

body .theme-btn-fifteen {
 width: 150px;
 height: 150px;
 background: #000;
 border-radius: 0 35% 0 0;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-fifteen:hover {
 border-radius: 50%;
 background: var(--orange-one);
}

body .theme-btn-sixteen {
 font-family: "Zen Dots", cursive;
 font-size: 17px;
 color: #000;
 letter-spacing: -0.3px;
 line-height: 58px;
 padding: 0 45px;
 position: relative;
 z-index: 1;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-sixteen:after {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 left: 0;
 top: 0;
 background: #fff;
 z-index: -1;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-sixteen:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 left: 0;
 top: 0;
 border: 1px solid #000;
 z-index: -2;
 transform: translate(6px, 6px);
 transition: all 0.3s ease-in-out;
}

body .theme-btn-sixteen:hover:before {
 transform: translate(-6px, -6px);
}

body .theme-btn-sixteen:hover:after {
 background: #000;
}

body .theme-btn-sixteen:hover {
 color: #fff;
}

body .theme-btn-seventeen {
 font-family: "Zen Dots", cursive;
 font-size: 15px;
 color: #fff;
 line-height: 45px;
 padding: 0 28px;
 position: relative;
 z-index: 1;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-seventeen:after {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 left: 0;
 top: 0;
 background: #000;
 z-index: -1;
 transition: all 0.3s ease-in-out;
}

body .theme-btn-seventeen:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 left: 0;
 top: 0;
 border: 1px solid #000;
 z-index: -2;
 transform: translate(6px, 6px);
 transition: all 0.3s ease-in-out;
}

body .theme-btn-seventeen:hover:before {
 transform: translate(-6px, -6px);
}

body .theme-btn-seventeen:hover:after {
 background: #ff5332;
}

body .theme-btn-eighteen {
 font-size: 16px;
 color: #000;
 position: relative;
 transition: all 0.2s ease-in-out;
}

body .theme-btn-eighteen:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 1px;
 background: #000000;
 left: 0;
 bottom: 0;
 transition: all 0.2s ease-in-out;
}

body .theme-btn-eighteen:hover {
 color: #ff7961;
}

body .theme-btn-eighteen:hover:before {
 background: #ff7961;
}

/*^^^^^^^^^^^^^^^^^^^^^^^ Pagination ^^^^^^^^^^^^^^^^^^^^^^*/
.page-pagination-one ul li {
 font-family: "gorditamedium";
 font-size: 20px;
 color: #3e3e3e;
 margin-right: 20px;
}

.page-pagination-one ul li button {
 transition: all 0.3s ease;
}

.page-pagination-one ul.justify-content-center li {
 margin: 0 10px;
}

.page-pagination-one ul li:hover button,
.page-pagination-one ul li.active button,
.dark-bg .page-pagination-one ul li.active button {
 color: var(--red-one);
}

.page-pagination-one ul li.arrow {
 font-size: 28px;
 color: #000;
}

.page-pagination-one ul li:nth-child(6) a {
 font-size: 18px;
}

.page-pagination-one ul li a span {
 display: inline-block;
 width: 40px;
 line-height: 40px;
 border-radius: 50%;
 text-align: center;
 transition: all 0.3s ease;
}

.page-pagination-one ul li a:hover span,
.page-pagination-one ul li.active a span {
 background: #232323;
 color: #fff;
}

.page-pagination-one.round-style ul li {
 margin: 0 5px;
}

.dark-bg .page-pagination-one ul li,
.dark-bg .page-pagination-one ul li button,
.dark-bg .page-pagination-one ul li.arrow button,
.dark-layout .page-pagination-one ul li button,
.dark-layout .page-pagination-one ul li.arrow button {
 color: rgba(255, 255, 255, 0.8);
}

.page-pagination-two ul li a {
 width: 50px;
 line-height: 50px;
 border-radius: 50%;
 color: #000;
 text-align: center;
 margin: 0 10px;
 transition: all 0.3s ease;
}

.page-pagination-two ul li a:hover,
.page-pagination-two ul li.active a {
 background: #000;
 color: #fff;
 transform: scale(1.2);
 font-size: 1.2em;
}

.page-pagination-three ul li a {
 font-family: "gorditamedium";
 width: 35px;
 line-height: 35px;
 border: 1px solid #e4e4e4;
 border-right: none;
 font-size: 15px;
 color: #000;
 text-align: center;
 transition: all 0.3s ease;
}

.page-pagination-three ul li:last-child a {
 border-right: 1px solid #e4e4e4;
}

.page-pagination-three ul li.active a,
.page-pagination-three ul li a:hover {
 background: #ffe3ea;
 color: var(--red-two);
 border-color: #ffb2c5;
 border-right: 1px solid #ffb2c5;
}

.page-pagination-four ul li span {
 font-family: "gorditamedium";
 font-size: 17px;
 color: #000;
}

.page-pagination-four ul li .icon {
 width: 48px;
 line-height: 46px;
 border-radius: 50%;
 border: 1px solid #000;
 text-align: center;
 color: #000;
 font-size: 22px;
 transition: all 0.3s ease;
}

.page-pagination-four ul li:hover .icon,
.page-pagination-four ul li.active .icon {
 background: var(--red-two);
 color: #fff;
 border-color: var(--red-two);
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu ^^^^^^^^^^^^^^^^^^^^^^^^*/
.theme-main-menu {
 position: fixed;
 z-index: 99;
 top: 0;
 left: 0;
 right: 0;
 padding: 30px 65px;
 transition: all 0.4s ease-out;
}

.theme-main-menu.sticky-menu.fixed {
 z-index: 990;
 padding-top: 8px;
 padding-bottom: 8px;
 background: #fff;
 box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
}

.theme-main-menu .inner-content {
 position: relative;
}

.theme-main-menu .navbar,
.theme-main-menu .mega-dropdown {
 position: static;
}

.theme-main-menu.sticky-menu.fixed.white-vr,
.theme-main-menu.sticky-menu.fixed.dark-bg {
 background: #191919;
}

.theme-main-menu .logo-xl {
 width: 203px;
}

.theme-menu-one .right-widget .login-btn {
 margin-right: 60px;
}

.theme-menu-one .right-widget .login-btn span {
 font-family: "gorditamedium";
 margin: 0 0 0 12px;
 transition: all 0.3s ease-in-out;
 color: var(--heading);
}

.theme-menu-one .right-widget .login-btn:hover span {
 text-decoration: underline;
}

.theme-menu-one .right-widget .contact-fancy-btn {
 font-family: "gorditamedium";
 color: var(--heading);
 font-size: 16px;
 position: relative;
 z-index: 1;
}

.theme-menu-one .right-widget .contact-fancy-btn em {
 position: relative;
 display: inline-block;
}

.theme-menu-one .right-widget .contact-fancy-btn em:after,
.theme-menu-one .right-widget .contact-fancy-btn span:after {
 content: "";
 position: absolute;
 width: 107%;
 height: 2px;
 left: 0;
 bottom: 0;
 background: #000;
}

.theme-menu-one .right-widget .contact-fancy-btn span {
 display: inline-block;
 color: #fff;
 position: relative;
 padding-left: 5px;
}

.theme-menu-one .right-widget .contact-fancy-btn span:before {
 content: "";
 position: absolute;
 width: 65px;
 height: 65px;
 border-radius: 50%;
 background: #000;
 z-index: -1;
 top: 50%;
 transform: translateY(-50%);
 right: -36px;
 transition: all 0.3s ease-in-out;
}

.theme-menu-one .right-widget .contact-fancy-btn:hover span:before {
 background: var(--orange-one);
}

.theme-menu-one .right-widget .contact-fancy-btn span:after {
 background: #fff;
}

.theme-menu-one .right-widget .contact-fancy-btn i {
 color: #fff;
 margin-left: 3px;
}

.white-vr .navbar .navbar-nav .nav-link,
.theme-menu-one.white-vr .right-widget .login-btn span {
 color: #fff;
}

.theme-main-menu .right-widget .cart-group-wrapper .dropdown-toggle:after {
 display: none;
}

.theme-main-menu .right-widget .cart-group-wrapper .dropdown-toggle {
 width: 50px;
 height: 50px;
 border-radius: 50%;
 margin: 0 30px 0 -30px;
 position: relative;
 transition: all 0.3s ease-in-out;
}

.theme-main-menu .right-widget .cart-group-wrapper .dropdown-toggle:hover {
 background: rgba(0, 0, 0, 0.04);
}

.theme-main-menu .right-widget .cart-group-wrapper .item-count {
 position: absolute;
 width: 16px;
 height: 16px;
 border-radius: 50%;
 background: var(--red-one);
 line-height: 16px;
 text-align: center;
 font-size: 9px;
 color: #fff;
 right: 2px;
 top: 0px;
}

.theme-main-menu .cart-group-wrapper .dropdown-menu {
 width: 300px;
 background: #fff;
 border: none;
 border-radius: 0;
 padding: 30px 35px 40px;
 box-shadow: 0px 25px 50px rgba(213, 216, 223, 0.5);
}

.theme-main-menu .right-widget .cart-group-wrapper .item-img {
 width: 70px;
 height: 75px;
 padding: 5px;
 background: #f5f5f5;
}

.theme-main-menu .right-widget .cart-group-wrapper .item-info {
 width: calc(100% - 70px);
 padding-left: 20px;
 position: relative;
}

.theme-main-menu .right-widget .cart-group-wrapper .item-info .name {
 font-size: 17px;
 color: #1f1f1f;
 margin: 0 0 5px;
}

.theme-main-menu .right-widget .cart-group-wrapper .item-info .name:hover {
 text-decoration: underline;
}

.theme-main-menu .right-widget .cart-group-wrapper .item-info .price {
 font-family: "gorditamedium";
 font-size: 18px;
 color: #000;
 letter-spacing: -1px;
}

.theme-main-menu .right-widget .cart-group-wrapper .item-info .price .quantity {
 font-size: 17px;
 color: rgba(31, 31, 31, 0.3);
 margin-left: 7px;
}

.theme-main-menu .right-widget .cart-group-wrapper .selected-item {
 border-bottom: 1px solid #eeeeee;
 padding-bottom: 30px;
 margin-bottom: 22px;
}

.theme-main-menu .right-widget .cart-group-wrapper .selected-item:last-child {
 margin-bottom: 21px;
}

.theme-main-menu .right-widget .cart-group-wrapper .subtotal .title {
 font-size: 18px;
 color: #1f1f1f;
}

.theme-main-menu .right-widget .cart-group-wrapper .subtotal .total-price {
 font-family: "gorditamedium";
 font-size: 20px;
 letter-spacing: 0px;
 color: #000;
}

.theme-main-menu .right-widget .cart-group-wrapper .subtotal {
 padding-bottom: 7px;
}

.theme-main-menu .right-widget .cart-group-wrapper .button-group a {
 font-family: "gorditamedium";
 display: block;
 line-height: 43px;
 border: 1px solid #dbdbdb;
 text-align: center;
 font-size: 14px;
 text-transform: uppercase;
 color: #1f1f1f;
 letter-spacing: 1px;
 margin-top: 15px;
 transition: all 0.3s ease-in-out;
}

.theme-main-menu .right-widget .cart-group-wrapper .button-group a:hover {
 color: #fff;
 background: #212121;
}

.theme-main-menu .right-widget .eCommerce-search {
 width: 335px;
 position: relative;
}

.theme-main-menu .right-widget .eCommerce-search input {
 width: 100%;
 font-size: 17px;
 border: none;
 font-style: italic;
 padding-left: 35px;
 padding-right: 15px;
 background: transparent;
}

.theme-main-menu .right-widget .eCommerce-search .icon {
 position: absolute;
 left: 0;
 top: 50%;
 transform: translateY(-50%);
}

.theme-main-menu .user-profile-action .dropdown-toggle:after {
 display: none;
}

.theme-main-menu .user-profile-action .dropdown-menu {
 width: 220px;
 background: #fff;
 border: none;
 border-radius: 0;
 padding: 20px 10px 25px 30px;
 box-shadow: 0px 25px 50px rgba(213, 216, 223, 0.5);
}

.theme-main-menu .user-profile-action .dropdown-menu ul li a {
 font-size: 17px;
 line-height: 42px;
 color: #000;
 padding-left: 30px;
 position: relative;
 transition: all 0.3s ease-in-out;
}

.theme-main-menu .user-profile-action .dropdown-menu ul li a:hover {
 color: var(--red-one);
}

.theme-main-menu .user-profile-action .dropdown-menu ul li a .icon {
 position: absolute;
 left: 0;
 top: 12px;
}

.theme-main-menu .user-profile-action .dropdown-menu ul li a:hover .icon path {
 fill: var(--red-one);
}

.theme-main-menu .mobile-content .search-form {
 margin: 40px auto 40px;
 width: 100%;
 position: relative;
}

.theme-main-menu .mobile-content .search-form input {
 width: 100%;
 height: 50px;
 border: 2px solid var(--heading);
 border-radius: 6px;
 font-size: 16px;
 padding: 0 50px 0 15px;
}

.theme-main-menu .mobile-content .search-form button {
 position: absolute;
 width: 50px;
 right: 0;
 top: 0;
 bottom: 0;
 border-radius: 6px;
 color: var(--heading);
 font-size: 20px;
 transition: all 0.3s ease-in-out;
}

.theme-main-menu .mobile-content .search-form button:hover {
 color: var(--red-one);
}

.theme-main-menu .mobile-content .address-block .title {
 font-family: "gorditamedium";
 font-size: 18px;
 margin-bottom: 15px;
}

.theme-main-menu .mobile-content .address-block p {
 font-size: 16px;
 line-height: 1.6em;
 color: var(--heading);
}

.theme-main-menu .mobile-content .address-block p a {
 font-size: 20px;
 color: var(--red-one);
 margin-top: 3px;
}

.theme-main-menu .mobile-content .address-block p a:hover {
 text-decoration: underline;
}

/*---Dark Version ----*/
.theme-main-menu.dark-bg
 .right-widget
 .cart-group-wrapper
 .dropdown-toggle:hover {
 background: rgba(255, 255, 255, 0.06);
}

.theme-main-menu .dark-style .mobile-content .search-form input {
 background: #323232;
 border: none;
 color: #fff;
}

.theme-main-menu .dark-style .mobile-content .address-block .title,
.theme-main-menu .dark-style .mobile-content .search-form button,
.theme-main-menu
 .right-widget
 .cart-group-wrapper
 .dark-dropdown
 .item-info
 .price,
.theme-main-menu
 .right-widget
 .cart-group-wrapper
 .dark-dropdown
 .subtotal
 .title,
.theme-main-menu
 .right-widget
 .cart-group-wrapper
 .dark-dropdown
 .subtotal
 .total-price {
 color: #fff;
}

.theme-main-menu .dark-style .mobile-content .address-block p,
.theme-main-menu
 .right-widget
 .cart-group-wrapper
 .dark-dropdown
 .item-info
 .name,
.theme-main-menu
 .right-widget
 .cart-group-wrapper
 .dark-dropdown
 .item-info
 .price
 .quantity {
 color: rgba(255, 255, 255, 0.6);
}

.theme-main-menu .cart-group-wrapper .dropdown-menu.dark-dropdown {
 background: #212121;
 box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.12);
}

.theme-main-menu
 .right-widget
 .cart-group-wrapper
 .dark-dropdown
 .selected-item {
 border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.theme-main-menu
 .right-widget
 .cart-group-wrapper
 .dark-dropdown
 .button-group
 a {
 border: 1px solid rgba(255, 255, 255, 0.08);
 color: #fff;
}

.theme-main-menu
 .right-widget
 .cart-group-wrapper
 .dark-dropdown
 .button-group
 a:hover {
 background: #000;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.theme-main-menu.theme-menu-two {
 padding-left: 40px;
 padding-right: 40px;
}

.theme-main-menu.theme-menu-two .inner-content {
 background: rgba(255, 255, 255, 0.5);
 border-radius: 15px;
 padding: 12px 35px;
 transition: all 0.3s ease-in-out;
}

.theme-main-menu.theme-menu-two.fixed .inner-content {
 padding: 0;
}

.theme-menu-two .right-widget .contact-btn {
 font-family: "gorditamedium";
 width: 160px;
 line-height: 44px;
 font-size: 18px;
 text-align: center;
 // border: 2px solid var(--heading);
 border-radius: 25px;
 // color: var(--heading);
 background-color: #8163ff;
 color: #fff;
 padding: 0 32px;
 line-height: 52px;
}

.theme-menu-two .right-widget .menu-search-btn {
 width: 45px;
 height: 45px;
 border: 2px solid var(--heading);
 border-radius: 50%;
 font-size: 18px;
 color: var(--heading);
 margin-right: 22px;
}

// .theme-menu-two .right-widget .contact-btn:hover,
// .theme-menu-two .right-widget .menu-search-btn:hover {
//   background: var(--red-one);
//   color: #fff;
//   border-color: var(--red-one);
// }

.theme-search-form {
 background: rgba(23, 22, 26, 0.96);
 border: none;
 height: 50vh !important;
 padding: 0 12px;
}

.theme-search-form .close-btn {
 position: absolute;
 right: 40px;
 top: 30px;
 color: rgba(255, 255, 255, 0.7);
 font-size: 22px;
 box-shadow: none;
}

.theme-search-form .close-btn:hover,
.theme-search-form .close-btn:focus {
 color: var(--red-one);
}

.theme-search-form form {
 height: 60px;
 max-width: 540px;
 margin: 0 auto;
 position: relative;
}

.theme-search-form form input {
 width: 100%;
 height: 100%;
 display: block;
 border: none;
 border-radius: 8px;
 padding: 0 60px 0 20px;
 background: rgb(255 255 255 / 3%);
 font-size: 16px;
 color: rgba(255, 255, 255, 0.7);
}

.theme-search-form form ::-webkit-input-placeholder {
 color: rgba(255, 255, 255, 0.5);
}

.theme-search-form form :-ms-input-placeholder {
 color: rgba(255, 255, 255, 0.5);
}

.theme-search-form form ::placeholder {
 color: rgba(255, 255, 255, 0.5);
}

.theme-search-form form button {
 font-size: 20px;
 position: absolute;
 right: 8px;
 top: 5px;
 bottom: 5px;
 width: 50px;
 border-radius: 8px;
 background: transparent;
 color: #fff;
 transition: all 0.3s ease;
}

.theme-search-form form button:hover {
 color: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.theme-main-menu.theme-menu-three .lang-select a {
 font-size: 19px;
 color: #fff;
 margin-right: 5px;
 transition: all 0.3s ease-in-out;
}

.theme-main-menu.theme-menu-three .lang-select a:hover {
 color: var(--red-one);
 text-decoration: underline;
}

.theme-main-menu.theme-menu-three .lang-select {
 margin-right: 45px;
}

.theme-main-menu.theme-menu-three.sticky-menu.fixed {
 background: #232227;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.theme-main-menu.theme-menu-four {
 padding: 0;
}

.theme-menu-four .logo {
 width: 100px;
 height: 100px;
 border-right: 1px solid #ffffff;
 border-bottom: 1px solid #ffffff;
}

.theme-menu-four .search-form {
 width: 470px;
 position: relative;
 height: 60px;
 margin-left: calc(12vw - 100px);
}

.theme-menu-four .search-form input {
 width: 100%;
 height: 100%;
 background: transparent;
 border: none;
 color: #fff;
 border-bottom: 1px solid #fff;
 font-style: italic;
}

.theme-menu-four .search-form button {
 position: absolute;
 right: 0;
 top: 0;
 bottom: 0;
 background: transparent;
 color: #fff;
 font-size: 20px;
}

.theme-menu-four .search-form button:hover {
 color: var(--red-one);
}

.theme-menu-four .sidebar-nav-button {
 width: 100px;
 height: 100px;
 border-left: 1px solid #ffffff;
 border-bottom: 1px solid #ffffff;
}

.theme-menu-four .right-widget .login-btn span {
 font-family: "gorditamedium";
 margin: 0 60px 0 12px;
 transition: all 0.3s ease-in-out;
 color: #fff;
}

.theme-menu-four .right-widget .login-btn:hover span {
 text-decoration: underline;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.theme-menu-five.theme-main-menu {
 padding: 50px 12px;
}

.theme-menu-five.theme-main-menu.sticky-menu.fixed {
 background: #080808;
 padding: 20px 12px;
}

.theme-menu-five .inner-content {
 position: relative;
}

.theme-menu-five .inner-content .logo {
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 1;
}

.theme-menu-five .navbar .navbar-nav .nav-link {
 font-family: "gorditaregular";
 font-size: 20px;
 color: #fff;
 margin: 0 15px;
}

.theme-menu-five .navbar .navbar-nav .nav-link.active {
 color: var(--red-one);
}

.theme-menu-five .navbar .navbar-nav .nav-item {
 width: 13vw;
 min-width: 220px;
 text-align: center;
}

.theme-menu-five .navbar .navbar-nav .nav-item:nth-child(2) {
 margin-right: 8vw;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.theme-main-menu.theme-menu-six {
 padding-left: 50px;
 padding-right: 50px;
}

.theme-main-menu.theme-menu-six .navbar {
 margin-left: calc(11vw - 112px);
}

.theme-main-menu.theme-menu-six .right-widget {
 margin-left: auto;
}

.theme-menu-six .right-widget .login-btn {
 margin-right: 50px;
}

.theme-menu-six .right-widget .login-btn span {
 font-family: "gorditamedium";
 margin: 0 0 0 12px;
 transition: all 0.3s ease-in-out;
 color: var(--heading);
}

.theme-menu-six .right-widget .login-btn:hover span {
 text-decoration: underline;
}

.theme-menu-six .right-widget .search-form {
 width: 20vw;
 min-width: 300px;
 position: relative;
 height: 50px;
 margin-right: 4vw;
 margin-bottom: 20px;
}

.theme-menu-six .right-widget .search-form input {
 width: 100%;
 height: 100%;
 font-size: 16px;
 background: transparent;
 border: none;
 padding: 0 0 0 35px;
 border-bottom: 2px solid #000;
 font-style: italic;
}

.theme-menu-six .right-widget .search-form button {
 position: absolute;
 left: 0;
 top: 0;
 bottom: 0;
 background: transparent;
 color: #000;
 font-size: 19px;
}

.theme-menu-six .right-widget .search-form button:hover {
 color: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.theme-menu-seven {
 border-bottom: 1px solid rgba(0, 0, 0, 0.06);
 padding-top: 18px;
 padding-bottom: 18px;
}

.theme-menu-seven .right-widget .login-btn {
 margin-right: 60px;
}

.theme-menu-seven .right-widget .login-btn span {
 font-size: 15px;
 margin: 0 0 0 12px;
 transition: all 0.3s ease-in-out;
 color: #fff;
}

.theme-menu-seven .right-widget .login-btn:hover span {
 text-decoration: underline;
}

.theme-menu-seven .right-widget .sign-up-btn {
 line-height: 45px;
 background: #000;
 color: #fff;
 font-size: 14px;
 padding: 0 35px;
 transition: all 0.3s ease-in-out;
}

.theme-menu-seven .right-widget .sign-up-btn:hover {
 background: #fff;
 color: #000;
}

.theme-menu-seven .navbar .navbar-nav .active .nav-link,
.theme-menu-seven .navbar .navbar-nav .nav-item:hover .nav-link,
.theme-menu-seven .white-vr .navbar .navbar-nav .active .nav-link,
.theme-menu-seven .white-vr .navbar .navbar-nav .nav-item:hover .nav-link {
 color: #fff;
 text-decoration: underline;
}

/*^^^^^^^^^^^^^^^^^^^^^ sidebar Menu ^^^^^^^^^^^^^^^^^^^^^^^^*/
.offcanvas.sidebar-nav {
 width: 500px;
 border: none;
 padding: 40px 40px 20px;
 max-height: 100%;
 overflow-y: auto;
}

.offcanvas.sidebar-nav .offcanvas-header {
 padding: 0;
}

.sidebar-nav .offcanvas-header .close-btn {
 font-size: 20px;
 color: #000;
}

.sidebar-nav .offcanvas-header .close-btn:hover {
 color: var(--red-one);
}

.sidebar-nav .sidebar-nav-item {
 padding: 5px 0 55px;
 text-align: center;
}

.sidebar-nav .sidebar-nav-item .nav-item > a {
 font-size: 24px;
 line-height: 2.42em;
 color: var(--heading);
 position: relative;
 transition: all 0.3s ease-in-out;
}

.sidebar-nav .sidebar-nav-item .nav-item:hover > a,
.sidebar-nav .sidebar-nav-item .nav-item.active > a {
 color: var(--red-one);
}

.sidebar-nav .sidebar-nav-item .dropdown-toggle::after {
 display: none;
}

.sidebar-nav .sidebar-nav-item .dropdown-toggle::before {
 content: "\f282";
 position: absolute;
 margin: 0;
 border: none;
 font-size: 18px;
 right: -25px;
 top: 2px;
 font-family: "bootstrap-icons";
 font-weight: 700;
 transition: transform 0.3s ease-in-out;
}

.sidebar-nav .sidebar-nav-item .dropdown-toggle.show::before {
 transform: rotate(180deg);
}

.sidebar-nav .sidebar-nav-item .dropdown-menu {
 position: static !important;
 border: none;
 background: var(--light-cyan);
 transform: scale(1) !important;
 padding: 10px 0;
 text-align: center;
}

.sidebar-nav .sidebar-nav-item .dropdown-menu a {
 font-size: 18px;
 line-height: 44px;
 color: var(--heading);
 padding: 0 15px;
 background: transparent;
 transition: all 0.3s ease-in-out;
}

.sidebar-nav .sidebar-nav-item .dropdown-menu a:hover {
 color: var(--red-one);
}

.sidebar-nav .sidebar-nav-item .dropdown-menu a span {
 position: relative;
}

.sidebar-nav .sidebar-nav-item .dropdown-menu a span:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 2px;
 left: 0;
 bottom: -2px;
 background: var(--red-one);
 transform: scale(0, 1);
 transform-origin: 0 0;
 transition: all 0.3s ease-in-out;
}

.sidebar-nav .sidebar-nav-item .dropdown-menu a:hover span:before {
 transform: scale(1);
}

.sidebar-nav .search-form {
 margin: 20px auto 70px;
 width: 100%;
 position: relative;
}

.sidebar-nav .search-form input {
 width: 100%;
 height: 60px;
 border: 2px solid var(--heading);
 border-radius: 6px;
 font-size: 17px;
 padding: 0 50px 0 20px;
}

.sidebar-nav .search-form button {
 position: absolute;
 width: 50px;
 right: 0;
 top: 0;
 bottom: 0;
 border-radius: 6px;
 color: var(--heading);
 font-size: 22px;
 transition: all 0.3s ease-in-out;
}

.sidebar-nav .search-form button:hover {
 color: var(--red-one);
}

.sidebar-nav .address-block .title {
 font-family: "gorditamedium";
 font-size: 22px;
 padding-bottom: 14px;
 margin-bottom: 0;
}

.sidebar-nav .address-block p {
 font-size: 19px;
 line-height: 1.6em;
 color: var(--heading);
 padding-bottom: 30px;
 margin-bottom: 0;
}

.sidebar-nav .address-block p a {
 font-size: 22px;
 color: var(--red-one);
 margin-top: 2px;
}

.sidebar-nav .address-block p a:hover {
 text-decoration: underline;
}

.sidebar-nav .social-icon a {
 font-size: 19px;
 margin-right: 19px;
 color: #333333;
 transition: all 0.3s ease-in-out;
}

.sidebar-nav .social-icon a:hover {
 color: var(--red-one);
}

/*---- Dark Style ----*/
.dark-style.sidebar-nav {
 background: #181818;
}

.dark-style.sidebar-nav .sidebar-nav-item .dropdown-menu {
 background: #000;
}

.dark-style.sidebar-nav .offcanvas-header .close-btn {
 color: rgba(255, 255, 255, 0.8);
}

.dark-style.sidebar-nav .sidebar-nav-item .nav-item > a,
.dark-style.sidebar-nav .search-form button,
.dark-style.sidebar-nav .address-block .title {
 color: #fff;
}

.dark-style.sidebar-nav .sidebar-nav-item .dropdown-menu a,
.dark-style.sidebar-nav .address-block p,
.dark-style.sidebar-nav .social-icon a {
 color: rgba(255, 255, 255, 0.75);
}

.dark-style.sidebar-nav .sidebar-nav-item .nav-item:hover > a,
.dark-style.sidebar-nav .sidebar-nav-item .nav-item.active > a,
.dark-style.sidebar-nav .sidebar-nav-item .dropdown-menu a:hover,
.dark-style.sidebar-nav .search-form button:hover,
.dark-style.sidebar-nav .social-icon a:hover,
.dark-style.sidebar-nav .offcanvas-header .close-btn:hover {
 color: var(--red-one);
}

.dark-style.sidebar-nav [class*="theme-btn-"] {
 background: var(--red-one);
}

.dark-style.sidebar-nav .search-form input {
 background: #323232;
 border: none;
 color: #fff;
}

/*--- Full Screen ----*/
.offcanvas.sidebar-nav.full-screen {
 width: 100vw;
 height: 100vh;
 padding: 20px 15px;
 transition: all 0.5s ease-in-out;
}

.sidebar-nav.full-screen .sidebar-nav-item {
 padding: 40px 0;
}

.sidebar-nav.full-screen .sidebar-nav-item .nav-item {
 font-family: "Recoleta";
}

.sidebar-nav.full-screen .sidebar-nav-item .nav-item > a {
 font-size: 100px;
 line-height: 1.4em;
 color: #e7e7e7;
}

.sidebar-nav.full-screen .sidebar-nav-item .nav-item:hover > a,
.sidebar-nav.full-screen .sidebar-nav-item .nav-item a.show {
 color: #000;
}

.sidebar-nav.full-screen .sidebar-nav-item .nav-item > a:after {
 content: "";
 display: block;
 position: absolute;
 width: 100%;
 height: 6px;
 background: var(--red-one);
 left: 0;
 top: 50%;
 z-index: 1;
 transition: all 0.3s ease-in-out;
 opacity: 0;
 border: none;
 margin: 0;
 vertical-align: middle;
 transform: translateY(-50%) scale(0.5, 1);
}

.sidebar-nav.full-screen .sidebar-nav-item .nav-item:hover > a:after,
.sidebar-nav.full-screen .sidebar-nav-item .nav-item a.show:after {
 transform: translateY(-50%) scale(1);
 opacity: 1;
}

.sidebar-nav.full-screen .sidebar-nav-item .dropdown-toggle::before {
 right: -30px;
}

.sidebar-nav.full-screen .sidebar-nav-item .dropdown-menu a {
 font-size: 35px;
 line-height: 2em;
}

/*---- Full Screen Dark ----*/
.sidebar-nav.full-screen.dark-style .sidebar-nav-item .nav-item > a {
 color: #383838;
}

.sidebar-nav.full-screen.dark-style .sidebar-nav-item .nav-item:hover > a,
.sidebar-nav.full-screen.dark-style .sidebar-nav-item .nav-item a.show {
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-one {
 position: relative;
 z-index: 1;
}

.hero-banner-one .hero-heading {
 line-height: 1.1em;
}

.hero-banner-one .hero-heading span:before,
.hero-banner-one .hero-heading span:after {
 content: "";
 width: 49%;
 height: 4px;
 position: absolute;
 bottom: 2px;
 left: 13px;
 background: var(--heading);
}

.hero-banner-one .hero-heading span:after {
 background: var(--red-one);
 left: auto;
 right: 8px;
}

.hero-banner-one .hero-heading span em {
 font-style: normal;
 color: var(--red-one);
}

.hero-banner-one .hero-sub-heading {
 color: var(--heading);
 padding: 30px 40px 55px 0;
}

.hero-banner-one form {
 max-width: 565px;
 height: 80px;
 position: relative;
}

.hero-banner-one form input {
 border: none;
 background: #f4f4f4;
 border-radius: 7px;
 width: 100%;
 height: 100%;
 font-size: 16px;
 padding: 0 190px 0 30px;
}

.hero-banner-one form button {
 position: absolute;
 right: 10px;
 top: 8px;
 bottom: 8px;
 background: #242424;
 width: 180px;
 border-radius: 7px;
 text-align: center;
 color: #fff;
 font-size: 16px;
 transition: all 0.3s ease-in-out;
}

.hero-banner-one form button:hover {
 background: var(--red-one);
}

.hero-banner-one form ::-webkit-input-placeholder {
 /* Edge */
 color: #0b0b0b;
}

.hero-banner-one form :-ms-input-placeholder {
 /* Internet Explorer 10-11 */
 color: #0b0b0b;
}

.hero-banner-one .term-text {
 font-size: 16px;
 color: #979797;
 padding-top: 20px;
}

.hero-banner-one .term-text a {
 color: var(--heading);
}

.hero-banner-one .term-text a:hover {
 text-decoration: underline;
}

.hero-banner-one .illustration-container {
 position: absolute;
 right: 0;
 top: 0;
 z-index: -1;
 max-width: 46%;
}

.partner-slider-one p {
 font-size: 20px;
 color: var(--heading);
 padding-bottom: 60px;
}

.partner-slider-one p span {
 font-family: "gorditamedium";
}

.partner-slider-one-border .partner-slider-one {
 max-width: 1500px;
 margin: 0 auto;
 border-bottom: 1px solid #e8e8e8;
 padding-bottom: 70px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-feature-section-one .bg-wrapper {
 padding: 155px 15px 80px;
 background: var(--light-cyan);
 position: relative;
 z-index: 1;
}

.vcamp-feature-section-one .bg-wrapper .shape-one {
 left: 14%;
 bottom: 8%;
 animation: jumpTwo 5s infinite linear;
}

.vcamp-feature-section-one .meta-info-text {
 color: var(--heading);
 padding: 30px 0 65px;
}

.card-style-one {
 margin-bottom: 95px;
}

.card-style-one .icon {
 width: 65px;
 height: 65px;
 background: #fff;
 border-radius: 50%;
}

.card-style-one .icon.style-two {
 width: 60px;
 height: 60px;
 border-radius: 10px;
 border: 1px solid #dfdfdf;
}

.card-style-one:hover .icon img {
 transform: rotate(360deg);
}

.card-style-one .title {
 font-size: 24px;
 padding: 30px 0 20px;
 margin-bottom: 0;
}

.card-style-one .arrow-icon:hover img {
 transform: translateX(5px);
}

.card-style-one p {
 padding: 0 50px 32px 0;
 margin-bottom: 0;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-one {
 position: relative;
 z-index: 1;
}

.vcamp-text-block-one .text-wrapper {
 padding-top: 40px;
}

.vcamp-text-block-one .text-wrapper ul {
 list-style-type: disc;
 list-style-position: inside;
}

.vcamp-text-block-one .video-icon {
 width: 65px;
 height: 65px;
 border-radius: 50%;
 background: var(--heading);
 padding: 15px 16px 18px 13px;
 margin-bottom: 30px;
 position: relative;
 z-index: 1;
 animation: ripple 3s linear infinite;
}

.vcamp-text-block-one .video-icon::before,
.vcamp-text-block-one .video-icon::after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 border-radius: 50%;
 animation: inherit;
 animation-delay: 1s;
}

.vcamp-text-block-one .video-icon::after {
 animation-delay: 3s;
}

.vcamp-text-block-one .meta-info-text {
 padding: 45px 0 50px;
 font-weight: 600;
 font-family: "avenirblack";
 line-height: 2.5rem;
 font-size: 21px;
}

.vcamp-text-block-one .text-wrapper h6 {
 font-family: "gorditamedium";
 font-size: 25px;
 padding: 65px 0 12px;
}

.vcamp-text-block-one .meta-info-text-two {
 font-size: 22px;
 line-height: 1.85em;
 padding-right: 30px;
 margin-bottom: 0;
}

.vcamp-text-block-one .img-container {
 padding: 0 0 50px 110px;
}

.vcamp-text-block-one .quote-wrapper {
 position: absolute;
 width: 410px;
 background: #fff;
 border-radius: 14px;
 bottom: 0;
 left: 0;
 z-index: 1;
 padding: 25px 30px 36px 48px;
 box-shadow: 0 40px 80px rgba(23, 42, 65, 0.06);
 animation: jumpTwo 8s infinite linear;
}

.vcamp-text-block-one .quote-wrapper blockquote {
 font-size: 20px;
 line-height: 1.75em;
 padding-bottom: 16px;
}

.vcamp-text-block-one .quote-wrapper .name {
 font-family: "gorditamedium";
 font-size: 20px;
 margin-bottom: 0;
}

.vcamp-text-block-one .quote-wrapper .name span {
 font-family: "gorditaregular";
 font-weight: normal;
 font-size: 18px;
 color: #9c9c9c;
}

.vcamp-text-block-one .quote-wrapper .icon {
 position: absolute;
 right: 43px;
 bottom: 32px;
}

.vcamp-text-block-one .illustration {
 animation: jumpTwo 5s infinite linear;
}

.vcamp-text-block-one .video-img-box {
 position: relative;
 border-radius: 30px;
 overflow: hidden;
}

.vcamp-text-block-one .video-img-box .video-icon {
 background: var(--red-one);
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 1;
}

.vcamp-text-block-one .left-img-meta {
 position: absolute;
 left: 5%;
 top: 50%;
 transform: translateY(-50%);
 max-width: 45%;

 // transform: translate(20%,-50%);
 // padding: 0 12px;
 // margin: 40px auto 0;
}

.vcamp-text-block-one .left-img-meta .shape-one {
 top: -88px;
 right: -93px;
 z-index: 1;
 animation: fade-in 1.5s infinite linear;
}

.vcamp-text-block-one .shape-two {
 right: 5%;
 bottom: -9%;
 animation: jumpThree 5s infinite linear;
}

/*^^^^^^^^^^^^^^^^^^^^^ Counter Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.counter-section-one .inner-container {
 max-width: 1790px;
 padding: 0 12px;
 margin: 0 auto;
}

.counter-block-one .main-count {
 font-size: 60px;
 color: var(--heading);
 line-height: initial;
 margin-bottom: -8px;
}

.counter-block-one .main-count.text-lg {
 font-size: 80px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Slider One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-one .bg-wrapper {
 padding: 150px 15px 120px;
 background: #f6eae0;
 position: relative;
 z-index: 5;
}

.feedback-section-one .inner-content .icon {
 width: 68px;
 height: 68px;
 background: var(--heading);
 border-radius: 50%;
}

.feedback-section-one .inner-content blockquote {
 font-size: 36px;
 line-height: 1.8em;
 color: var(--heading);
 padding-bottom: 50px;
}

.feedback-section-one .inner-content blockquote span {
 color: var(--red-one);
 position: relative;
 display: inline-block;
}

.feedback-section-one .inner-content blockquote span:before {
 content: "";
 position: absolute;
 width: 98%;
 height: 2px;
 background: var(--red-one);
 left: 2px;
 bottom: 2px;
}

.feedback-section-one .inner-content .avatar {
 width: 40px;
 height: 40px;
 border-radius: 50%;
 margin-right: 20px;
}

.feedback-section-one .inner-content .name {
 font-size: 20px;
 font-family: "gorditamedium";
}

.feedback-section-one .inner-content .name span {
 font-family: "gorditaregular";
 font-size: 15px;
}

.feedback-section-one .screen-one {
 right: 0;
 bottom: 0;
 max-width: 50.1%;
}

.feedback-section-one .slick-arrow {
 right: 18%;
 bottom: 16px;

 @media (max-width: 767px) {
  right: auto;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
 }

 &.slick-prev {
  right: 24%;
 }
}

.feedback-slider-one {
 @media (max-width: 767px) {
  padding-bottom: 30px;
 }
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.card-style-two {
 border-top: 1px solid #e4e4e4;
 padding-top: 65px;
 margin-bottom: 60px;
}

.card-style-two .icon {
 width: 55px;
 height: 55px;
 background: #f2f2f2;
 border-radius: 50%;
}

.card-style-two:hover .icon img {
 transform: rotate(360deg);
}

.card-style-two .title {
 font-size: 24px;
 padding: 30px 0 20px;
 margin-bottom: 0;
}

.card-style-two p {
 font-size: 17px;
 margin-bottom: 0;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Slider Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-two .bg-wrapper {
 padding: 120px 0 160px;
 background: var(--deep-cyan);
 position: relative;
 z-index: 5;
 max-width: 1785px;
 margin: 0 auto;
}

.feedback_slider_two {
 max-width: 1590px;
 margin: 125px -30px 0 auto;
}

.testimonial-block-one {
 background: #fff;
 border-radius: 10px;
 padding: 48px 25px 52px 52px;
 position: relative;
}

.testimonial-block-one .icon {
 width: 53px;
 height: 53px;
 border-radius: 50%;
 background: var(--heading);
 position: absolute;
 bottom: 47px;
 right: 42px;
}

.testimonial-block-one blockquote {
 font-size: 24px;
 line-height: 1.75em;
 padding-bottom: 45px;
}

.testimonial-block-one .name {
 font-size: 20px;
 color: #1b1b1b;
 position: relative;
 padding-left: 28px;
}

.testimonial-block-one .name:before {
 content: "";
 position: absolute;
 width: 16px;
 height: 2px;
 background: var(--heading);
 left: 0;
 top: 13px;
}

.testimonial-block-one .name span {
 font-size: 18px;
 color: #c1c1c1;
}

.testimonial-block-one .rating li {
 font-size: 16px;
 margin-right: 10px;
 color: #797979;
}

.testimonial-block-one .rating li .bi-star-fill {
 color: #ffc545;
}

.feedback_slider_two .item {
 padding: 0 25px;
}

.feedback-section-two .slider-arrows li {
 width: 50px;
 height: 50px;
 border-radius: 50%;
 background: #fff;
 text-align: center;
 line-height: 50px;
 color: #2a2a2a;
 font-size: 18px;
 margin-left: 12px;
 cursor: pointer;
 transition: all 0.3s ease-in-out;
}

.feedback-section-two .slider-arrows li:hover {
 background: var(--red-one);
 color: #fff;
}

/*--- dark Version ---*/
.dark-bg .testimonial-block-one {
 background: rgba(255, 255, 255, 0.05);
}

.dark-bg .testimonial-block-one blockquote {
 color: #d4d4d4;
}

.dark-bg .testimonial-block-one .name {
 color: #fff;
}

.dark-bg .testimonial-block-one .name:before {
 background: #fff;
}

.dark-bg .testimonial-block-one .name span {
 color: #a4a4a4;
}

.dark-bg .testimonial-block-one .icon {
 background: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ Blog Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.blog-meta-one {
 position: relative;
 z-index: 1;
 padding-top: 75px;
 padding-bottom: 145px;
 border-bottom: 2px solid var(--heading);
 width: 100%;
}

.blog-meta-one.top-border {
 border-top: 2px solid var(--heading);
}

.blog-meta-one.feature-post {
 background: #f6f6f6;
 padding: 75px 50px 145px;
 border-bottom-color: #f6f6f6;
}

.blog-meta-one.feature-post .post-footer {
 left: 50px;
 right: 50px;
}

.blog-meta-one .tag {
 font-family: "gorditamedium";
 display: inline-block;
 line-height: 27px;
 border-radius: 20px;
 letter-spacing: 1px;
 background: var(--heading);
 color: #fff;
 font-size: 11px;
 text-transform: uppercase;
 padding: 0 20px;
 transition: all 0.3s ease-in-out;
}

.blog-meta-one .tag:hover {
 box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.blog-meta-one .title {
 font-family: "gorditamedium";
 font-size: 26px;
 line-height: 1.3em;
 color: var(--heading);
 display: block;
 margin: 23px 0 17px;
}

.blog-meta-one:hover .title {
 text-decoration: underline;
}

.blog-meta-one .post-info {
 font-family: "gorditamedium";
 font-size: 16px;
 color: var(--heading);
}

.blog-meta-one .post-info .date {
 font-family: "gorditaregular";
 color: #aaaaaa;
}

.blog-meta-one .post-footer {
 position: absolute;
 left: 0;
 right: 0;
 bottom: 40px;
}

.blog-meta-one .post-footer .read-more:hover {
 transform: translateX(-5px);
}

/*^^^^^^^^^^^^^^^^^^^^^ Contact Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.contact-section-one .bg-wrapper {
 padding: 85px 0 80px;
 background: #fbf3f3;
 position: relative;
 z-index: 5;
}

.contact-meta-one p {
 padding: 36px 35px 45px 0;
}

.contact-meta-one .help-text {
 color: rgba(0, 0, 0, 0.5);
 padding-bottom: 12px;
}

.contact-meta-one .help-text span {
 font-family: "gorditamedium";
 color: var(--red-one);
}

.contact-meta-one .call-button {
 font-size: 26px;
 color: var(--heading);
 transition: all 0.3s ease;
}

.contact-meta-one .call-button:hover {
 color: var(--red-one);
 text-decoration: underline;
}

.form-style-one {
 background: #fff;
 border-radius: 15px;
 padding: 65px 55px 80px;
 margin-right: 45px;
 position: relative;
 z-index: 1;
}

.form-style-one.with-border {
 box-shadow: 5px 5px 0px #000;
 margin-right: 103px;
}

.contact-section-four .form-style-one {
 border-top: 1px solid #e0e0e0;
 border-radius: 0;
 padding: 115px 0 0;
 margin: 80px 0 0;
}

.contact-section-four .form-style-one.border0 {
 border: none;
}

.form-style-one .shape-one {
 right: -103px;
 bottom: -84px;
 animation: jumpThree 5s infinite linear;
}

.form-style-one label {
 font-size: 14px;
 color: rgba(0, 0, 0, 0.5);
 margin-bottom: 5px;
}

.form-style-one .input-group-meta input,
.form-style-one .input-group-meta textarea,
.form-style-one .input-group-meta select,
.form-style-three .input-group-meta select {
 display: block;
 width: 100% !important;
 max-width: 100%;
 border: 20px solid var(--heading);
 border-radius: 8px;
 padding: 0 20px;
 font-size: 16px;
}

.form-style-one .input-group-meta input,
.form-style-one .input-group-meta select,
.form-style-three .input-group-meta select {
 height: 60px;
}

.form-style-one .input-group-meta select:focus {
 box-shadow: none;
}

.form-style-one .input-group-meta textarea {
 min-height: 140px;
 padding: 15px 20px;
}

.contact-section-four .form-style-one .input-group-meta textarea {
 min-height: 200px;
}

.form-style-one .selectize-input {
 display: block !important;
 font-size: 16px;
 line-height: 56px;
 height: 60px;
 color: rgba(0, 0, 0, 0.6);
 border-radius: 8px;
 padding: 0 0 0 20px;
 border: 2px solid var(--heading);
 box-shadow: none;
}

.form-style-one .selectize-input .item {
 line-height: 56px;
 color: var(--heading);
 display: block;
}

.form-style-one .selectize-dropdown {
 border: none;
 box-shadow: 0 13px 35px -12px rgb(35 35 35 / 10%);
 margin: 0;
}

.form-style-one .selectize-dropdown .option {
 font-size: 16px;
 color: #4f4f4f;
 line-height: 35px;
}

.form-style-one .selectize-dropdown .option:hover,
.form-style-one .selectize-dropdown .active {
 color: #fff;
 background: var(--red-one);
}

.form-style-one .agreement-checkbox label {
 position: relative;
 font-size: 15px;
 color: var(--heading);
 cursor: pointer;
 letter-spacing: -0.5px;
 padding-left: 22px;
 line-height: 18px;
 transition: all 0.1s ease-in-out;
}

.form-style-one .agreement-checkbox label a {
 color: var(--p-color);
 text-decoration: underline;
}

.form-style-one .agreement-checkbox input[type="checkbox"] {
 display: none;
}

.form-style-one .agreement-checkbox label:before {
 content: "";
 width: 13px;
 height: 13px;
 line-height: 9px;
 border-radius: 2px;
 border: 2px solid rgba(0, 0, 0, 0.3);
 font-size: 10px;
 font-weight: 700;
 text-align: center;
 position: absolute;
 left: 0;
 top: 3px;
 transition: all 0.1s ease-in-out;
}

.form-style-one
 .agreement-checkbox
 input[type="checkbox"]:checked
 + label:before {
 content: "\f272";
 font-family: bootstrap-icons !important;
 background: #000;
 color: #fff;
 border-color: #000;
}

#contact-form .input-group-meta {
 position: relative;
}

#contact-form .form-group .help-block {
 position: absolute;
 left: 0;
 bottom: -22px;
 font-size: 12px;
 line-height: 22px;
 color: var(--red-one);
}

#contact-form .form-group .help-block ul {
 margin-bottom: 0;
}

.address-block-one .icon {
 width: 80px;
 height: 80px;
 border: 1px solid #e6e6e6;
 border-radius: 50%;
}

.address-block-one .title {
 font-family: "gorditamedium";
 font-size: 24px;
 padding: 20px 0 14px;
 margin-bottom: 0;
}

.address-block-one p {
 font-size: 19px;
 line-height: 1.65em;
 color: #878787;
 margin-bottom: 0;
}

.address-block-one p .call {
 font-size: 22px;
 color: var(--red-one);
}

.address-block-one p .call:hover {
 text-decoration: underline;
}

.address-block-one p .webaddress {
 color: var(--heading);
 text-decoration: underline;
}

/*^^^^^^^^^^^^^^^^^^^^^ Footer One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-footer-one .bg-wrapper {
 padding: 90px 0 70px;
 background: var(--light-blue);
 position: relative;
 z-index: 5;
}

.vcamp-footer-one .bg-wrapper .illustration {
 max-width: 25%;
 left: 7%;
 bottom: 0;
}

.vcamp-footer-one .subscribe-area .subscribe-form form {
 position: relative;
 height: 66px;
 max-width: 540px;
 margin-left: auto;
}

.vcamp-footer-one .subscribe-area .subscribe-form form input {
 width: 100%;
 height: 100%;
 border: 2px solid var(--heading);
 font-size: 17px;
 padding: 0 150px 0 20px;
 font-style: italic;
 border-radius: 7px;
 background: transparent;
}

.vcamp-footer-one .subscribe-area .subscribe-form form button {
 position: absolute;
 width: 135px;
 background: var(--heading);
 right: 0;
 top: 0;
 bottom: 0;
 border-radius: 0 7px 7px 0;
 color: #fff;
 transition: all 0.3s ease-in-out;
}

.vcamp-footer-one .subscribe-area .subscribe-form form button img {
 vertical-align: baseline;
}

.vcamp-footer-one .subscribe-area .subscribe-form p {
 text-align: right;
 font-size: 15px;
 color: #969696;
 padding-top: 15px;
 margin-bottom: 0;
}

.vcamp-footer-one .subscribe-area .subscribe-form p a {
 color: var(--heading);
}

.vcamp-footer-one .subscribe-area .subscribe-form p a:hover {
 text-decoration: underline;
}

.vcamp-footer-one .subscribe-area-two {
 background: url(../images/media/img_39.jpg) no-repeat center;
 background-size: cover;
 position: relative;
 z-index: 1;
 padding: 95px 0 70px;
}

.vcamp-footer-one .subscribe-area-two:before {
 content: "";
 position: absolute;
 left: 0;
 right: 0;
 bottom: 0;
 top: 45%;
 z-index: -1;
 background: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0.0466274) 0%,
  #0a090b 25.38%,
  #121115 63.05%
 );
}

.vcamp-footer-one .subscribe-area-two ::-webkit-input-placeholder {
 color: #404040;
}

.vcamp-footer-one .subscribe-area-two :-ms-input-placeholder {
 color: #404040;
}

.vcamp-footer-one .subscribe-area-two ::placeholder {
 color: #404040;
}

.vcamp-footer-one .subscribe-area-two .subscribe-form form {
 position: relative;
 height: 66px;
 max-width: 500px;
 margin-left: auto;
}

.vcamp-footer-one .subscribe-area-two .subscribe-form form input {
 width: 100%;
 height: 100%;
 border: none;
 font-size: 16px;
 padding: 0 150px 0 20px;
 color: #404040;
 background: #fff;
}

.vcamp-footer-one .subscribe-area-two .subscribe-form form button {
 position: absolute;
 width: 135px;
 background: var(--red-two);
 right: 0;
 top: 0;
 bottom: 0;
 color: #fff;
 transition: all 0.3s ease-in-out;
}

.vcamp-footer-one .subscribe-area-two .subscribe-form form button.bg-two {
 background: var(--red-one);
}

.vcamp-footer-one .subscribe-area-two .subscribe-form p {
 text-align: right;
 font-size: 15px;
 color: #969696;
 padding-top: 15px;
 margin-bottom: 0;
}

.vcamp-footer-one .subscribe-area-two .subscribe-form p a {
 color: #fff;
}

.vcamp-footer-one .subscribe-area-two .subscribe-form p a:hover {
 text-decoration: underline;
}

.vcamp-footer-one .top-footer .shape {
 max-width: 152px;
}

.vcamp-footer-one .top-footer .footer-title {
 font-family: "gorditamedium";
 font-size: 24px;
 padding: 8px 0 20px 0;
 margin-bottom: 0;
}

.vcamp-footer-one .top-footer .footer-nav-link a {
 line-height: 40px;
 color: #515151;
 font-size: 16px;
 transition: all 0.3s ease-in-out;
}

.vcamp-footer-one .top-footer .footer-nav-link a:hover {
 color: var(--red-one);
 text-decoration: underline;
}

.vcamp-footer-one .top-footer [class*="col-"] {
 margin-bottom: 35px;
}

.vcamp-footer-one .bottom-footer {
 padding: 35px 0 15px;
}

.vcamp-footer-one .bottom-footer .footer-nav a {
 font-family: "gorditamedium";
 font-size: 15px;
 color: var(--heading);
 margin-right: 22px;
}

.vcamp-footer-one .bottom-footer .footer-nav a:hover {
 text-decoration: underline;
}

.vcamp-footer-one .bottom-footer .copyright {
 font-size: 15px;
 margin: 0;
}

.vcamp-footer-one .bottom-footer .social-icon a {
 font-size: 19px;
 margin-left: 19px;
 color: #333333;
 transition: all 0.3s ease-in-out;
}

.vcamp-footer-one .bottom-footer .social-icon a:hover {
 color: var(--red-one);
}

/*-- Dark Bg ---*/
.vcamp-footer-one.dark-bg {
 position: relative;
 padding: 240px 0 0;
 color: #fff;
}

.vcamp-footer-one.dark-bg.image-bg {
 background-image: url(../images/shape/bg-1.png);
 background-position: top center;
 background-repeat: no-repeat;
 background-size: cover;
 padding-top: 320px;
}

.vcamp-footer-one.dark-bg .subscribe-area .subscribe-form form input {
 border: 2px solid #272727;
 background: #272727;
 color: rgba(255, 255, 255, 0.5);
}

.vcamp-footer-one.dark-bg .subscribe-area .subscribe-form form button {
 background: var(--red-one);
}

.vcamp-footer-one.dark-bg .subscribe-area .subscribe-form p a,
.vcamp-footer-one.dark-bg .top-footer .footer-title {
 color: #fff;
}

.vcamp-footer-one.dark-bg .top-footer .footer-nav-link a,
.vcamp-footer-one.dark-bg .bottom-footer .footer-nav a,
.vcamp-footer-one.dark-bg .bottom-footer .social-icon a,
.vcamp-footer-one.dark-bg .bottom-footer .copyright {
 color: rgba(255, 255, 255, 0.7);
}

.vcamp-footer-one.dark-bg .top-footer .footer-nav-link a:hover {
 color: var(--red-one);
 text-decoration: underline;
}

.vcamp-footer-one.dark-bg .bottom-footer .social-icon a:hover {
 color: #fff;
}

.vcamp-footer-one.dark-bg .bottom-footer {
 padding-top: 90px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-two {
 background: url(../images/shape/shape_02.svg) no-repeat center;
 background-size: cover;
 position: relative;
 z-index: 2;
 padding: 250px 0 0;
 height: 1100px;
}

.hero-banner-two .container {
 max-width: 1440px;
 padding: 0 12px;
}

.hero-banner-two .main-content {
 position: relative;
}

.hero-banner-two .shape-one {
 top: -2%;
 right: 31%;
 animation: jumpTwo 5s infinite linear;
}

.hero-banner-two .text-wrapper {
 max-width: 660px;
 position: absolute;
 left: 0;
 top: 0;
 z-index: 1;
}

.hero-banner-two .hero-heading {
 font-size: 90px;
 line-height: 1.11em;
 margin-bottom: 0;
}

.hero-banner-two .hero-heading span {
 color: var(--red-one);
 position: relative;
 display: inline-block;
}

.hero-banner-two .hero-heading span:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 4px;
 background: var(--red-one);
 left: 0;
 bottom: 2px;
}

.hero-banner-two .hero-sub-heading {
 color: var(--heading);
 padding: 23px 40px 25px 0;
}

.hero-banner-two .button-group .demo-button {
 line-height: 55px;
 border-radius: 8px;
 background: #161616;
 font-size: 18px;
 margin-right: 60px;
 color: #fff;
 padding: 0 30px;
}

.hero-banner-two .button-group .demo-button:hover {
 background: var(--red-one);
}

.hero-banner-two .button-group .callback-button {
 font-family: "gorditamedium";
 font-size: 16px;
 color: var(--heading);
}

.hero-banner-two .button-group .callback-button:hover {
 text-decoration: underline;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-feature-section-three .meta-info-text {
 color: var(--heading);
 margin-bottom: 0;
}

.card-style-three .icon {
 width: 100px;
 height: 60px;
 background: #161616;
 border-radius: 50%;
}

.card-style-three:hover .icon img {
 transform: rotate(360deg);
}

.card-style-three .title {
 font-size: 23px;
 padding: 0 0 3px;
 margin-bottom: 0;
 font-family: "avenirblack";
 color: #8163ff;
}

.card-style-three ul li {
 line-height: 39px;
 color: var(--heading);
}

.card-style-three ul li:hover {
 text-decoration: underline;
}

.card-style-three p {
 // line-height: 1.83em;
 font-family: "avenirroman";
 text-align: center;
 font-size: 18px;
 max-width: 100%;

 @media (max-width: 500px) {
  max-width: 90%;
 }
}

.vcamp-feature-section-three .service-title {
 display: inline-block;
 position: relative;
 font-size: 16px;
 line-height: 26px;
 padding: 0 5px;
 text-transform: uppercase;
 letter-spacing: 2px;
 color: #fff;
 background: #000;
}

/*--- Dark Version ---*/
.vcamp-feature-section-three.dark-bg {
 padding: 180px 0 350px;
 position: relative;
 z-index: 1;
}

.vcamp-feature-section-three.dark-bg .shape-one {
 top: -100px;
 right: 0;
 animation: fade-in 1.5s infinite linear;
}

.vcamp-feature-section-three.dark-bg .service-title {
 background: var(--red-one);
}

.dark-bg .card-style-three .icon {
 background: rgba(255, 255, 255, 0.1);
}

.dark-bg .card-style-three .title {
 color: #fff;
}

.dark-bg .card-style-three ul li {
 color: rgba(255, 255, 255, 0.65);
}

.vcamp-feature-section-three.dark-bg .border-line {
 background: rgba(255, 255, 255, 0.3);
 margin-bottom: 110px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Slider Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-three {
 position: relative;
 z-index: 1;
}

.feedback-section-three .shape-one {
 left: 0;
 bottom: -115px;
 animation: jumpTwo 5s infinite linear;
}

.feedback-section-three .inner-content {
 max-width: 1920px;
 margin: 0 auto;
 padding: 185px 0 185px 130px;
}

.feedback-section-three .text-wrapper {
 max-width: 400px;
 float: left;
}

.feedback-section-three .text-wrapper p {
 font-size: 20px;
 line-height: 1.75em;
 color: rgba(255, 255, 255, 0.7);
 padding: 30px 0 85px;
 margin-bottom: 0;
}

.feedback-section-three .slider-wrapper {
 max-width: 85%;
 float: right;
 margin-left: auto;
 margin-right: -350px;
}

.feedback_slider_three .block-wrapper {
 position: relative;
}

.feedback_slider_three .block-wrapper .screen {
 width: 100%;
}

.feedback_slider_three .block-wrapper .overlay-content {
 position: absolute;
 height: 285px;
 width: 100%;
 left: 0;
 right: 0;
 bottom: 0;
 padding: 0 30px 35px;
 opacity: 0;
 transform: translateY(100px);
 transition: all 0.4s ease-in-out;
 background: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.0001) 0%,
  rgba(255, 255, 255, 0.634119) 57.49%,
  #ffffff 100%
 );
}

.feedback_slider_three .block-wrapper:hover .overlay-content {
 opacity: 1;
 transform: translateY(0);
}

.feedback_slider_three .block-wrapper .overlay-content a {
 font-family: "gorditamedium";
 font-size: 22px;
 color: var(--heading);
 width: 100%;
}

.feedback_slider_three .slick-dots {
 position: absolute;
 left: -135px;
 bottom: 9%;
 padding: 0;
 margin-bottom: 0;
 width: auto;
}

.feedback_slider_three .slick-dots li {
 display: inline-block;
}

.feedback_slider_three .slick-dots li button {
 text-indent: -50000px;
 width: 10px;
 height: 10px;
 border-radius: 50%;
 border: 1px solid #fff;
 margin: 0 5px;
 transition: all 0.3s ease-in-out;
}

.feedback_slider_three .slick-dots li.slick-active button {
 background: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-feature-section-four {
 position: relative;
 z-index: 1;
}

.vcamp-feature-section-four .shape-one {
 right: 5%;
 top: -18%;
 animation: jumpThree 5s infinite linear;
}

.vcamp-feature-section-four .text-wrapper {
 background: #fff;
 box-shadow: 5px 5px 0px #000;
 border-radius: 24px;
 padding: 60px 15px 70px 70px;
 margin-right: 10px;
}

.vcamp-feature-section-four .text-wrapper .meta-info-text {
 padding: 37px 40px 50px 0;
}

.card-style-four .icon {
 height: 65px;
}

.card-style-four .icon img {
 max-height: 100%;
}

.card-style-four:hover .icon img {
 transform: translateY(-8px);
}

.card-style-four .title {
 font-size: 24px;
 padding: 30px 0 20px;
 margin-bottom: 0;
}

.card-style-four p {
 font-size: 17px;
 line-height: 1.76em;
 color: #4f4f4f;
 margin-bottom: 0;
}

.feature-section-baner-promo {
 // background: #876BFE;
 height: 100%;
}

.feature-section-baner-promo .body {
 font-family: "avenirblack";
 // color: #FFFFFF;
 padding: 40px 40px 20px;
 border-radius: 20px;
 width: 100%;

 h2 {
  text-align: center;
  font-size: 40px;

  @media (max-width: 1399px) {
   font-size: 34px;
  }

  @media (max-width: 992px) {
   font-size: 34px;
  }

  @media (max-width: 370px) {
   font-size: 30px;
  }
 }

 h3 {
  text-align: center;
  font-size: 50px;

  span {
   color: #876bfe;
  }

  @media (max-width: 450px) {
   font-size: 35px;
  }

  @media (max-width: 320px) {
   font-size: 30px;
  }
 }

 p {
  text-align: left;
  font-size: 22px;
  padding: 0 40px;
  width: 90%;

  // @media (max-width: 1399px) {
  //   font-size: 20px;
  // }

  @media (max-width: 992px) {
   font-size: 20px;
  }

  @media (max-width: 501px) {
   text-align: center;
   width: 95%;
   padding: 0 10px;
  }

  @media (max-width: 370px) {
   width: 100%;
   padding: 0 5px;
   font-size: 18px;
  }
 }

 button {
  background: #fff;
  color: #876bfe;
  padding: 5px 20px;
  border-radius: 20px;
  font-size: 24px;

  @media (max-width: 425px) {
   font-size: 20px;
  }
 }

 @media (max-width: 501px) {
  width: 100%;
  padding: 20px;
 }
}

.feature-section-baner-promo .body .content-image {
 position: relative;
 // width: 100%;

 img {
  width: auto;
  height: auto;
 }
}

.feature-section-baner-promo .body .content-image .overlay {
 right: 0%;
 bottom: 10%;
 position: absolute;
 background: #00fbc8;
 color: #100c22;
 padding: 10px 10px 4px;
 border-radius: 10px;
 overflow: hidden;

 p {
  font-size: 16px;
  margin-bottom: 0;
  height: 100%;
  width: max-content;
  padding: 0;

  span {
   color: #100c22;
   font-size: 40px;
   text-align: start;
  }

  span::before {
   content: "";
   width: 45%;
   position: absolute;
   bottom: 25px;
   border-bottom: 5px solid #8161f7;
   transform: rotate(-35deg);
  }
 }

 @media (min-width: 1200px) {
  font-size: 20px;
  // right: -10%;
  bottom: 10%;
 }

 @media (max-width: 450px) {
  font-size: 20px;
  // right: -10%;
  bottom: 10%;

  p span {
   font-size: 36px;
  }
 }
}

.feature-section-four {
 background: #322861;
}

.feature-section-four .content-section {
 background: #8163ff;
 font-family: "avenirblack";
 color: #ffffff;
 margin: 60px;
 padding: 50px;
 border-radius: 20px;
 width: 50%;
}

.feature-section-four .content-section .title {
 font-size: 42px;
 text-align: center;
 line-height: normal;
}

.feature-section-four .content-section .subtitle {
 font-size: 20px;
 font-family: "avenirroman";
 width: 65%;
 text-align: center;
}

.feature-section-four .content-section .months {
 display: flex;
 justify-content: space-evenly;
}

.feature-section-four .content-section .month {
 // background: #00FBC8;
 // color: #282828;
 font-size: 18px;
 text-align: center;
 width: 25%;
 border-radius: 20px;
 padding: 8px 0px;
}

.feature-section-four .content-section .content-price {
 background: #ffffff;
 color: #282828;
 font-size: 18px;
 font-weight: 800;
 text-align: center;
 line-height: normal;
 width: 65%;
 border-radius: 20px;
 padding: 15px 0px;
 margin-top: 35px;
}

.feature-section-four .content-section .content-price .title {
 background: #ffffff;
 color: #282828;
 font-size: 20px;
}

.feature-section-four .content-section .content-price .price {
 background: #ffffff;
 color: #8163ff;
 font-size: 40px;
 border-bottom: 1px solid #767676;
}

.feature-section-four .content-section .content-price .total-price {
 background: #ffffff;
 color: #282828;
 font-size: 22px;
 width: 100%;
 text-align: center;
}

.feature-section-four .content-section .content-price .discount {
 font-weight: normal;
 font-size: 20px;
 text-align: center;
 width: 100%;
}

.feature-section-four .content-section button {
 background: #3d2f75;
 color: #ffffff;
 font-size: 20px;
 margin-top: 25px;
 padding: 10px 15px;
 border-radius: 30px;
 width: 30%;
}

.feature-section-five {
 background: #fafafa;
 font-family: "avenirroman";
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 50px 0px 100px;

 @media (max-width: 767px) {
  padding: 50px 0px 70px;
 }
}

.feature-section-five .content-section {
 width: 80%;
 text-align: center;
 display: flex;
 flex-direction: column;
 gap: 30px;

 .title {
  text-align: center;
  color: #322861;
  font-weight: bold;
  font-size: 37px;
 }

 .content {
  display: flex;
  justify-content: space-between;
  // gap: 3.5rem;
 }

 @media (max-width: 767px) {
  width: 90%;

  .content {
   display: flex;
   flex-direction: column;
  }

  .title {
   font-size: 32px;
  }
 }
}

.feature-section-five .content-section .content ul {
 width: 40%;
 padding: 0px;
 margin: 0px;

 @media (max-width: 767px) {
  width: 100%;
 }

 button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;

  div {
   display: flex;
   align-items: center;
   gap: 8px;
   padding: 26px 0px;

   p {
    font-size: 21px;
    padding: 0px;
    margin: 0px;
   }

   img {
    width: 24px;
    height: 24px;
   }
  }
 }

 // button:hover {
 //   background-color: #8163FF;
 // }
}

.feature-section-five .content-section .content .content-img {
 width: 60%;
 display: flex;
 flex-direction: column;
 justify-items: center;
 align-items: center;
 gap: 12px;
 padding: 30px 0px;

 img {
  width: 611px;
  height: 342px;
 }

 div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  font-weight: bold;

  p {
   margin: 0px;
   padding: 0px;
   text-align: center;
  }

  button {
   font-weight: bold;
   width: max-content;
   padding: 0px 30px;
   background-color: #00fbc8;
   border-radius: 20px;
  }

  a {
   font-weight: bold;
   width: max-content;
   padding: 0px 30px;
   background-color: #00fbc8;
   border-radius: 20px;
  }
 }

 @media (max-width: 767px) {
  width: 100%;
  padding: 0px;

  img {
   width: auto;
   height: auto;
  }
 }
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Slider Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-four {
 background: url(../images/shape/shape_07.svg) no-repeat center;
 background-size: cover;
 position: relative;
 z-index: 1;
 padding: 214px 0 140px;
}

.feedback-section-four.bg-white {
 background: none;
}

.feedback-section-four .shape-two {
 top: -57px;
 left: 7%;
 animation: jumpThree 5s infinite linear;
}

.feedback-section-four .shape-three {
 top: -29%;
 right: 0;
 animation: fade-in 1.5s infinite linear;
}

.feedback-section-four .shape-four {
 bottom: -80px;
 right: 8%;
 animation: jumpThree 5s infinite linear;
}

.feedback-section-four .shape-five {
 top: 0;
 right: 0;
 animation: fade-in 1.5s infinite linear;
}

.feedback-section-four .image-container {
 position: relative;
 padding: 0 0 90px 70px;
}

.feedback-section-four .image-container.style-two:before {
 content: "";
 position: absolute;
 width: 75%;
 height: 83%;
 left: 5%;
 bottom: 11%;
 z-index: 1;
 mix-blend-mode: multiply;
 background-color: var(--red-one);
 transform: rotate(-5.5deg);
}

.feedback-section-four .image-container .shape-one {
 left: 0;
 bottom: 0;
 z-index: 1;
 animation: jumpThree 5s infinite linear;
}

.feedback-section-four .feedback-wrapper {
 padding: 0 25px 0 90px;
}

.feedback-section-four .feedback-wrapper .icon {
 width: 62px;
 height: 62px;
 padding: 15px;
 background: var(--heading);
 border-radius: 50%;
 margin-bottom: 35px;
}

.feedback-section-four .feedback-wrapper blockquote {
 font-size: 42px;
 line-height: 1.54em;
 color: var(--heading);
 padding-bottom: 30px;
}

.feedback-section-four .feedback-wrapper blockquote span {
 color: var(--red-one);
 position: relative;
 display: inline-block;
}

.feedback-section-four .feedback-wrapper blockquote span:before {
 content: "";
 position: absolute;
 width: 98%;
 height: 2px;
 background: var(--red-one);
 left: 2px;
 bottom: 14px;
}

.feedback-section-four .feedback-wrapper .name {
 font-family: "gorditamedium";
 font-size: 20px;
 color: #1b1b1b;
 padding-left: 35px;
 position: relative;
 line-height: 1.55em;
}

.feedback-section-four .feedback-wrapper .name span {
 font-size: 18px;
 font-family: "gorditaregular";
 color: #818181;
}

.feedback-section-four .feedback-wrapper .name:before {
 content: "";
 position: absolute;
 width: 23px;
 height: 2px;
 background: var(--heading);
 left: 0;
 top: 14px;
}

.feedback_slider_four .slick-dots {
 position: absolute;
 left: 0;
 width: 492px;
 bottom: 2%;
 text-align: right;
}

.feedback_slider_four .slick-dots li {
 display: inline-block;
}

.feedback_slider_four .slick-dots li button {
 text-indent: -50000px;
 width: 10px;
 height: 10px;
 border-radius: 50%;
 border: 1px solid var(--heading);
 margin: 0 4px;
 transition: all 0.3s ease-in-out;
}

.feedback_slider_four .slick-dots li.slick-active button {
 background: var(--heading);
}

/*^^^^^^^^^^^^^^^^^^^^^ Contact Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.contact-section-two {
 position: relative;
 z-index: 1;
}

/*^^^^^^^^^^^^^^^^^^^^^ Partner Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.partner-section-two {
 position: relative;
 z-index: 1;
}

.partner-section-two .shape-one {
 top: 37%;
 right: 10%;
 animation: jumpTwo 5s infinite linear;
}

.partner-section-two .partent-logos {
 max-width: 1170px;
 margin: 130px auto 0;
 border: 1px solid #dedede;
 border-bottom: none;
 border-right: none;
}

.partner-section-two .partent-logos .logo {
 height: 175px;
 border-bottom: 1px solid #dedede;
 border-right: 1px solid #dedede;
}

.partner-section-two .partent-logos .logo:hover img {
 transform: scale(1.1);
}

/*^^^^^^^^^^^^^^^^^^^^^ Address Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.address-section-two {
 position: relative;
 z-index: 6;
}

.address-section-two .inner-content {
 max-width: 1170px;
 margin: 0 auto -70px;
 background: #fff;
 border-radius: 0 0 15px 15px;
 position: relative;
}

.address-section-two.bg-color .inner-content {
 max-width: 100%;
 background: var(--light-cyan);
 border-radius: 0;
 margin: 0;
}

.address-section-two.bg-color .inner-content .shape-one {
 left: 1%;
 top: 4%;
 transform: translate(-50%, -50%);
 animation: fade-in 1s infinite linear;
}

.address-block-two {
 padding: 60px 15px 62px 80px;
}

.address-block-two.border-right {
 border-right: 1px solid #e4e4e4;
}

.address-block-two .text-meta {
 padding-left: 40px;
}

.address-block-two .text-meta .title {
 font-size: 24px;
 margin-bottom: 10px;
}

.address-block-two .text-meta p {
 font-size: 20px;
 line-height: 1.6em;
 color: #828282;
 margin-bottom: 0;
}

.address-block-two .text-meta p a {
 font-size: 24px;
 color: var(--red-one);
}

.address-block-two .text-meta p a:hover {
 text-decoration: underline;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-three {
 background-color: #161616;
 background-image: url(../images/shape/bg-1.png);
 background-position: top center;
 background-repeat: no-repeat;
 background-size: cover;
 padding: 190px 0 0;
 height: 1100px;
}

.hero-banner-three .hero-heading {
 font-size: 90px;
 line-height: 1.11em;
 color: #fff;
 margin-bottom: 0;
}

.hero-banner-three .sub-heading {
 vertical-align: middle;
 font-size: 32px;
 color: #fff;
 position: relative;
}

.hero-banner-three .sub-heading span {
 vertical-align: middle;
 width: 50px;
 height: 2px;
 background: #fff;
 margin-left: 5px;
}

.hero-banner-three .hero-sub-heading {
 font-size: 20px;
 line-height: 1.8em;
 color: #fff;
 padding: 20px 0 50px;
}

.hero-banner-three .button-group .case-study-button {
 font-family: "gorditamedium";
 font-size: 16px;
 color: #fff;
}

.hero-banner-three .button-group .case-study-button:hover {
 text-decoration: underline;
}

.hero-banner-three .slider-wrapper {
 padding: 0 12px;
 margin: 140px auto 0;
 position: relative;
 z-index: 1;
 overflow: hidden;
}

.hero-banner-three .slider-wrapper .video-wrapper {
 max-width: 1400px;
 margin: 0 auto;
 max-height: 790px;
 overflow: hidden;
 background: #fff;
}

.hero-banner-three .slider-wrapper .video-wrapper video {
 display: block;
 margin: 0 auto;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-two {
 background: var(--light-cyan);
 padding: 90px 0 0;
 position: relative;
 z-index: 1;
}

.vcamp-text-block-two .text-wrapper {
 padding: 0 0 100px 84px;
}

.vcamp-text-block-two .meta-info-text {
 padding: 45px 30px 45px 0;
}

.vcamp-text-block-two .img-container {
 z-index: 1;
 display: inline-block;
}

.vcamp-text-block-two .img-container:before {
 content: "";
 position: absolute;
 background: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.0001) 0%,
  rgba(255, 255, 255, 0.494482) 44.97%,
  var(--light-cyan) 100%
 );
 bottom: 0;
 left: 0;
 right: 0;
 height: 280px;
}

.vcamp-text-block-two .img-container .shape-one {
 left: 20px;
 bottom: 0;
 animation: fade-in 1.5s infinite linear;
}

.vcamp-text-block-two .quote-wrapper {
 position: absolute;
 width: 410px;
 background: #fff;
 bottom: -60px;
 left: -90px;
 z-index: 1;
 padding: 25px 30px 36px 48px;
 box-shadow: 0 30px 60px rgba(7, 23, 28, 0.08);
 animation: jumpTwo 8s infinite linear;
}

.vcamp-text-block-two .quote-wrapper blockquote {
 font-size: 20px;
 line-height: 1.75em;
 padding-bottom: 16px;
}

.vcamp-text-block-two .quote-wrapper .name {
 font-family: "gorditamedium";
 font-size: 20px;
 margin-bottom: 0;
}

.vcamp-text-block-two .quote-wrapper .name span {
 font-family: "gorditaregular";
 font-weight: normal;
 font-size: 18px;
 color: #9c9c9c;
}

.vcamp-text-block-two .quote-wrapper .icon {
 position: absolute;
 right: 43px;
 bottom: 32px;
}

.vcamp-text-block-two .shape-two {
 right: 0;
 top: -18%;
 animation: fade-in 1s infinite linear;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Slider Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-five .slider-wrapper {
 max-width: 1870px;
 padding: 0 12px;
 margin: 110px auto 0;
}

.feedback-section-five .feedback_slider_three .slick-dots {
 position: static;
 margin-top: 80px;
 text-align: center;
}

.feedback-section-five .feedback_slider_three .slick-dots li {
 display: inline-block;
}

.feedback-section-five .feedback_slider_three .slick-dots li button {
 border: none;
 background: #e4e7ef;
}

.feedback-section-five
 .feedback_slider_three
 .slick-dots
 li.slick-active
 button {
 background: #212121;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-six {
 background: var(--light-cyan);
 position: relative;
 z-index: 1;
 padding: 190px 0 115px;
}

.feedback-section-six .quote-icon {
 left: 50%;
 top: 0;
 transform: translate(-50%, -50%);
}

.feedback-section-six .shape-one {
 right: -20px;
 top: 0;
 transform: translateY(-50%);
 animation: fade-in 1s infinite linear;
}

.feedback-section-six .shape-two {
 left: 0;
 bottom: 0;
 transform: translateY(50%);
 animation: fade-in 1s infinite linear;
}

.feedback-section-six .slider-wrapper {
 max-width: 1920px;
 margin: 80px auto 0;
}

.feedback_slider_five .block-wrapper {
 background: #fff;
 padding: 38px 45px 42px;
}

.feedback_slider_five .block-wrapper .icon {
 height: 72px;
}

.feedback_slider_five .block-wrapper p {
 font-size: 20px;
 line-height: 1.75em;
 padding: 15px 0 30px;
}

.feedback_slider_five .block-wrapper .avatar {
 width: 45px;
 height: 45px;
 border-radius: 50%;
 margin-right: 25px;
}

.feedback_slider_five .block-wrapper .name {
 font-family: "gorditamedium";
 color: var(--heading);
 line-height: 25px;
}

.feedback_slider_five .block-wrapper .name span {
 font-family: "gorditaregular";
 color: #c1c1c1;
 display: block;
 font-size: 16px;
}

.feedback_slider_five .item {
 padding: 0 22px;
}

.feedback-section-six .slider-arrow li {
 width: 20px;
 line-height: 20px;
 text-align: center;
 cursor: pointer;
 margin: 0 8px;
 font-size: 35px;
 color: var(--heading);
 transition: all 0.3s ease-in-out;
 transform: scale(0.7);
}

.feedback-section-six .slider-arrow li:hover {
 opacity: 1;
 transform: scale(1);
}

/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.blog-meta-two .date {
 font-size: 14px;
 text-transform: uppercase;
 color: rgba(42, 42, 42, 0.7);
 margin: 30px 0 0;
}

.blog-meta-two .title {
 font-family: "gorditamedium";
 font-size: 28px;
 line-height: 1.3em;
 color: var(--heading);
 display: block;
 margin: 14px 0;
}

.blog-meta-two:hover .title {
 text-decoration: underline;
}

.blog-meta-two .post-meta .theme-btn-three {
 font-size: 16px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Contact Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.contact-section-three {
 position: relative;
 // z-index: 6;
}

.contact-section-three.image-bg {
 background: url(../images/media/powerbizzfondo.webp) no-repeat center;
 background-size: cover;
}

.contact-section-three.image-bg:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 z-index: -1;
 // background: rgba(0, 0, 0, 0.5);
}

.contact-section-three.image-bg2 {
 background: url(../images/media/powerbizz-banner.webp) no-repeat center;
 background-size: cover;
}

.contact-section-three.image-bg2:before {
 content: "";
 position: absolute;
 width: max-content;
 height: auto;
 top: 0;
 left: 0;
 z-index: -1;
 // background: rgba(0, 0, 0, 0.5);
}

.contact-section-three.image-bg .bg-wrapper {
 margin: 0;
}

.contact-section-three.image-bg .bg-wrapper:before {
 display: none;
}

.contact-section-three .bg-wrapper {
 background: #fff;
 padding: 100px 80px 85px;
 position: relative;
 box-shadow: 0 -10px 20px rgba(13, 24, 46, 0.03);
 margin-bottom: -200px;
}

.contact-section-three .bg-wrapper:before {
 content: "";
 position: absolute;
 left: 25px;
 right: 25px;
 top: -30px;
 height: 70%;
 box-shadow: 0 -10px 20px rgba(13, 24, 46, 0.03);
 z-index: -1;
}

.contact-section-three .form-style-two {
 padding-right: 40px;
}

.form-style-two label {
 font-size: 14px;
 color: rgba(0, 0, 0, 0.4);
 display: block;
 margin-bottom: -10px;
}

.form-style-two .input-group-meta input,
.form-style-two .input-group-meta textarea {
 display: block;
 width: 100% !important;
 max-width: 100%;
 font-size: 17px;
}

.form-style-two .input-group-meta input {
 height: 55px;
 border: none;
 border-bottom: 2px solid var(--heading);
}

.form-style-two .input-group-meta textarea {
 min-height: 140px;
 padding: 15px 20px;
 border: 2px solid var(--heading);
}

.contact-section-three .contact-meta-one p {
 padding-bottom: 20px;
}

.form-style-two .selectize-input {
 display: block !important;
 font-size: 16px;
 line-height: 53px;
 height: 55px;
 background: transparent;
 color: rgba(0, 0, 0, 0.6);
 padding: 0;
 border-radius: 0;
 border: none;
 border-bottom: 2px solid var(--heading);
 box-shadow: none;
}

.form-style-two .selectize-input .item {
 line-height: 53px;
 color: var(--heading);
 display: block;
}

.form-style-two .selectize-dropdown {
 border: none;
 border-radius: 0;
 box-shadow: 0 13px 35px -12px rgb(35 35 35 / 10%);
 margin: 0;
}

.form-style-two .selectize-dropdown .option {
 font-size: 16px;
 color: #4f4f4f;
 line-height: 35px;
}

.form-style-two .selectize-dropdown .option:hover,
.form-style-two .selectize-dropdown .active {
 color: #fff;
 background: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-four {
 margin: 135px 0 -100px;
 position: relative;
 background: #161616;
 z-index: 5;
}

.hero_slider_two .content-wrapper {
 position: relative;
}

.hero_slider_two .hero-img {
 width: 100%;
 min-height: 800px;
 height: calc(100vh - 135px);
 object-fit: cover;
 object-position: top center;
}

.hero_slider_two .slider-inner {
 position: absolute;
 width: 620px;
 right: 80px;
 bottom: 0;
 padding: 70px 15px 50px 80px;
}

.hero_slider_two .slider-inner:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 mix-blend-mode: multiply;
 background-color: #eb0000;
}

.hero_slider_two .hero-content .video-icon {
 width: 70px;
 height: 70px;
 border-radius: 50%;
 background: var(--heading);
 padding-right: 5px;
 position: relative;
 animation: ripple 3s linear infinite;
}

.hero_slider_two .hero-content .video-icon::before,
.hero_slider_two .hero-content .video-icon::after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 border-radius: 50%;
 animation: inherit;
 animation-delay: 1s;
}

.hero_slider_two .hero-content .video-icon::after {
 animation-delay: 3s;
}

@keyframes ripple {
 0% {
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.08);
 }

 100% {
  box-shadow: 0 0 0 3rem rgba(0, 0, 0, 0);
 }
}

.hero_slider_two .hero-content .hero-heading {
 font-family: "gorditabold";
 font-size: 85px;
 line-height: 1.1em;
 color: #fff;
 padding: 40px 0 40px;
 margin-bottom: 0;
}

.hero_slider_two .hero-content .hero-sub-heading {
 font-size: 22px;
 line-height: 1.75em;
 color: #fff;
 padding: 0 60px 40px 0;
}

.hero_slider_two .hero-content .theme-btn-one {
 width: 200px;
}

@-webkit-keyframes fadeInUpSD {
 0% {
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
 }

 100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
 }
}

@keyframes fadeInUpSD {
 0% {
  opacity: 0;
  -webkit-transform: translateX(100px);
  transform: translateX(100px);
 }

 100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
 }
}

.hero_slider_two .slick-active .hero-content {
 animation-name: fadeInUpSD;
 animation-duration: 1s;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-three {
 position: relative;
 padding: 300px 0 200px;
 z-index: 1;
}

.vcamp-text-block-three .text-wrapper {
 padding-left: 50px;
}

.vcamp-text-block-three .text-wrapper h6 {
 font-size: 20px;
 text-transform: uppercase;
 letter-spacing: 2px;
 color: var(--red-two);
}

.vcamp-text-block-three .text-wrapper p {
 font-family: "gorditalight";
 font-size: 60px;
 line-height: 1.36em;
 color: #fff;
 letter-spacing: -1px;
 padding: 27px 0 34px;
}

.vcamp-text-block-three .text-wrapper p span {
 color: var(--red-two);
}

.vcamp-text-block-three .text-wrapper .name {
 font-family: "gorditalight";
 font-size: 24px;
 color: #fff;
 font-style: italic;
 padding-left: 30px;
}

.vcamp-text-block-three .text-wrapper .name:before {
 content: "";
 width: 19px;
 height: 2px;
 position: absolute;
 background: #fff;
 left: 0;
 top: 15px;
}

.vcamp-text-block-three .img-container {
 padding: 0 0 7% 7%;
}

.vcamp-text-block-three .img-container:before {
 content: "";
 position: absolute;
 z-index: -1;
 top: 7%;
 right: 7%;
 left: 0;
 bottom: 0;
 background: #ff3c3c;
}

.vcamp-text-block-three .shape-one {
 right: 0;
 bottom: -110px;
 animation: fade-in 1s infinite linear;
}

.vcamp-text-block-three .counter-block {
 margin-bottom: 30px;
}

.vcamp-text-block-three .counter-block .main-count {
 font-family: "gorditabold";
 font-size: 58px;
 color: #fff;
 line-height: initial;
 margin-bottom: 8px;
}

.vcamp-text-block-three .counter-block p {
 color: #fff;
}

.vcamp-text-block-three .text-block h4 {
 display: inline-block;
 font-size: 28px;
 color: #fff;
 margin: 20px 0 21px;
 padding-right: 10px;
 position: relative;
}

.vcamp-text-block-three .text-block h4:before {
 content: "";
 position: absolute;
 width: 35px;
 height: 1px;
 background: var(--red-two);
 bottom: 6px;
 left: 100%;
}

.vcamp-text-block-three .text-block p {
 color: rgba(255, 255, 255, 0.7);
 margin-bottom: 35px;
 line-height: 36px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-four .left-side {
 background: url(../images/media/img_23.jpg) no-repeat center;
 background-size: cover;
}

.vcamp-text-block-four .right-side {
 background: url(../images/media/img_24.jpg) no-repeat center;
 background-size: cover;
}

.vcamp-text-block-four .right-side .content-wrapper:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 mix-blend-mode: multiply;
 background-color: #eb0000;
}

.vcamp-text-block-four .text-wrapper {
 max-width: 660px;
 padding: 145px 0 130px;
}

.vcamp-text-block-four .right-side .text-wrapper {
 padding-left: 55px;
}

.vcamp-text-block-four .text-wrapper .title {
 font-family: "gorditabold";
 font-size: 68px;
 line-height: 1.17em;
 color: #fff;
}

.vcamp-text-block-four .text-wrapper p {
 font-family: "gorditalight";
 font-size: 22px;
 color: #fff;
 padding: 40px 0 34px;
}

.vcamp-text-block-four .text-wrapper .more-button {
 width: 54px;
 height: 54px;
 border-radius: 50%;
 background: var(--heading);
 text-align: center;
 line-height: 54px;
 color: #fff;
 font-size: 26px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Case Study Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.case-study-one {
 position: relative;
 padding: 200px 0 250px;
 z-index: 1;
}

.case-study-one .slick-list {
 max-width: 1920px;
 margin: 100px auto 0;
 transform: translateX(14.2%);
}

.case-block-one .img-meta {
 min-height: 595px;
 object-fit: cover;
 object-position: top center;
}

.case-block-one .hover-content {
 position: absolute;
 top: 4%;
 right: 4%;
 bottom: 4%;
 left: 4%;
 z-index: 1;
 padding: 50px 22px 30px 50px;
 color: #fff;
 background: rgb(235, 0, 0, 0.85);
 opacity: 0;
 transform: translateX(40px);
 transition: all 0.4s ease-in-out;
}

.case-block-one .hover-content .icon {
 width: 65px;
 height: 65px;
 padding: 10px;
 background: var(--heading) 000;
 border-radius: 50%;
}

.case-block-one .hover-content blockquote {
 font-size: 28px;
 line-height: 1.6em;
 padding: 35px 0 75px;
 margin-bottom: 0;
}

.case-block-one .hover-content .web {
 font-family: "gorditamedium";
 font-size: 25px;
 color: #fff;
 text-decoration: underline;
}

.case-block-one .hover-content .arrow {
 display: inline-block;
 width: 39px;
 line-height: 28px;
 font-size: 16px;
 border-radius: 15px;
 text-align: center;
 color: #fff;
 transition: all 0.3s ease-in-out;
 background: rgba(0, 0, 0, 0.15);
}

.case-block-one .hover-content a:hover .arrow {
 background: var(--heading);
}

.case-block-one:hover .hover-content {
 opacity: 1;
 transform: translateX(0);
}

.case_slider_one .item {
 padding: 0 30px;
}

.case-study-one .slider-arrows li {
 width: 50px;
 height: 50px;
 border-radius: 50%;
 text-align: center;
 line-height: 50px;
 color: #fff;
 font-size: 28px;
 margin-left: 12px;
 cursor: pointer;
 transform: scale(0.8);
 transition: all 0.3s ease-in-out;
}

.case-study-one .slider-arrows li:hover {
 background: rgba(255, 255, 255, 0.2);
 color: #fff;
 transform: scale(1);
}

.case-study-one .shape-one {
 left: 0;
 bottom: -110px;
 animation: fade-in 1s infinite linear;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-five .text-wrapper p {
 padding-top: 55px;
 margin-bottom: 0;
}

.accordion-style-one .accordion-item {
 border: none;
 border-radius: 0;
}

.accordion-style-one .accordion-button {
 font-family: "gorditamedium";
 font-size: 24px;
 padding: 30px 0;
 box-shadow: none;
 border-radius: 0;
 color: var(--heading);
 background: transparent;
 transition: all 0.3s ease-in-out;
}

.accordion-style-one .accordion-button:not(.collapsed) {
 padding-bottom: 15px;
}

.accordion-style-one .accordion-body {
 padding: 0 25px 1px 0;
}

.accordion-style-one .accordion-body p {
 font-size: 20px;
 line-height: 1.8em;
}

.accordion-style-one .accordion-button::after {
 content: "+";
 font-family: "gorditaregular";
 font-size: 28px;
 background-image: none;
 width: auto;
 height: auto;
}

.accordion-style-one .accordion-button:not(.collapsed)::after {
 content: "-";
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-seven {
 position: relative;
 z-index: 5;
}

.feedback_slider_six .feedback-wrapper .icon {
 width: 58px;
 height: 58px;
 padding: 15px;
 background: var(--heading);
 border-radius: 50%;
 margin-bottom: 50px;
}

.feedback_slider_six .feedback-wrapper blockquote {
 font-size: 28px;
 line-height: 1.84em;
 color: var(--heading);
 padding-bottom: 46px;
}

.feedback_slider_six .feedback-wrapper .name {
 font-family: "gorditamedium";
 font-size: 20px;
 color: var(--heading);
 padding-left: 54px;
 position: relative;
 line-height: 1.55em;
}

.feedback_slider_six .feedback-wrapper .name span {
 font-family: "gorditaregular";
 color: #818181;
}

.feedback_slider_six .feedback-wrapper .name:before {
 content: "";
 position: absolute;
 width: 36px;
 height: 2px;
 background: var(--heading);
 left: 0;
 top: 14px;
}

.feedback_slider_six .slick-dots {
 position: absolute;
 right: 0;
 bottom: -5px;
 text-align: right;
 margin: 0;
 padding: 0;
}

.feedback_slider_six .slick-dots li {
 display: inline-block;
}

.feedback_slider_six .slick-dots li button {
 text-indent: -50000px;
 width: 8px;
 height: 8px;
 border-radius: 50%;
 border: none;
 background: #e4e7ef;
 margin: 0 4px;
 transition: all 0.3s ease-in-out;
}

.feedback_slider_six .slick-dots li.slick-active button {
 background: var(--red-two);
}

/*^^^^^^^^^^^^^^^^^^^^^ Counter Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.counter-section-two {
 position: absolute;
 left: 0;
 top: 0;
 bottom: 0;
 width: 46%;
}

.counter-section-two .img-meta {
 max-height: 100%;
 min-height: 100%;
 object-fit: cover;
 object-position: top center;
}

.counter-section-two .counter-container {
 position: absolute;
 width: 504px;
 background: #fff;
 z-index: 1;
 right: 0;
 bottom: 0;
}

.counter-block-two {
 padding: 30px 10px;
}

.counter-block-two .main-count {
 font-size: 52px;
 color: var(--heading);
 line-height: initial;
}

.counter-block-two p {
 color: #969696;
 font-size: 17px;
 margin-bottom: 0;
}

.counter-block-two.bg-color {
 background: var(--red-two);
}

.counter-block-two.bg-color .main-count,
.counter-block-two.bg-color p {
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Team Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.team_slider_one {
 margin: 120px auto 0;
}

.team-block-one .overlay-bg {
 position: absolute;
 bottom: 0;
 left: 0;
 right: 0;
 height: 60%;
 padding: 0 0 38px 40px;
 background: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0.0001) 0%,
  rgba(0, 0, 0, 0.544058) 62%,
  rgba(0, 0, 0, 0.615498) 100%
 );
 z-index: 1;
}

.team-block-one .name {
 font-family: "gorditamedium";
 font-size: 24px;
 color: #fff;
}

.team-block-one .position {
 color: #fff;
 padding: 2px 0 30px;
}

.team-block-one .social-icon a {
 width: 36px;
 height: 36px;
 line-height: 34px;
 text-align: center;
 border: 1px solid #fff;
 border-radius: 50%;
 color: #fff;
 font-size: 15px;
 margin-right: 15px;
 transition: all 0.3s ease-in-out;
 opacity: 0;
}

.team-block-one .social-icon a:hover {
 background: var(--red-two);
 border-color: var(--red-two);
}

.team-block-one .hover-content {
 transform: translateY(34px);
}

.team-block-one:hover .hover-content {
 transform: translateY(0);
}

.team-block-one:hover .social-icon a {
 opacity: 1;
}

.team_slider_one .item {
 padding: 0 20px;
}

.team-section-one .slider-arrows li {
 width: 50px;
 height: 50px;
 border-radius: 50%;
 text-align: center;
 line-height: 50px;
 color: var(--heading);
 font-size: 28px;
 margin-left: 12px;
 cursor: pointer;
 transform: scale(0.8);
 transition: all 0.3s ease-in-out;
}

.team-section-one .slider-arrows li:hover {
 background: var(--heading);
 color: #fff;
 transform: scale(1);
}

/*--- Dark Version ---*/
.team-section-one.dark-bg {
 padding: 120px 0 390px;
}

.team-section-one .mixitUp-container {
 margin: 0 -20px;
}

.team-section-one .mix {
 width: calc(100% / 4 - (((4 - 1) * 1rem) / 4));
 display: inline-block;
 margin-top: 40px;
 padding: 0 20px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Fancy Banner One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-banner-one {
 background: url(../images/media/img_30.jpg) no-repeat center;
 background-size: cover;
 position: relative;
}

.fancy-banner-one:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 mix-blend-mode: multiply;
 background-color: #eb0000;
}

.fancy-banner-one .inner-content {
 padding: 125px 0;
}

.fancy-banner-one p {
 font-size: 24px;
 line-height: 1.75em;
 color: #fff;
 padding-bottom: 24px;
}

.fancy-banner-one .theme-btn-one {
 width: 300px;
 line-height: 75px;
 font-size: 20px;
 background: var(--heading);
}

/*^^^^^^^^^^^^^^^^^^^^^ Footer Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-footer-two.dark-footer {
 background: #121212;
}

.vcamp-footer-two .title {
 font-size: 16px;
 text-transform: uppercase;
 color: rgba(0, 0, 0, 0.7);
 letter-spacing: 3px;
 padding-bottom: 25px;
 margin-bottom: 0;
}

.vcamp-footer-two .footer-list a {
 line-height: 40px;
 color: var(--heading);
 transition: all 0.3s ease-in-out;
}

.vcamp-footer-two .footer-list a:hover,
.vcamp-footer-two.dark-footer .footer-list a:hover {
 text-decoration: underline;
 color: var(--red-two);
}

.vcamp-footer-two .newsletter p {
 font-size: 19px;
 color: var(--heading);
 padding: 10px 0 24px;
}

.vcamp-footer-two .newsletter p span {
 text-decoration: underline;
 color: var(--red-two);
}

.vcamp-footer-two .newsletter p span.color2 {
 color: var(--red-one);
}

.vcamp-footer-two .newsletter form {
 height: 70px;
 position: relative;
 margin-bottom: 12px;
}

.vcamp-footer-two .newsletter form input {
 width: 100%;
 height: 100%;
 border: none;
 background: #eeeeee;
 border-radius: 8px;
 padding: 0 125px 0 20px;
 font-size: 16px;
 color: var(--heading);
}

.vcamp-footer-two .newsletter form ::placeholder {
 color: rgba(0, 0, 0, 0.5);
 opacity: 1;
 /* Firefox */
}

.vcamp-footer-two .newsletter form :-ms-input-placeholder {
 color: rgba(0, 0, 0, 0.5);
}

.vcamp-footer-two .newsletter form ::-ms-input-placeholder {
 color: rgba(0, 0, 0, 0.5);
}

.vcamp-footer-two .newsletter form button {
 position: absolute;
 font-size: 16px;
 font-family: "gorditamedium";
 top: 8px;
 right: 8px;
 bottom: 8px;
 width: 115px;
 border-radius: 8px;
 background: var(--red-two);
 color: #fff;
 transition: all 0.3s ease-in-out;
}

.vcamp-footer-two .newsletter form button.dark-btn {
 background: var(--heading);
}

.vcamp-footer-two .newsletter form button.color2-btn {
 background: var(--red-one);
}

.vcamp-footer-two .newsletter form button.dark-btn:hover {
 background: var(--red-two);
}

.vcamp-footer-two .newsletter .info {
 font-size: 14px;
 color: #979797;
}

.vcamp-footer-two .bottom-footer {
 border-top: 1px solid rgba(0, 0, 0, 0.08);
 padding: 35px 0 0;
 margin-top: 45px;
}

.vcamp-footer-two .bottom-footer .footer-nav a {
 font-family: "gorditamedium";
 font-size: 16px;
 color: var(--heading);
 margin-right: 25px;
}

.vcamp-footer-two .bottom-footer .footer-nav a:hover {
 text-decoration: underline;
}

.vcamp-footer-two .bottom-footer .copyright {
 font-size: 16px;
 color: #373737;
}

.vcamp-footer-two .bottom-footer .social-icon a {
 font-size: 20px;
 margin-left: 20px;
 color: var(--heading);
 transition: all 0.3s ease-in-out;
}

.vcamp-footer-two .bottom-footer .social-icon a:hover,
.vcamp-footer-two.dark-footer .bottom-footer .social-icon a:hover {
 color: var(--red-two);
}

.vcamp-footer-two.dark-footer .title {
 color: rgba(255, 255, 255, 0.5);
}

.vcamp-footer-two.dark-footer .footer-list a,
.vcamp-footer-two.dark-footer .newsletter p,
.vcamp-footer-two.dark-footer .bottom-footer .footer-nav a,
.vcamp-footer-two.dark-footer .bottom-footer .social-icon a,
.vcamp-footer-two.dark-footer .newsletter form input {
 color: #fff;
}

.vcamp-footer-two.dark-footer .newsletter form input {
 background: rgba(255, 255, 255, 0.11);
}

.vcamp-footer-two.dark-footer .newsletter form ::placeholder {
 color: rgba(255, 255, 255, 0.5);
 opacity: 1;
 /* Firefox */
}

.vcamp-footer-two.dark-footer .newsletter form :-ms-input-placeholder {
 color: rgba(255, 255, 255, 0.5);
}

.vcamp-footer-two.dark-footer .newsletter form ::-ms-input-placeholder {
 color: rgba(255, 255, 255, 0.5);
}

.vcamp-footer-two.dark-footer .bottom-footer {
 border-top-color: rgba(255, 255, 255, 0.08);
}

.vcamp-footer-two.dark-footer .bottom-footer .copyright {
 color: rgba(255, 255, 255, 0.7);
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-five {
 position: relative;
 z-index: 1;
}

.hero-banner-five .video-icon {
 width: 65px;
 height: 65px;
 border-radius: 50%;
 background: var(--heading);
 padding: 15px 16px 18px 13px;
 position: relative;
 animation: ripple 3s linear infinite;
}

.hero-banner-five .video-icon::before,
.hero-banner-five .video-icon::after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 border-radius: 50%;
 animation: inherit;
 animation-delay: 1s;
}

.hero-banner-five .video-icon::after {
 animation-delay: 3s;
}

.hero-banner-five .hero-heading {
 font-family: "gorditabold";
 line-height: 1.1em;
 padding: 38px 0 64px;
 margin-bottom: 0;
}

.hero-banner-five .hero-sub-heading {
 color: var(--heading);
 padding-bottom: 36px;
}

.hero-banner-five form {
 max-width: 565px;
 height: 80px;
 position: relative;
}

.hero-banner-five form input {
 border: none;
 background: #f4f4f4;
 border-radius: 7px;
 width: 100%;
 height: 100%;
 font-size: 16px;
 padding: 0 190px 0 30px;
}

.hero-banner-five form button {
 position: absolute;
 right: 10px;
 top: 8px;
 bottom: 8px;
 background: #242424;
 width: 180px;
 border-radius: 7px;
 text-align: center;
 color: #fff;
 font-size: 16px;
 transition: all 0.3s ease-in-out;
}

.hero-banner-five form button:hover {
 background: var(--red-one);
}

.hero-banner-five form ::-webkit-input-placeholder {
 /* Edge */
 color: #0b0b0b;
}

.hero-banner-five form :-ms-input-placeholder {
 /* Internet Explorer 10-11 */
 color: #0b0b0b;
}

.hero-banner-five .term-text {
 font-size: 16px;
 color: #979797;
 padding-top: 20px;
 margin-bottom: 0;
}

.hero-banner-five .term-text a {
 color: var(--heading);
}

.hero-banner-five .term-text a:hover {
 text-decoration: underline;
}

.hero-banner-five .illustration-container {
 position: absolute;
 left: 0;
 top: 0;
 z-index: -1;
 max-width: 46%;
 animation: jumpTwo 5s infinite linear;
}

/*^^^^^^^^^^^^^^^^^^^^^ Case Study Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.case-block-two .img-meta {
 min-height: 350px;
 object-fit: cover;
 object-position: top center;
}

.case-block-two .hover-content {
 position: absolute;
 top: 15px;
 right: 15px;
 left: 15px;
 bottom: 15px;
 color: #fff;
 font-size: 35px;
 opacity: 0;
 background: rgba(0, 0, 0, 0.8);
 transition: all 0.3s ease-in-out;
}

.case-block-two .hover-content a {
 color: #fff;
}

.case-block-two:hover .hover-content {
 opacity: 1;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Fancy Banner Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-banner-two {
 background: url(../images/media/img_35.jpg) no-repeat center;
 background-size: cover;
 height: 800px;
}

.fancy-banner-two.sm {
 height: 690px;
}

.fancy-banner-two .video-icon {
 width: 110px;
 height: 110px;
 border-radius: 50%;
 background: var(--heading);
 padding-right: 8px;
 position: relative;
 animation: ripple 3s linear infinite;
}

.fancy-banner-two .video-icon::before,
.fancy-banner-two .video-icon::after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 border-radius: 50%;
 animation: inherit;
 animation-delay: 1s;
}

.fancy-banner-two .video-icon::after {
 animation-delay: 3s;
}

.fancy-banner-two .counter-section-two {
 position: absolute;
 right: 0;
 bottom: 0;
 top: auto;
 width: 100%;
}

.fancy-banner-two .counter-block-two {
 padding: 20px 10px;
}

.fancy-banner-two .counter-block-two .main-count {
 font-family: "gorditamedium";
 font-size: 60px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-eight .feedback-wrapper {
 padding: 0 25px 0 95px;
}

.feedback-section-eight .feedback-wrapper .icon {
 width: 62px;
 height: 62px;
 padding: 15px;
 background: var(--heading);
 border-radius: 50%;
 margin-bottom: 27px;
}

.feedback-section-eight .feedback-wrapper blockquote {
 font-size: 48px;
 line-height: 1.687em;
 color: var(--heading);
 padding-bottom: 50px;
}

.feedback-section-eight .feedback-wrapper blockquote span {
 color: var(--red-two);
 position: relative;
 display: inline-block;
}

.feedback-section-eight .feedback-wrapper blockquote span:before {
 content: "";
 position: absolute;
 width: 98%;
 height: 2px;
 background: var(--red-two);
 left: 2px;
 bottom: 14px;
}

.feedback-section-eight .feedback-wrapper .name {
 font-family: "gorditamedium";
 font-size: 20px;
 color: #1b1b1b;
 padding-left: 35px;
 position: relative;
 line-height: 1.55em;
}

.feedback-section-eight .feedback-wrapper .name span {
 font-size: 18px;
 font-family: "gorditaregular";
 color: #818181;
}

.feedback-section-eight .feedback-wrapper .name:before {
 content: "";
 position: absolute;
 width: 23px;
 height: 2px;
 background: var(--heading);
 left: 0;
 top: 18px;
}

.feedback-section-eight .feedback_slider_four .slick-dots {
 position: static;
 text-align: right;
 width: 495px;
 margin-top: 38px;
}

.feedback-section-eight .feedback_slider_four .slick-dots li button {
 margin: 0 0 0 7px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.blog-section-three {
 background: var(--light-cyan);
 padding: 130px 0 160px;
}

.blog-meta-three {
 position: relative;
 z-index: 1;
 padding: 35px 50px 115px;
 background: #ffffff;
 box-shadow: 0px 30px 70px rgba(31, 44, 64, 0.03);
 border-radius: 8px;
 width: 100%;
 height: 100%;
 transition: all 0.3s ease-in-out;
}

.blog-meta-three:hover {
 transform: translateY(-5px);
}

.blog-meta-three .tag {
 font-family: "gorditamedium";
 display: inline-block;
 line-height: 27px;
 border-radius: 20px;
 letter-spacing: 1px;
 background: var(--heading);
 color: #fff;
 font-size: 11px;
 text-transform: uppercase;
 padding: 0 20px;
 transition: all 0.3s ease-in-out;
}

.blog-meta-three .tag:hover {
 box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.blog-meta-three .title {
 font-family: "gorditamedium";
 font-size: 24px;
 line-height: 1.3em;
 color: var(--heading);
 display: block;
 margin: 23px 0 28px;
}

.blog-meta-three:hover .title {
 text-decoration: underline;
}

.blog-meta-three .post-info {
 font-family: "gorditamedium";
 font-size: 16px;
 color: var(--heading);
}

.blog-meta-three .post-info .date {
 font-family: "gorditaregular";
 color: #aaaaaa;
}

.blog-meta-three .post-footer {
 position: absolute;
 left: 50px;
 right: 50px;
 bottom: 27px;
}

.blog-meta-three .post-footer .read-more:hover {
 transform: translateX(-5px);
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Fancy Banner Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-banner-three {
 background: url(../images/media/img_39.jpg) no-repeat center;
 background-size: cover;
 position: relative;
}

.fancy-banner-three.bg-white {
 background: none;
}

.fancy-banner-three.bg-white .title {
 color: var(--heading);
}

.fancy-banner-three .title {
 font-size: 72px;
 line-height: 1.16em;
 color: #fff;
 margin-bottom: 0;
}

.fancy-banner-three .title span {
 text-decoration: underline;
}

.fancy-banner-three .inner-content {
 padding: 110px 0;
}

.fancy-banner-three [class*="theme-btn-"] {
 width: 230px;
 line-height: 68px;
 font-size: 20px;
 border-radius: 8px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Fancy Banner Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-banner-four .bg-wrapper {
 background: var(--light-blue);
 padding: 80px 0;
 position: relative;
 z-index: 5;
 margin-top: -190px;
}

.fancy-banner-four .shape-one {
 top: -87px;
 left: -92px;
 z-index: 1;
 animation: fade-in 1.5s infinite linear;
}

.fancy-banner-four .title {
 font-size: 72px;
 line-height: 1.16em;
 margin-bottom: 0;
}

.fancy-banner-four .title span {
 text-decoration: underline;
}

.fancy-banner-four .inner-content {
 padding: 140px 0;
}

.fancy-banner-four [class*="theme-btn-"] {
 font-family: "gorditaregular";
 width: 210px;
 line-height: 60px;
 font-size: 20px;
 border-radius: 8px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Inside Page Banner One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.inside-hero-one {
 background: #e7e1f2;
 text-align: center;
 padding: 105px 0 60px;
}

.inside-hero-one .page-title {
 font-size: 60px;
 font-weight: 500;
 font-family: "avenirblack";
}

.inside-hero-one p {
 font-size: 30px;
 padding: 20px 0;
 margin-bottom: 0;
 font-family: "avenirbook";
 max-width: 60%;
}

/*^^^^^^^^^^^^^^^^^^^^^ Inside Page Banner Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.inside-hero-two {
 background-size: cover;
 background-repeat: no-repeat;
 background-position: top center;
 text-align: center;
 padding: 250px 0 230px;
 position: relative;
 z-index: 1;
}

.inside-hero-two .page-title {
 font-size: 72px;
 font-family: "gorditamedium";
 color: #fff;
}

.inside-hero-two p {
 font-size: 20px;
 padding-top: 27px;
 color: #fff;
 margin-bottom: 0;
}

.inside-hero-two .shape-one {
 right: 0;
 bottom: -80px;
 animation: fade-in 1.5s infinite linear;
}

/*^^^^^^^^^^^^^^^^^^^^^ Inside Page Banner Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.inside-hero-three {
 padding: 225px 0 150px;
 position: relative;
 z-index: 1;
}

.inside-hero-three.bg-color {
 background: #f9f2ec;
}

.inside-hero-three .shape-one {
 bottom: -60px;
 right: 6%;
 animation: jumpTwo 4s infinite linear;
}

.inside-hero-three .page-title {
 font-size: 72px;
 line-height: 1.25em;
 font-weight: 500;
 margin-bottom: 0;
}

.inside-hero-three.dark-bg .page-title {
 color: #fff;
}

.inside-hero-three .page-title span {
 position: relative;
 display: inline-block;
 color: var(--red-one);
}

.inside-hero-three .page-title span:before {
 content: "";
 width: 100%;
 height: 4px;
 position: absolute;
 bottom: 5px;
 left: 0;
 background: var(--red-one);
}

.inside-hero-three p {
 font-size: 24px;
 line-height: 1.83em;
 margin-bottom: 0;
}

.inside-hero-three.dark-bg p {
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.card-style-five {
 position: relative;
 z-index: 5;
 overflow: hidden;
}

.card-style-five:before {
 content: "";
 position: absolute;
 left: 0;
 right: 0;
 bottom: 0;
 height: 60%;
 z-index: 0;
 background: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0.0001) 0%,
  rgba(0, 0, 0, 0.358835) 50.1%,
  rgba(0, 0, 0, 0.615498) 100%
 );
}

.card-style-five .img-meta {
 width: 100%;
 min-height: 680px;
 object-fit: cover;
 object-position: top center;
}

.card-style-five .hover-content {
 position: absolute;
 left: 0;
 bottom: 0;
 right: 0;
 z-index: 1;
 padding: 30px 60px 68px 50px;
 transform: translateY(72px);
 transition: all 0.3s ease-in-out;
}

.card-style-five .hover-content:before {
 content: "";
 position: absolute;
 left: 20px;
 right: 20px;
 bottom: 25px;
 top: 0;
 background: var(--red-one);
 z-index: -1;
 opacity: 0;
 transition: all 0.3s ease-in-out;
}

.card-style-five .hover-content .title a {
 display: block;
 position: relative;
 font-size: 42px;
 line-height: 1.23em;
 color: #fff;
 margin-bottom: 10px;
 transition: all 0.3s ease-in-out;
}

.card-style-five .hover-content .title a:before {
 content: "+";
 position: absolute;
 right: 0;
 bottom: -10px;
 color: #fff;
 transition: all 0.3s ease-in-out;
}

.card-style-five:hover .hover-content .title a:before,
.slick-center .card-style-five .hover-content .title a:before {
 content: "-";
 transform: rotate(360deg);
}

.card-style-five .hover-content p {
 font-size: 20px;
 line-height: 1.75em;
 margin-bottom: 0;
 color: #fff;
 transition: all 0.3s ease-in-out;
 opacity: 0;
 transform: scale(1, 0);
}

.card-style-five:hover .hover-content p,
.slick-center .card-style-five .hover-content p {
 opacity: 1;
 transform: scale(1);
}

.card-style-five:hover .hover-content:before,
.slick-center .card-style-five .hover-content:before {
 opacity: 1;
}

.card-style-five:hover .hover-content,
.slick-center .card-style-five .hover-content {
 transform: translateY(0);
}

/*^^^^^^^^^^^^^^^^^^^^^ Partner Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.partner-section-three .logo {
 height: 50px;
 margin-top: 43px;
}

.partner-section-three .logo:hover img {
 transform: scale(1.1);
}

.partner-section-three .info-text {
 font-size: 20px;
 padding-bottom: 10px;
}

.partner-section-three .info-text span {
 font-family: "gorditamedium";
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-nine .bg-wrapper {
 padding: 120px 0 110px;
 background: var(--deep-cyan);
 position: relative;
 z-index: 5;
}

.feedback_slider_seven {
 max-width: 1530px;
 margin: 80px auto 0;
}

.feedback_slider_seven .item {
 padding: 0 22px;
}

.feedback_slider_seven .slick-dots {
 text-align: center;
 margin-top: 75px;
}

.feedback_slider_seven .slick-dots li {
 display: inline-block;
}

.feedback_slider_seven .slick-dots li button {
 text-indent: -50000px;
 width: 10px;
 height: 10px;
 border-radius: 50%;
 border: 1px solid var(--heading);
 margin: 0 4px;
 transition: all 0.3s ease-in-out;
}

.feedback_slider_seven .slick-dots li.slick-active button {
 background: var(--heading);
}

/*^^^^^^^^^^^^^^^^^^^^^ Counter Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.counter-block-three .main-count {
 font-family: "gorditabold";
 font-size: 58px;
 color: var(--heading);
 line-height: initial;
 margin-bottom: 0;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-feature-section-six {
 background: var(--light-cyan);
 padding: 165px 0 140px;
 position: relative;
 z-index: 1;
}

.vcamp-feature-section-six .shape-one {
 right: 0;
 top: -9%;
 animation: fade-in 1.5s infinite linear;
}

.vcamp-feature-section-six .shape-two {
 left: 19%;
 bottom: -9%;
 animation: fade-in 1.5s infinite linear;
}

.vcamp-feature-section-six .text-wrapper p {
 padding-top: 42px;
 margin-bottom: 0;
}

.card-style-six {
 background: #fff;
 padding: 32px 38px 40px;
 margin-bottom: 35px;
}

.card-style-six .icon {
 height: 54px;
}

.card-style-six h4 {
 font-size: 24px;
 padding: 18px 0 20px;
 margin-bottom: 0;
}

.card-style-six p {
 color: #4f4f4f;
 margin-bottom: 0;
}

/*^^^^^^^^^^^^^^^^^^^^^ Case Study Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.case-study-three {
 overflow: hidden;
}

.control-nav-one li {
 display: inline-block;
 line-height: 48px;
 border-radius: 8px;
 border: 1px solid #e7e7e7;
 padding: 0 35px;
 margin: 0 0.8%;
 transition: all 0.3s ease-in-out;
 cursor: pointer;
}

.control-nav-one li.react-tabs__tab--selected {
 background: #000;
 border-color: #000;
 color: #fff;
}

.case-block-three {
 overflow: hidden;
 margin-top: 50px;
}

.case-block-three .screen {
 width: 100%;
}

.case-block-three .overlay-content {
 position: absolute;
 height: 285px;
 width: 100%;
 left: 0;
 right: 0;
 bottom: 0;
 padding: 0 30px 35px;
 opacity: 0;
 transform: translateY(100px);
 transition: all 0.4s ease-in-out;
 background: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.0001) 0%,
  rgba(255, 255, 255, 0.634119) 57.49%,
  #ffffff 100%
 );
}

.case-block-three:hover .overlay-content {
 opacity: 1;
 transform: translateY(0);
}

.case-block-three .overlay-content a {
 font-family: "gorditamedium";
 font-size: 22px;
 color: var(--heading);
 width: 100%;
}

.case-study-three .mix,
.case-study-three .gap {
 float: left;
 width: 50%;
}

.case-study-three .mixitUp-container {
 margin: 0 -22px;
}

.case-study-three .mix {
 padding: 0 22px;
}

.case-study-three.full-width .mix,
.case-study-three.full-width .gap {
 width: 25%;
}

/*^^^^^^^^^^^^^^^^^^^^^ Case Study Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.case-study-four {
 overflow: hidden;
}

.control-nav-two button,
.control-nav-two li {
 display: inline-block;
 line-height: 50px;
 border-radius: 8px;
 padding: 0 33px;
 margin: 0 0.8%;
 cursor: pointer;
 transition: all 0.3s ease-in-out;
}

.control-nav-two button.mixitup-control-active,
.control-nav-two li.react-tabs__tab--selected {
 background: #000;
 color: #fff;
}

.control-nav-two li {
 &:focus {
  outline: none;
  border: none;
 }
}

.dark-bg .control-nav-two li {
 color: #fff;
}

.dark-bg .control-nav-two li.react-tabs__tab--selected {
 background: var(--red-one);
}

.case-study-four .mix,
.case-study-four .gap {
 width: 100%;
}

.case-block-four {
 padding: 140px 0 125px;
 margin-top: 90px;
 z-index: 1;
}

.case-block-four .text-wrapper .icon {
 width: 68px;
 height: 68px;
 background: var(--heading);
 border-radius: 50%;
}

.case-block-four .text-wrapper blockquote {
 font-size: 36px;
 line-height: 1.8em;
 color: var(--heading);
 padding-bottom: 70px;
 margin-bottom: 0;
}

.case-block-four .text-wrapper blockquote span {
 color: var(--red-one);
 position: relative;
 display: inline-block;
}

.case-block-four .text-wrapper blockquote span:before {
 content: "";
 position: absolute;
 width: 98%;
 height: 2px;
 background: var(--red-one);
 left: 2px;
 bottom: 2px;
}

.case-block-four .text-wrapper .info-meta {
 width: 87%;
}

.case-block-four .text-wrapper .avatar {
 width: 40px;
 height: 40px;
 border-radius: 50%;
 margin-right: 20px;
}

.case-block-four .text-wrapper .name {
 font-size: 20px;
 font-family: "gorditamedium";
}

.case-block-four .text-wrapper .name span {
 font-family: "gorditaregular";
 font-size: 15px;
}

.case-block-four .text-wrapper .more {
 width: 39px;
 line-height: 29px;
 border-radius: 15px;
 color: #fff;
 font-size: 17px;
 text-align: center;
 background: var(--heading);
 margin-left: auto;
 transition: all 0.3s ease-in-out;
}

.case-block-four .text-wrapper:hover .more {
 background: var(--red-one);
}

.case-block-four .screen {
 position: absolute;
 bottom: 0;
 max-width: 45%;
}

.case-block-four .screen.screen-right {
 right: 0;
}

.case-block-four .screen.screen-left {
 left: 0;
}

/*^^^^^^^^^^^^^^^^^^^^^ Case Study Section Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.case-study-five {
 padding: 140px 0 400px;
 position: relative;
 z-index: 1;
}

.case-study-five .shape-one {
 top: -127px;
 right: 2%;
 animation: fade-in 1.5s infinite linear;
}

.control-nav-three li {
 display: inline-block;
 font-size: 19px;
 line-height: 30px;
 color: #000;
 margin: 0 3%;
 transition: all 0.3s ease-in-out;
 cursor: pointer;
}

.dark-bg .control-nav-three li,
.dark-bg .control-nav-one li {
 color: #fff;
}

.dark-bg .control-nav-one li {
 border-color: #444;

 &.react-tabs__tab--selected {
  background: var(--red-one);
  border-color: var(--red-one);
 }
}

.control-nav-three li.react-tabs__tab--selected {
 color: var(--red-one);
}

.case-study-five .mix,
.case-study-five .gap {
 float: left;
 width: 50%;
}

.case-study-five .mixitUp-container {
 margin: 0 -22px;
}

.case-study-five .mix {
 padding: 0 22px;
}

.case-study-five.full-width .mix,
.case-study-five.full-width .gap {
 float: left;
 width: 33.333%;
}

/*^^^^^^^^^^^^^^^^^^^^^ Inside Page Banner Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.inside-hero-four.bg-color {
 background: #f6eae0;
}

.inside-hero-four {
 padding: 130px 0 0;
 position: relative;
 z-index: 1;
}

.inside-hero-four h6 {
 font-size: 16px;
 text-transform: uppercase;
 letter-spacing: 2px;
 color: rgba(0, 0, 0, 0.35);
 margin-bottom: 0;
}

.inside-hero-four .page-title {
 font-size: 72px;
 line-height: 1.25em;
 padding: 9px 0 25px;
 margin-bottom: 0;
}

.inside-hero-four .page-title span {
 position: relative;
 display: inline-block;
 color: var(--red-one);
}

.inside-hero-four .page-title span:before {
 content: "";
 width: 100%;
 height: 4px;
 position: absolute;
 bottom: 10px;
 left: 0;
 background: var(--red-one);
}

.inside-hero-four p {
 font-size: 24px;
 line-height: 1.83em;
 margin-bottom: 0;
}

.inside-hero-four .screen {
 margin: 75px auto 0;
 border-radius: 40px 40px 0 0;
}

.inside-hero-four .shape-one {
 top: 21%;
 right: 4%;
 animation: jumpTwo 5s infinite linear;
}

.inside-hero-four .shape-two {
 left: 9%;
 bottom: 18%;
 animation: jumpThree 5s infinite linear;
}

/*--- Dark Style ---*/
.dark-bg .inside-hero-four h6,
.dark-bg .inside-hero-four p {
 color: rgba(255, 255, 255, 0.8);
}

.dark-bg .inside-hero-four .page-title {
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Case Study Details ^^^^^^^^^^^^^^^^^^^^^^^^*/
.case-details-one {
 padding: 175px 0 130px;
 position: relative;
 z-index: 1;
}

.case-details-one .shape-one {
 top: -127px;
 right: 0;
 animation: fade-in 1.5s infinite linear;
}

.case-details-one .sub-heading {
 font-size: 16px;
 text-transform: uppercase;
 letter-spacing: 2px;
 color: rgba(0, 0, 0, 0.5);
 margin-bottom: 42px;
}

.case-details-one .title {
 font-size: 62px;
 line-height: 1.24em;
 font-weight: 500;
 padding-bottom: 38px;
 margin-bottom: 0;
}

.case-details-one p {
 font-size: 19px;
 line-height: 2em;
 padding-bottom: 24px;
}

.case-details-one .border-block {
 border-top: 1px solid #d8d8d8;
 border-bottom: 1px solid #d8d8d8;
 padding: 120px 0 90px;
 margin: 80px 0 100px;
}

.case-details-one .img-meta {
 margin: 35px 0 80px;
}

.case-details-one.dark-bg .sub-heading {
 display: inline-block;
 font-size: 14px;
 color: #fff;
 line-height: 20px;
 padding: 0 2px;
 background: var(--red-one);
}

.case-details-one.dark-bg .title {
 color: #fff;
}

.case-details-one.dark-bg p {
 color: rgba(255, 255, 255, 0.8);
}

.case-details-one.dark-bg .border-block {
 border-top: 1px solid rgba(255, 255, 255, 0.1);
 border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Fancy Banner Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-banner-five .bg-wrapper {
 background: var(--light-blue);
 text-align: center;
 padding: 65px 0 85px;
 border-radius: 20px;
 position: relative;
 z-index: 5;
}

.fancy-banner-five.reverse-space .bg-wrapper {
 margin-top: -220px;
}

.fancy-banner-five .bg-wrapper.dark-bg {
 background: #333333;
}

.fancy-banner-five .bg-wrapper.dark-bg .title {
 color: #fff;
}

.fancy-banner-five .title {
 font-size: 69px;
 line-height: 1.21em;
 padding-bottom: 37px;
}

.fancy-banner-five.dark-bg .title {
 color: #fff;
}

.fancy-banner-five [class*="theme-btn-"] {
 width: 200px;
 border-radius: 9px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Pricing Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.pricing-nav-one {
 margin: 70px 0 100px;
 border: none;
}

.pricing-nav-one .nav-item .nav-link {
 font-family: "gorditamedium";
 font-size: 18px;
 line-height: 61px;
 border: 2px solid #000;
 padding: 0 15px;
 width: 150px;
 text-align: center;
 color: #000;
 transition: all 0.3s ease-in-out;
}

.pricing-nav-one .nav-item:nth-child(1) .nav-link {
 border-right: none;
 border-radius: 10px 0 0 10px;
}

.pricing-nav-one .nav-item:nth-child(2) .nav-link {
 border-left: none;
 border-radius: 0 10px 10px 0;
}

.pricing-nav-one .nav-item .nav-link.active {
 background: #000;
 color: #fff;
}

.pricing-table-area-one .pr-table-wrapper {
 border: 1px solid #ececec;
 border-radius: 15px;
 transition: all 0.3s ease-in-out;
 padding: 25px 25px 40px;
 margin-top: 40px;
 position: relative;
 z-index: 1;
 text-align: center;
}

.pricing-table-area-one .pr-table-wrapper.most-popular:after {
 content: url(../images/shape/popular-tag.svg);
 position: absolute;
 top: -97px;
 right: -87px;
}

.pricing-table-area-one .pr-table-wrapper .pack-name {
 font-family: "avenirblack";
 font-size: 28px;
 color: #8163ff;
}

.pricing-table-area-one .pr-table-wrapper .pack-details {
 color: #878787;
 padding: 5px 0 45px;
 font-weight: 800;
}

.pricing-table-area-one .pr-table-wrapper .top-banner {
 padding: 9px 5px 15px 22px;
 color: #000;
 text-align: center;
}

.pricing-table-area-one .pr-table-wrapper .price {
 font-size: 40px;
 font-family: "avenirblack";
 padding: 25px 20px 0 0;
 font-weight: 800;
}

.pricing-table-area-one .pr-table-wrapper .price sup {
 font-size: 20px;
 // top: -22px;
}

.pricing-table-area-one .pr-table-wrapper .top-banner span {
 font-size: 22px;
}

.pricing-table-area-one .pr-table-wrapper .top-banner em {
 font-size: 14px;
 font-style: normal;
 display: block;
 margin-top: -7px;
}

.pricing-table-area-one .pr-table-wrapper .pr-feature {
 text-align: left;
 padding: 38px 0 48px 20px;
 list-style: none;
}

.pricing-table-area-one .pr-table-wrapper .pr-feature li {
 position: relative;
 line-height: 42px;
 color: rgba(0, 0, 0, 0.7);
 padding-left: 30px;
}

.pricing-table-area-one .pr-table-wrapper .pr-feature li:before {
 content: url(../images/icon/icon_51.svg);
 position: absolute;
 top: 0;
 left: 0;
}

.pricing-table-area-one .pr-table-wrapper .trial-button {
 display: block;
 font-size: 16px;
 font-family: "gorditamedium";
 color: #000;
 line-height: 52px;
 border: 2px solid #000000;
 border-radius: 5px;
 transition: all 0.3s ease-in-out;
}

.pricing-table-area-one .pr-table-wrapper .trial-button2 {
 display: block;
 font-size: 16px;
 font-family: "avenirblack";
 color: #fff;
 line-height: 52px;
 // border: 2px solid #000000;
 border-radius: 25px;
 transition: all 0.3s ease-in-out;
 background-color: #8163ff;
 font-weight: 800;
}

.pricing-table-area-one .pr-table-wrapper .trial-text {
 font-size: 16px;
 padding-top: 20px;
}

.pricing-table-area-one .pr-table-wrapper:hover {
 box-shadow: 0 15px 60px rgba(0, 0, 0, 0.05);
}

.pricing-table-area-one .pr-table-wrapper .trial-button:hover,
.pricing-table-area-one .pr-table-wrapper.active .trial-button {
 background: #000;
 color: #fff;
}

/*--- Dark Version ---*/
.pricing-section-one.dark-bg {
 padding: 160px 0 350px;
 position: relative;
 z-index: 1;
}

.pricing-section-one.dark-bg .shape-one {
 top: -110px;
 right: 0;
 animation: fade-in 1.5s infinite linear;
}

.pricing-section-one.dark-bg .nav-item .nav-link {
 border-color: var(--red-one);
 color: #fff;
}

.pricing-section-one.dark-bg .nav-item .nav-link.active {
 background: var(--red-one);
}

.pricing-section-one.dark-bg .pr-table-wrapper:not(.active) .pack-name,
.pricing-section-one.dark-bg .pr-table-wrapper:not(.active) .trial-button,
.pricing-section-one.dark-bg .pr-table-wrapper:not(.active) .top-banner {
 color: #fff;
}

.pricing-section-one.dark-bg .pr-table-wrapper:not(.active) .pack-details,
.pricing-section-one.dark-bg .pr-table-wrapper:not(.active) .pr-feature li,
.pricing-section-one.dark-bg .pr-table-wrapper:not(.active) .trial-text {
 color: rgba(255, 255, 255, 0.5);
}

.pricing-section-one.dark-bg .pr-table-wrapper:not(.active) .top-banner {
 background: rgba(255, 255, 255, 0.15) !important;
}

.pricing-section-one.dark-bg .pr-table-wrapper:not(.active) .trial-button {
 border-color: #fff;
}

.pricing-section-one.dark-bg
 .pr-table-wrapper:not(.active)
 .pr-feature
 li:before {
 content: url(../images/icon/icon_54.svg);
}

.pricing-section-one.dark-bg .pr-table-wrapper.most-popular:after {
 content: url(../images/shape/popular-tag-w.svg);
}

.pricing-section-one.dark-bg .pr-table-wrapper.active {
 background: #fff;
}

.pricing-section-one.dark-bg .pr-table-wrapper.active .top-banner {
 background: #ffefef;
}

.pricing-section-one.dark-bg .pr-table-wrapper .trial-button:hover {
 background: var(--red-one);
 border-color: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Fancy Banner Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-banner-six .inner-content {
 background: var(--deep-blue);
 padding: 55px 60px;
 border-radius: 10px;
 z-index: 5;
}

.fancy-banner-six.reverse-space .inner-content {
 margin-top: -130px;
}

.fancy-banner-six.reverse-space .shape-one {
 top: -80px;
 left: -88px;
 z-index: 1;
 animation: fade-in 1.5s infinite linear;
}

.fancy-banner-six .inner-content .icon {
 width: 110px;
 height: 110px;
 background: #fff;
 border-radius: 50%;
}

.fancy-banner-six .inner-content .text {
 max-width: calc(100% - 110px);
}

.fancy-banner-six .inner-content h3 {
 font-size: 36px;
}

.fancy-banner-six .inner-content h3 span {
 color: var(--red-one);
}

.fancy-banner-six .theme-btn-four {
 border-radius: 8px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.accordion-style-two .accordion-tag {
 font-family: "gorditamedium";
 font-size: 15px;
 letter-spacing: 1.5px;
 text-transform: uppercase;
 color: #a5a5a5;
 margin-bottom: 35px;
}

.accordion-style-two .accordion-item {
 border: none;
 border-top: 1px solid rgba(0, 0, 0, 0.1);
 border-radius: 0;
}

.accordion-style-two .accordion-item:last-child {
 border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion-style-two .accordion-button {
 font-family: "gorditamedium";
 font-size: 20px;
 min-height: 1.75em;
 padding: 34px 10px 34px 0;
 box-shadow: none;
 border-radius: 0;
 color: var(--heading);
 background: transparent;
 transition: all 0.3s ease-in-out;
}

.accordion-style-two .accordion-button:not(.collapsed) {
 padding-bottom: 15px;
}

.accordion-style-two .accordion-body {
 padding: 0 50px 25px 0;
}

.accordion-style-two .accordion-body p {
 font-size: 20px;
 line-height: 1.8em;
 margin-bottom: 0;
}

.accordion-style-two .accordion-button::after {
 content: url(../images/icon/icon_53.svg);
 background-image: none;
 width: auto;
 height: auto;
}

.accordion-style-two .accordion-button:not(.collapsed)::after {
 transform: rotate(180deg);
}

/*^^^^^^^^^^^^^^^^^^^^^ Inside Page Banner Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.inside-hero-five {
 padding: 250px 0 150px;
}

.inside-hero-five .illustration {
 animation: jumpTwo 5s infinite linear;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-feature-section-seven {
 padding: 120px 0 150px;
 position: relative;
 z-index: 5;
 background: var(--deep-cyan);
}

.vcamp-feature-section-seven.style-two {
 background: var(--light-blue);
}

.vcamp-feature-section-seven .shape-one {
 top: -50px;
 left: 10%;
 animation: jumpThree 5s infinite linear;
}

.vcamp-feature-section-seven .shape-two {
 bottom: -60px;
 right: 10%;
 animation: jumpTwo 5s infinite linear;
}

.vcamp-feature-section-seven .shape-three {
 top: -100px;
 left: 0;
 animation: fade-in 1.1s infinite linear;
}

.card-style-seven {
 background: #fff;
 border-radius: 8px;
 padding: 40px 70px 38px 50px;
 width: 100%;
 height: 100%;
 transition: all 0.3s ease-in-out;
}

.card-style-seven:hover {
 transform: translateY(-5px);
}

.card-style-seven .icon {
 width: 60px;
 height: 60px;
 border-radius: 10px;
 background: var(--red-one);
 margin-bottom: 25px;
}

.card-style-seven h4 {
 font-size: 24px;
 margin-bottom: 24px;
}

.card-style-seven p {
 line-height: 34px;
}

.card-style-seven p span {
 font-family: "gorditamedium";
 text-decoration: underline;
 display: inline-block;
}

/*--- Dark Version ---*/
.dark-bg .card-style-seven {
 background: transparent;
 padding: 0;
 transform: none;
}

.dark-bg .card-style-seven h4 {
 color: #fff;
}

.dark-bg .card-style-seven p {
 color: rgba(255, 255, 255, 0.65);
 padding-right: 50px;
}

.dark-bg .card-style-seven p span {
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Fancy Banner Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-banner-seven .inner-content {
 padding: 75px 60px;
 background: var(--light-blue);
}

.fancy-banner-seven h3 {
 font-size: 36px;
 font-weight: 500;
 margin-bottom: 20px;
}

.fancy-banner-seven p {
 font-size: 19px;
 margin-bottom: 0;
}

.fancy-banner-seven p a {
 font-family: "gorditamedium";
 text-decoration: underline;
 color: #000;
 transition: all 0.3s ease-in-out;
}

.fancy-banner-seven p a:hover {
 color: var(--red-one);
}

.fancy-banner-seven .theme-btn-four {
 border-radius: 8px;
 padding: 0 35px;
}

.dark-bg.fancy-banner-seven .inner-content {
 background: transparent;
 border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-bg.fancy-banner-seven h3,
.dark-bg.fancy-banner-seven p a {
 color: #fff;
}

.dark-bg.fancy-banner-seven p {
 color: rgba(255, 255, 255, 0.7);
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/
.card-style-eight {
 padding: 65px 45px 50px;
 border: 1px solid #e9e9e9;
 border-radius: 8px;
 margin-top: 40px;
 transition: all 0.3s ease-in-out;
}

.card-style-eight:hover {
 background: #fff;
 border-color: #fff;
 box-shadow: 0 15px 50px rgba(0, 0, 0, 0.04);
 transform: translateY(-5px);
}

.card-style-eight .icon {
 height: 26px;
 margin-bottom: 25px;
}

.card-style-eight h4 {
 font-size: 24px;
 margin-bottom: 20px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Team Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.team-section-two .mix {
 width: calc(100% / 3 - (((3 - 1) * 1rem) / 3));
 display: inline-block;
 margin-top: 70px;
 padding: 0 25px;
}

.team-section-two .mixitUp-container {
 margin: 0 -25px;
}

.team-block-two .img-meta {
 overflow: hidden;
 border-radius: 15px;
 margin-bottom: 30px;
}

.team-block-two .img-meta img {
 border-radius: 15px;
 transition: all 0.5s ease-in-out;
}

.team-block-two .img-meta:hover img {
 transform: scale3d(1.1, 1.1, 1.1);
}

.team-block-two .name {
 font-size: 24px;
}

.team-block-two p {
 color: #a7a7a7;
 margin-bottom: 0;
}

.team_slider_two .item {
 padding: 0 18px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Ten ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-ten {
 position: relative;
 padding: 200px 0 160px;
 z-index: 2;
}

.feedback-section-ten.dark-bg {
 padding-bottom: 340px;
 z-index: 1;
}

.feedback-section-ten.light-bg {
 background: var(--deep-cyan);
}

.feedback-section-ten .inner-container {
 max-width: 1500px;
 margin: 0 auto;
 padding: 0 12px;
}

.feedback-section-ten .shape-one {
 top: -75px;
 right: 10%;
 animation: jumpThree 5s infinite linear;
}

.feedback-section-ten .shape-two {
 bottom: -70px;
 left: 8%;
 animation: jumpTwo 5s infinite linear;
}

.feedback-section-ten .shape-three {
 bottom: -180px;
 right: -70px;
 animation: fade-in 1.1s infinite linear;
}

.feedback-section-ten .shape-four {
 top: -100px;
 right: 0;
 animation: fade-in 1.1s infinite linear;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Eleven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.testimonial-block-two {
 padding: 17px 55px 35px;
 border: 1px solid #e8e8e8;
 border-radius: 10px;
 margin-bottom: 40px;
 transition: all 0.3s ease-in-out;
}

.testimonial-block-two:hover {
 border-color: #fff;
 box-shadow: 0 10px 60px rgba(0, 0, 0, 0.04);
}

.testimonial-block-two .logo {
 height: 72px;
}

.testimonial-block-two p {
 font-size: 24px;
 line-height: 1.7em;
 margin: 23px 0 52px;
}

.testimonial-block-two .name {
 font-family: "gorditamedium";
 font-size: 20px;
 color: var(--heading);
 line-height: 28px;
}

.testimonial-block-two .name span {
 font-family: "gorditaregular";
 color: #c1c1c1;
 display: block;
 font-size: 16px;
}

.testimonial-block-two .icon {
 width: 37px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.blog-section-four.dark-bg {
 .post-footer,
 .read-more {
  img {
   filter: invert(1);
  }
 }
}

.blog-section-four .blog-meta-wrapper {
 column-count: 2;
 grid-column-gap: 50px;
}

.blog-meta-four {
 margin-bottom: 80px;
 display: inline-block;
}

.blog-meta-four .img-meta img {
 border-radius: 9px;
}

.blog-meta-four .tag {
 font-family: "gorditamedium";
 display: inline-block;
 line-height: 27px;
 border-radius: 20px;
 letter-spacing: 1px;
 background: var(--red-one);
 color: #fff;
 font-size: 11px;
 text-transform: uppercase;
 padding: 0 15px;
 position: absolute;
 right: 10px;
 bottom: 20px;
}

.blog-meta-four .post-info {
 font-family: "gorditamedium";
 font-size: 16px;
 color: var(--heading);
 margin-top: 23px;
}

.blog-meta-four .post-info .date {
 font-family: "gorditaregular";
 color: #aaaaaa;
}

.blog-meta-four .title {
 font-family: "gorditamedium";
 font-size: 28px;
 line-height: 1.35em;
 color: var(--heading);
 display: block;
 margin: 11px 0 38px;
}

.blog-meta-four:hover .title {
 text-decoration: underline;
}

.blog-meta-four .post-footer .read-more:hover {
 transform: translateX(-5px);
}

/*--- Blog Sidebar ---*/
.blog-sidebar .blog-sidebar-search form {
 height: 65px;
 position: relative;
}

.blog-sidebar .blog-sidebar-search input {
 width: 100%;
 height: 100%;
 border: none;
 font-size: 17px;
 background: #f4f4f4;
 border-radius: 5px;
 padding: 0 60px 0 20px;
}

.blog-sidebar .blog-sidebar-search button {
 position: absolute;
 right: 0;
 top: 0;
 bottom: 0;
 width: 60px;
 color: #000;
}

.blog-sidebar .sidebar-title {
 font-size: 24px;
 margin-bottom: 30px;
}

.blog-sidebar .blog-sidebar-category ul li a {
 font-size: 16px;
 line-height: 36px;
 display: block;
 color: #000;
 transition: all 0.3s ease-in-out;
}

.blog-sidebar .blog-sidebar-category ul li a:hover {
 text-decoration: underline;
 color: var(--red-one);
}

.blog-sidebar .sidebar-banner-add {
 background-size: cover;
 border-radius: 10px;
 position: relative;
 padding: 20px;
}

.blog-sidebar .sidebar-banner-add .banner-content {
 border-radius: 10px;
 background: rgba(255, 255, 255, 0.9);
 padding: 40px 50px;
 text-align: center;
}

.blog-sidebar .sidebar-banner-add .banner-content h4 {
 line-height: 31px;
}

.blog-sidebar .sidebar-banner-add .banner-content p {
 font-size: 15px;
 margin: 20px 0 15px;
}

.blog-sidebar .sidebar-banner-add .banner-content a {
 font-family: "gorditamedium";
 font-size: 14px;
 color: #fff;
 width: 140px;
 line-height: 40px;
 border-radius: 20px;
 text-align: center;
 background: #212121;
}

/*----Dark Version ---*/
.blog-section-four.dark-bg {
 padding: 120px 0 170px;
}

.dark-bg .blog-meta-four .post-info,
.dark-bg .blog-meta-four .title,
.dark-bg .blog-sidebar .blog-sidebar-search button,
.dark-bg .blog-sidebar .sidebar-title {
 color: #fff;
}

.dark-bg .blog-sidebar .blog-sidebar-search input {
 background: rgba(255, 255, 255, 0.2);
 color: #fff;
}

.dark-bg .blog-sidebar .blog-sidebar-category ul li a {
 color: rgba(255, 255, 255, 0.65);
}

.dark-bg .blog-sidebar .blog-sidebar-category ul li a:hover {
 color: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.blog-meta-five {
 margin-bottom: 70px;
}

.blog-meta-five.text-style {
 padding: 35px 50px 40px;
 border: 2px solid #000;
 border-radius: 9px;
}

.blog-meta-five .img-meta img {
 border-radius: 9px;
}

.blog-meta-five .tag {
 font-family: "gorditamedium";
 display: inline-block;
 line-height: 27px;
 border-radius: 15px;
 letter-spacing: 1px;
 background: var(--red-one);
 color: #fff;
 font-size: 11px;
 text-transform: uppercase;
 padding: 0 15px;
}

.blog-meta-five .img-meta .tag {
 position: absolute;
 left: 30px;
 bottom: 20px;
}

.blog-meta-five .post-info {
 font-family: "gorditamedium";
 font-size: 16px;
 color: var(--heading);
 margin-top: 32px;
}

.blog-meta-five.text-style .post-info {
 margin-top: 23px;
}

.blog-meta-five .post-info .date {
 font-family: "gorditaregular";
 color: #aaaaaa;
}

.blog-meta-five .title {
 font-family: "gorditamedium";
 font-size: 32px;
 line-height: 1.3em;
 width: 71%;
 color: var(--heading);
 display: block;
 margin: 23px 0 28px;
}

.blog-meta-five:hover .title {
 text-decoration: underline;
}

.blog-meta-five.text-style .title {
 width: 95%;
}

/*--- dark Version ---*/
.blog-section-five.dark-bg {
 padding: 150px 0 150px;
}

.dark-bg .blog-meta-five {
 margin-bottom: 100px;
}

.dark-bg .blog-meta-five .img-meta img {
 border-radius: 0;
}

.dark-bg .blog-meta-five .title {
 color: #fff;
}

.dark-bg .blog-meta-five .tag {
 border-radius: 0;
 margin-top: 60px;
}

.dark-bg .blog-meta-five .theme-btn-three {
 color: rgba(255, 255, 255, 0.7);
}

.dark-bg .blog-meta-five .theme-btn-three:hover {
 color: var(--red-one);
}

.dark-bg .blog-meta-five.text-style {
 padding: 0 50px 50px;
 border: none;
 background: rgba(255, 255, 255, 0.07);
 border-radius: 9px;
}

.dark-bg .blog-meta-five.text-style .title {
 width: 72%;
}

/*^^^^^^^^^^^^^^^^^^^^^ Inside Page Banner Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.inside-hero-six .bg-container {
 background: #f9f2ec;
 padding: 92px 0;
}

.blog-pubish-date {
 font-size: 16px;
}

.blog-pubish-date a {
 font-family: "gorditamedium";
 color: #000;
}

.blog-pubish-date a:hover {
 text-decoration: underline;
}

.blog-heading-one {
 font-family: "gorditamedium";
 font-size: 52px;
 line-height: 1.44em;
 margin-bottom: 0;
}

.blog-breadcrumb .breadcrumb-item a,
.blog-breadcrumb .breadcrumb-item {
 font-size: 16px;
 color: #000;
}

.blog-breadcrumb .breadcrumb-item.active {
 font-family: "gorditamedium";
}

.blog-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
 content: "\f285";
 font-family: bootstrap-icons !important;
 font-size: 14px;
}

.blog-breadcrumb .breadcrumb-item a:hover {
 text-decoration: underline;
}

/*^^^^^^^^^^^^^^^^^^^^^Blog Details One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.blog-details-one .blog-details-content {
 border-bottom: 1px solid #e7e7e7;
 padding-bottom: 10px;
}

.blog-details-one .blog-details-content .image-meta {
 margin-bottom: 54px;
}

.blog-details-one .blog-details-content p {
 font-size: 17px;
 line-height: 32px;
 margin-bottom: 32px;
}

.blog-details-one .blog-details-content h4 {
 color: #303030;
 line-height: 35px;
 margin-bottom: 22px;
 letter-spacing: -0.5px;
}

.blog-details-one .blog-details-content h2 {
 font-family: "gorditamedium";
 font-size: 36px;
 padding-top: 10px;
 letter-spacing: -1px;
 margin-bottom: 20px;
}

.blog-details-one .blog-details-content .tags li:first-child {
 font-family: "gorditamedium";
 color: #000;
 padding-right: 5px;
}

.blog-details-one .blog-details-content .tags li {
 font-size: 16px;
 color: rgba(0, 0, 0, 0.5);
}

.blog-details-one .blog-details-content .tags li a:hover {
 color: #000;
 text-decoration: underline;
}

.blog-details-one .blog-details-content .share-icon li:first-child {
 font-family: "gorditamedium";
 color: #000;
 font-size: 16px;
}

.blog-details-one .blog-details-content .share-icon li a {
 width: 38px;
 height: 38px;
 line-height: 38px;
 border-radius: 50%;
 background: #eeeded;
 text-align: center;
 color: #000;
 font-size: 16px;
 margin-left: 10px;
 transition: all 0.3s ease-in-out;
}

.blog-details-one .blog-details-content .share-icon li a:hover {
 background: var(--red-one);
 color: #fff;
}

.blog-details-one .blog-details-content .bottom-widget {
 padding-top: 25px;
}

.blog-details-one .blog-inner-title {
 font-family: "gorditamedium";
 font-size: 42px;
 letter-spacing: -1px;
}

.blog-comment-area {
 margin: 100px 0 100px;
}

.blog-comment-area .comment .user-avatar {
 width: 60px;
 height: 60px;
 border-radius: 50%;
}

.blog-comment-area .comment .comment-text {
 width: calc(100% - 60px);
 padding-left: 25px;
 position: relative;
}

.blog-comment-area .comment .name {
 font-family: "gorditamedium";
 font-size: 20px;
 letter-spacing: -1px;
}

.blog-comment-area .comment .date {
 font-size: 15px;
 color: #adadad;
}

.blog-comment-area .comment p {
 font-size: 16px;
 line-height: 28px;
 padding: 12px 50px 0 0;
}

.blog-comment-area .comment .reply-btn {
 font-family: "gorditamedium";
 font-size: 13px;
 color: #fff;
 text-transform: uppercase;
 letter-spacing: 0px;
 text-align: center;
 width: 65px;
 line-height: 27px;
 background: #2c2c2c;
 border-radius: 3px;
 position: absolute;
 right: 0;
 top: 0;
 transition: all 0.3s ease-in-out;
}

.blog-comment-area .comment .reply-btn:hover {
 background: var(--red-one);
}

.blog-comment-area .comment {
 margin-top: 45px;
}

.blog-comment-form form label {
 font-size: 14px;
 font-weight: normal;
 color: rgba(0, 0, 0, 0.5);
 display: block;
 padding-bottom: 3px;
}

.blog-comment-form form input {
 display: block;
 font-size: 17px;
 width: 100%;
 height: 60px;
 border: 2px solid #000;
 border-radius: 8px;
 padding: 0 25px;
 background: transparent;
}

.blog-comment-form form textarea {
 display: block;
 font-size: 17px;
 width: 100%;
 max-width: 100%;
 height: 190px;
 border: 2px solid #000;
 border-radius: 8px;
 padding: 20px 25px;
 background: transparent;
}

.blog-comment-form.bg-color form textarea {
 height: 140px;
}

.blog-comment-form.bg-color {
 background: var(--light-blue);
 padding: 65px 60px 70px;
}

/*---- Dark Version ---*/
.blog-details-one.dark-bg {
 padding: 105px 0 150px;
}

.blog-details-one.dark-bg .blog-details-content p,
.dark-bg .blog-comment-area .comment p,
.dark-bg .blog-comment-form p {
 color: rgba(255, 255, 255, 0.8);
}

.blog-details-one.dark-bg .blog-details-content h4,
.blog-details-one.dark-bg .blog-details-content h2,
.blog-details-one.dark-bg .blog-details-content .tags li:first-child,
.blog-details-one.dark-bg .blog-details-content .share-icon li:first-child,
.blog-details-one.dark-bg .blog-inner-title,
.dark-bg .blog-comment-area .comment .name {
 color: #fff;
}

.blog-details-one.dark-bg .blog-details-content .tags li,
.dark-bg .blog-comment-form form label {
 color: rgba(255, 255, 255, 0.55);
}

.blog-details-one.dark-bg .blog-details-content .tags li a:hover {
 color: var(--red-one);
}

.blog-details-one.dark-bg .blog-details-content .share-icon li a {
 background: rgba(255, 255, 255, 0.15);
 color: #fff;
}

.blog-details-one.dark-bg .blog-details-content .share-icon li a:hover {
 background: var(--red-one);
}

.blog-details-one.dark-bg .blog-details-content {
 border-bottom: 1px solid rgba(255, 255, 255, 0.09);
}

.dark-bg .blog-comment-form form input,
.dark-bg .blog-comment-form form textarea {
 border: none;
 background: rgba(255, 255, 255, 0.18);
 color: #fff;
}

.dark-bg .blog-comment-form form .theme-btn-four {
 border-radius: 5px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Inside Page Banner Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.inside-hero-seven .bg-wrapper {
 background-size: cover;
 background-repeat: no-repeat;
 padding: 215px 0 140px;
}

.blog-heading-two {
 font-family: "gorditamedium";
 font-size: 61px;
 line-height: 1.22em;
 margin-bottom: 34px;
}

.inside-hero-seven .blog-heading-two,
.inside-hero-seven .blog-pubish-date,
.inside-hero-seven .blog-pubish-date a {
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Inside Page Banner Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/
.inside-hero-eight {
 padding: 190px 0 30px;
}

.inside-hero-eight .breadcrumb-item + .breadcrumb-item {
 padding: 0 8px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Contact Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.map-area-one .mapouter {
 height: 600px;
}

.area-contact-message {
 background-color: #00fbc8;
 height: 100%;
 width: 100%;
}

.area-contact-message .text-message {
 font-size: 62px;
 font-weight: 500;
 padding: 15px;
 font-weight: bold;
 font-family: "avenirblack";
}

.mapouter .gmap_iframe,
.map-area-two .mapouter,
.mapouter .gmap_canvas {
 height: 100%;
 width: 100%;
 background-color: #00fbc8;
}

/*^^^^^^^^^^^^^^^^^^^^^ Contact Section Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.contact-section-five .form-wrapper {
 padding: 60px 0 85px;
}

.form-style-three {
 max-width: 648px;
 padding-left: 100px;
}

.form-style-three .form-title {
 font-size: 62px;
 color: #fff;
}

.form-style-three label {
 font-size: 14px;
 color: rgba(0, 0, 0, 0.5);
 margin-bottom: 5px;
}

.form-style-three .input-group-meta input,
.form-style-three .input-group-meta textarea {
 display: block;
 width: 100% !important;
 max-width: 100%;
 border: 2px solid;
 border-radius: 8px;
 background: #f2f2f2;
 padding: 0 20px;
 font-size: 22px;
 // border-color: blue;
 font-family: "avenirblack";
}

.form-style-three .input-group-meta input {
 height: 60px;
}

.form-style-three .input-group-meta textarea {
 min-height: 165px;
 padding: 15px 20px;
}

.form-style-three [class*="theme-btn-"] {
 font-size: 20px;
 // text-transform: uppercase;
 // border-radius: 8px;
}

.form-style-three .form-select {
 display: block !important;
 font-size: 16px;
 line-height: 60px;
 height: 60px;
 background-color: #f2f2f2;
 color: rgba(0, 0, 0, 0.6);
 border-radius: 8px;
 padding: 0 0 0 20px;
 border: none;
 box-shadow: none;
}

.form-style-three.light-bg .form-select {
 border-color: #f2f2f2 !important;
}

.form-style-three .form-select .item {
 line-height: 60px;
 color: var(--heading);
 display: block;
}

.form-style-three .selectize-dropdown {
 border: none;
 border-radius: 8px;
 box-shadow: 0 13px 35px -12px rgb(35 35 35 / 10%);
 margin: -1px 0 0;
}

.form-style-three .selectize-dropdown .option {
 font-size: 16px;
 color: #4f4f4f;
 line-height: 35px;
}

.form-style-three .selectize-dropdown .option:hover,
.form-style-three .selectize-dropdown .active {
 color: #fff;
 background: var(--red-one);
}

/*--Dark Style ---*/
.dark-bg .form-style-three label {
 color: rgba(255, 255, 255, 0.5);
}

.dark-bg .form-style-three .input-group-meta input,
.dark-bg .form-style-three .input-group-meta textarea {
 background: rgba(255, 255, 255, 0.15);
 color: #fff;
}

.white-bg .form-style-three .input-group-meta input,
.white-bg .form-style-three .input-group-meta textarea {
 background: rgba(255, 255, 255, 0.9);
 color: #000;
}

.dark-bg .intl-tel-input .country-list {
 background: #353535;
 color: #fff;
}

.dark-bg .form-style-three {
 select {
  background: rgba(255, 255, 255, 0.15);

  color: #fff;

  &:focus {
   box-shadow: none;
  }
 }
}

.dark-bg .form-style-three option {
 background: #353535;
}

.dark-bg .form-style-three select .option,
.dark-bg .form-style-three select .item {
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Contact Section Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.address-block-three .icon {
 width: 35px;
 margin-top: 7px;
}

.address-block-three .icon img {
 margin-left: auto;
}

.address-block-three .text {
 width: calc(100% - 35px);
 padding-left: 30px;
}

.address-block-three .title {
 font-family: "gorditamedium";
 font-size: 28px;
 padding: 0 0 17px;
 margin-bottom: 0;
 letter-spacing: 0px;
}

.address-block-three p {
 font-size: 19px;
 line-height: 1.65em;
 color: #878787;
 margin-bottom: 0;
}

.address-block-three p .call {
 font-size: 22px;
 color: var(--red-one);
}

.address-block-three p .call:hover {
 text-decoration: underline;
}

.address-block-three p .webaddress {
 color: var(--heading);
 text-decoration: underline;
}

/*^^^^^^^^^^^^^^^^^^^^^ FAQ Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.faq-sidebar {
 position: sticky;
 top: 100px;
 height: 100vh;
}

.faq-nav-one .list-group {
 background: var(--deep-blue);
 border: none;
 border-radius: 0;
 padding: 25px 15px 35px 30px;
}

.faq-nav-one .list-group a {
 font-size: 22px;
 line-height: 55px;
 color: #000;
 padding: 0;
 border: none;
 background: transparent;
}

.faq-nav-one .list-group a.active {
 color: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ FAQ Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.faq-section-two {
 padding: 140px 0 140px;
}

.faq-section-two .nav-tabs {
 border: 0;
}

.faq-section-two .nav-tabs .nav-link {
 line-height: 40px;
 padding: 0 28px;
 font-size: 18px;
 color: rgba(255, 255, 255, 0.5);
 border: none;
 margin: 0 13px;
 letter-spacing: 0px;
 border-radius: 0;
}

.faq-section-two .nav-tabs .nav-link.active {
 background: var(--red-one);
 color: #fff;
}

.accordion-style-three .accordion-item {
 border: none;
 margin-bottom: 17px;
 background: rgba(255, 255, 255, 0.1);
 border-radius: 0;
}

.accordion-style-three .accordion-item:last-child {
 border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion-style-three .accordion-button {
 font-size: 20px;
 min-height: 1.75em;
 padding: 26px 20px 26px 35px;
 box-shadow: none;
 border-radius: 0;
 color: #fff;
 background: transparent;
 transition: all 0.3s ease-in-out;
}

.accordion-style-three .accordion-button:not(.collapsed) {
 padding-bottom: 19px;
}

.accordion-style-three .accordion-body {
 padding: 0 50px 38px 35px;
}

.accordion-style-three .accordion-body p {
 font-size: 18px;
 line-height: 1.8em;
 margin-bottom: 0;
 color: #d4d4d4;
}

.accordion-style-three .accordion-button::after {
 content: url(../images/icon/icon_66.svg);
 background-image: none;
 width: auto;
 height: auto;
}

.accordion-style-three .accordion-button:not(.collapsed)::after {
 transform: rotate(180deg);
}

.faq-section-two .request-faq h2 {
 font-size: 44px;
 margin-bottom: 25px;
 color: #fff;
}

.faq-section-two .request-faq .theme-btn-four {
 font-size: 13px;
 text-transform: uppercase;
}

/*^^^^^^^^^^^^^^^^^^^^^ FAQ Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.user-data-page-form {
 height: 100vh;
 display: flex;
 justify-content: center;
 align-items: center;
 background: linear-gradient(to right, #8163ff, #4350ff);
}

.user-data-page-form .form-wrapper {
 width: 40%;
 padding: 40px 80px;
 background: #ffffff;
 border-radius: 10px;

 @media (max-width: 1200px) {
  width: 60%;
  padding: 30px 60px;
 }

 @media (max-width: 767px) {
  width: 80%;
 }

 @media (max-width: 500px) {
  padding: 20px;
 }
}

.user-data-page .illustration-wrapper {
 width: 48%;
 padding: 40px 0 0;
 min-height: 100vh;
 text-align: center;
}

.user-data-page .illustration-wrapper2 {
 // width: 48%;
 // padding: 40px 0 0;
 min-height: 100vh;
 text-align: center;
}

.user-data-page .illustration-wrapper blockquote {
 font-size: 32px;
 line-height: 1.56em;
 color: #0e0e0e;
 padding: 0 15px 15px;
}

.user-data-page .illustration-wrapper .bio {
 font-family: "gorditamedium";
 font-size: 24px;
 color: #000;
 display: block;
 padding-bottom: 15px;
}

.user-data-page .illustration-wrapper .illustration-holder {
 width: 100%;
}

.user-data-page .form-wrapper {
 width: 52%;
 height: 100%;
 padding: 50px 5% 20px;
 position: relative;
}

.user-data-page .go-back-button {
 font-size: 15px;
 font-family: "gorditamedium";
 color: #000;
}

.user-data-page .go-back-button:hover {
 color: var(--red-one);
 text-decoration: underline;
}

.user-data-page .form-wrapper h2 {
 font-size: 62px;
 line-height: 1.161em;
}

.user-data-page .form-wrapper h2 span {
 color: var(--red-one);
 position: relative;
 display: inline-block;
}

.user-data-page .form-wrapper h2 span:before {
 content: "";
 position: absolute;
 width: 97%;
 height: 4px;
 background: var(--red-one);
 left: 0;
 bottom: 0;
}

.user-data-page .form-wrapper .header-info {
 font-size: 24px;
 color: var(--heading);
 letter-spacing: -1px;
 font-family: "Avenir", sans-serif;
}

.user-data-page .form-wrapper .header-info a {
 text-decoration: underline;
 color: var(--red-one);
}

.user-data-page .form-wrapper .copyright-text {
 font-size: 15px;
}

.user-data-form .element-optional {
 background-color: #bef5f5;
 padding: 50px 0px;
 border-radius: 50px;
}

.user-data-form .input-group-meta {
 position: relative;
 // padding: 0px 30px;
}

.user-data-form .input-group-meta input {
 width: 100%;
 height: 60px;
 font-size: 18px;
 border: 2px solid #000;
 border-radius: 8px;
 padding: 0 32px 0 20px;
 // background: transparent;
 font-family: "avenirroman";

 @media (max-width: 400px) {
  height: 40px;
 }
}

.user-data-form .input-group-meta label {
 font-size: 18px;
 // font-weight: normal;
 color: rgba(0, 0, 0, 0.5);
 display: block;
 margin-bottom: 2px;
 font-weight: 800;
 font-family: "avenirblack";

 @media (max-width: 400px) {
  font-size: 15px;
 }
}

// .user-data-form .input-group-meta label {
//   font-size: 14px;
//   font-weight: normal;
//   color: rgba(0, 0, 0, 0.5);
//   display: block;
//   margin-bottom: 2px;
// }

.user-data-form .input-group-meta .placeholder_icon {
 position: absolute;
 height: 60px;
 top: 30px;
 right: 0;
 bottom: 0;
 width: 50px;
 text-align: center;
 z-index: 1;
 color: rgba(0, 0, 0, 0.45);
 font-size: 17px;
}

.user-data-form .input-group-meta .valid-sign img {
 opacity: 0;
 transition: all 0.2s ease-in-out;
}

.user-data-form .input-group-meta input:valid + .valid-sign img {
 opacity: 1;
}

.user-data-form .input-group-meta .placeholder_icon img {
 position: relative;
 top: 50%;
 margin: 0 auto;
 transform: translateY(-50%);
}

.user-data-form .input-group-meta .placeholder_icon span {
 width: 100%;
 height: 100%;
 cursor: pointer;
 display: block;
 position: relative;
}

.user-data-form .input-group-meta .placeholder_icon span:before {
 content: "";
 width: 2px;
 height: 26px;
 background: #000;
 position: absolute;
 top: 15px;
 left: 24px;
 transform: rotate(45deg);
 z-index: 5;
 transition: all 0.2s ease-in-out;
}

.user-data-form .input-group-meta .placeholder_icon span.eye-slash:before {
 opacity: 0;
}

.user-data-form .agreement-checkbox label {
 position: relative;
 font-size: 18px;
 color: var(--heading);
 cursor: pointer;
 padding-left: 22px;
 line-height: 18px;
 transition: all 0.1s ease-in-out;
 font-family: "avenirbook";
}

.user-data-form .agreement-checkbox label a {
 color: var(--p-color);
 text-decoration: underline;
}

.user-data-form .agreement-checkbox input[type="checkbox"] {
 display: none;
}

.user-data-form .agreement-checkbox label:before {
 content: "";
 width: 13px;
 height: 13px;
 line-height: 11px;
 border-radius: 2px;
 border: 2px solid rgba(0, 0, 0, 0.3);
 font-size: 10px;
 text-align: center;
 position: absolute;
 left: 0;
 top: 3px;
 transition: all 0.1s ease-in-out;
}

.user-data-form
 .agreement-checkbox
 input[type="checkbox"]:checked
 + label:before {
 content: "\f272";
 font-family: bootstrap-icons !important;
 background: #000;
 color: #fff;
 border-color: #000;
}

.user-data-form .agreement-checkbox a {
 position: relative;
 font-size: 15px;
 color: #1e1e1e;
}

.user-data-form .agreement-checkbox a:hover {
 text-decoration: underline;
 color: var(--p-color);
}

.user-data-form .theme-btn-one {
 font-size: 14px;
 text-transform: uppercase;
}

.user-data-page .full-height {
 min-height: 100vh;
 padding: 20px 0;
 flex-direction: column;
 text-align: center;
}

.full-height .user-data-form .button-solid-one {
 width: 200px;
 margin: 0 auto 30px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Coming Soon ^^^^^^^^^^^^^^^^^^^^^^^^*/
.full-height-layout {
 min-height: 100vh;
 padding: 40px 12px;
 position: relative;
 z-index: 5;
 background: url(../images/assets/bg_01.png) no-repeat center;
 background-size: cover;
 overflow-x: hidden;
}

.coming-soon-content {
 max-width: 860px;
 margin: 0 auto;
 text-align: center;
}

.coming-soon-content h6 {
 font-size: 16px;
 text-transform: uppercase;
 letter-spacing: 2px;
}

.coming-soon-content h1 {
 font-size: 100px;
 line-height: 1.18em;
 padding: 8px 0 30px;
}

.coming-soon-content p {
 font-size: 24px;
 line-height: 1.66em;
 padding-bottom: 55px;
}

.coming-soon-content form {
 max-width: 620px;
 height: 75px;
 position: relative;
 margin: 0 auto;
}

.coming-soon-content form input {
 display: block;
 width: 100%;
 border: none;
 background: #f4f4f4;
 border-radius: 10px;
 padding: 0 190px 0 20px;
 height: 100%;
 box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
}

.coming-soon-content form button {
 width: 185px;
 background: #151515;
 color: #fff;
 font-family: "gorditamedium";
 border-radius: 10px;
 position: absolute;
 top: 6px;
 right: 6px;
 bottom: 6px;
 transition: all 0.3s ease-in-out;
}

.coming-soon-content form button:hover {
 background: var(--red-one);
}

.coming-soon-content .social-icon a {
 width: 34px;
 height: 34px;
 line-height: 32px;
 font-size: 17px;
 text-align: center;
 background: #fff;
 border-radius: 7px;
 color: #333333;
 margin: 0 5px;
 transition: all 0.3s ease-in-out;
}

.coming-soon-content .social-icon a:hover {
 background: var(--red-one);
 color: #fff;
}

.coming-soon-content .social-icon {
 position: absolute;
 left: 50%;
 transform: translateX(-50%);
 bottom: 40px;
}

.full-height-layout .shape-one {
 top: 8%;
 left: 45%;
 animation: jumpTwo 5s infinite linear;
}

.full-height-layout .shape-two {
 top: 6%;
 right: 45%;
 animation: jumpThree 5s infinite linear;
}

.full-height-layout .shape-three {
 top: 20%;
 right: 8%;
}

.full-height-layout .shape-four {
 bottom: 20%;
 left: 8%;
}

.full-height-layout .shape-five {
 top: 20%;
 left: 10%;
 animation: moveclouds 10s alternate infinite;
}

.full-height-layout .shape-six {
 bottom: 20%;
 right: 10%;
 animation: moveclouds2 10s alternate infinite;
}

/*^^^^^^^^^^^^^^^^^^^^^ Error Page ^^^^^^^^^^^^^^^^^^^^^^^^*/
.error-page {
 background: #232132;
 min-height: 100vh;
 padding: 185px 10% 50px;
 position: relative;
 z-index: 2;
}

.error-page h6 {
 font-size: 24px;
 color: rgba(255, 255, 255, 0.3);
 letter-spacing: 5px;
}

.error-page h1 {
 font-size: 82px;
 color: #fff;
 padding: 10px 0 25px;
}

.error-page p {
 color: #fff;
 margin-bottom: 55px;
}

.error-page .theme-btn-four {
 font-size: 14px;
}

.error-page .illustration {
 max-width: 64%;
 right: 0;
 top: 50%;
 transform: translateY(-50%);
}

/*^^^^^^^^^^^^^^^^^^^^^ One Page Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
#page-scroll-container-one {
 min-height: 100vh;
 position: relative;
}

#page-scroll-container-one .section {
 background: #fff;
 padding-top: 123px;
}

.spl-section-one .category-list li {
 font-size: 110px;
 line-height: 1.166em;
 font-weight: 500;
 color: #e7e7e7;
 position: relative;
}

.spl-section-one .category-list li span {
 display: inline-block;
 position: relative;
}

.spl-section-one .category-list li.active {
 color: #000;
}

.spl-section-one .category-list li span:before {
 content: "";
 position: absolute;
 width: 101%;
 height: 6px;
 background: var(--red-one);
 left: 0;
 top: 50%;
 margin-top: -3px;
 opacity: 0;
 transition: all 0.3s ease-in-out;
}

.spl-section-one .category-list li.active span:before {
 opacity: 1;
}

.spl-section-one .carousel-indicators [data-bs-target] {
 width: 8px;
 height: 8px;
 border-radius: 50%;
 padding: 0;
 opacity: 1;
 margin: 0 8px 0 0;
 border: 1px solid #000;
 background: transparent;
 transition: all 0.3s ease-in-out;
}

.spl-section-one .carousel-indicators .active {
 background: var(--red-one);
 border-color: var(--red-one);
}

.spl-section-one .carousel-indicators {
 width: 100%;
 bottom: -50px;
 margin: 0;
 justify-content: start;
}

#pp-nav {
 display: none;
}

.page-scroll-element-one .scroll-bar {
 width: 3px;
 height: 100px;
 background: #000;
 position: fixed;
 top: 50%;
 left: 65px;
 transform: translateY(-50%);
 z-index: 9;
}

.page-scroll-element-one .scroll-bar:before {
 content: "";
 position: absolute;
 height: 60%;
 width: 100%;
 left: 0;
 top: 0;
 background: var(--red-one);
 animation: jumpTwo 3s infinite linear;
}

.page-scroll-element-one .scroll-bar span {
 position: absolute;
 font-family: "gorditamedium";
 text-transform: uppercase;
 letter-spacing: 2px;
 font-size: 14px;
 transform: rotate(-90deg);
 top: 137%;
 left: -34px;
 color: #000;
}

.page-scroll-element-one .social-elemnet {
 position: fixed;
 z-index: 9;
 right: -45px;
 top: 50%;
 transform: rotate(-90deg) translate(0, 0%);
}

.page-scroll-element-one .social-elemnet li {
 font-family: "gorditamedium";
 color: #000;
 margin: 0 2px;
}

.page-scroll-element-one .social-elemnet li a:hover {
 color: var(--red-one);
}

.page-scroll-element-one .chat-icon {
 width: 52px;
 height: 52px;
 border-radius: 50%;
 background: var(--red-one);
 position: fixed;
 right: 65px;
 bottom: 4vh;
 z-index: 9;
 animation: rippleTwo 3s linear infinite;
}

.page-scroll-element-one .chat-icon::before,
.page-scroll-element-one .chat-icon::after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 border-radius: 50%;
 animation: inherit;
 animation-delay: 1s;
}

.page-scroll-element-one .chat-icon::after {
 animation-delay: 3s;
}

@keyframes rippleTwo {
 0% {
  box-shadow: 0 0 0 0.1rem rgba(210, 0, 3, 0.1);
 }

 100% {
  box-shadow: 0 0 0 4rem rgba(210, 0, 3, 0);
 }
}

.page-scroll-element-one .section-no {
 font-family: "gorditamedium";
 font-size: 20px;
 color: #000;
 transform: rotate(-90deg);
 position: fixed;
 bottom: 4vh;
 left: 58px;
}

/*--- Dark Version ---*/
.dark-bg #page-scroll-container-one .category-list li {
 color: #1f1f1f;
}

.dark-bg #page-scroll-container-one .category-list li.active,
.dark-bg.page-scroll-element-one .section-no,
.dark-bg.page-scroll-element-one .scroll-bar span,
.dark-bg.page-scroll-element-one .social-elemnet li {
 color: #fff;
}

.dark-bg #page-scroll-container-one .section {
 background: #121212;
}

.dark-bg.page-scroll-element-one .scroll-bar {
 background: #fff;
}

.dark-bg .spl-section-one .carousel-indicators [data-bs-target] {
 border: 1px solid #fff;
}

.dark-bg .spl-section-one .carousel-indicators .active {
 border-color: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-seven {
 padding: 240px 0 150px;
 background: #080808;
 position: relative;
}

.hero-banner-seven .main-content {
 max-width: 1535px;
 padding: 0 12px;
 margin: 0 auto;
}

.hero-banner-seven .img-meta-card p {
 font-size: 18px;
 line-height: 35px;
 color: #a3a2a3;
 padding-left: 80px;
}

.hero-banner-seven .img-meta-card p span {
 display: inline-block;
 color: #fff;
}

.hero-banner-seven .title {
 font-family: "gorditablack";
 color: #fff;
 font-size: 145px;
 text-align: center;
 line-height: 1.05em;
 letter-spacing: -1px;
}

.hero-banner-seven .title .line-shape {
 display: inline-block;
 position: relative;
}

.hero-banner-seven .title .line-shape:before {
 content: "";
 width: 100%;
 height: 100%;
 position: absolute;
 left: 0;
 top: 10px;
 background: url(../images/shape/shape_52.svg) no-repeat center;
 background-size: contain;
 z-index: 1;
}

.hero-banner-seven .scroll-btn {
 position: absolute;
 left: 50%;
 bottom: 100px;
 margin-left: -20px;
 z-index: 1;
 animation: jumpTwo 5s infinite linear;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-feature-section-nine .row {
 margin: 0 -30px;
}

.vcamp-feature-section-nine [class*="col-"] {
 padding: 0 30px;
}

.card-style-nine {
 padding: 35px 30px 80px;
 background: #f4f4f4;
 border-radius: 50px;
 text-align: center;
}

.card-style-nine .img-holder {
 height: 265px;
}

.card-style-nine h3 {
 font-family: "gorditabold";
 font-size: 36px;
 line-height: 1.33em;
 letter-spacing: 0px;
 padding-top: 45px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-seven .text-wrapper p {
 margin-bottom: 30px;
 padding-top: 12px;
 color: #454545;
}

.vcamp-text-block-seven .text-wrapper .name {
 font-family: "gorditamedium";
 font-size: 18px;
 padding-left: 32px;
 position: relative;
 margin-top: 60px;
}

.vcamp-text-block-seven .text-wrapper .name span {
 font-size: 16px;
 font-family: "gorditaregular";
 color: #b9b9b9;
}

.vcamp-text-block-seven .text-wrapper .name:before {
 content: "";
 position: absolute;
 width: 19px;
 height: 2px;
 background: var(--heading);
 left: 0;
 top: 10px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-eight {
 padding: 135px 0 120px;
 position: relative;
 z-index: 1;
}

.vcamp-text-block-eight .text-wrapper h6 {
 font-size: 17px;
 color: #ffffff;
 text-transform: uppercase;
 letter-spacing: 3.8px;
 padding-bottom: 20px;
}

.vcamp-text-block-eight .text-wrapper h2 {
 font-family: "gorditablack";
 font-size: 82px;
 line-height: 1.19em;
 letter-spacing: -1px;
 color: #fff;
}

.vcamp-text-block-eight .illustration {
 left: 11vw;
 max-width: 35%;
 top: 50%;
 transform: translateY(-50%);
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-nine {
 position: relative;
 z-index: 1;
}

.vcamp-text-block-nine .text-wrapper h2 {
 font-family: "gorditablack";
 font-size: 82px;
 line-height: 1.13em;
 letter-spacing: -1px;
}

.vcamp-text-block-nine .text-wrapper p {
 padding: 45px 0 40px;
}

.vcamp-text-block-nine .illustration {
 right: 0;
 max-width: 42%;
 bottom: 0;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Ten ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-ten {
 padding: 240px 0;
 position: relative;
 z-index: 1;
 overflow: hidden;
}

.vcamp-text-block-ten .city-list {
 position: absolute;
 top: -65px;
 bottom: -65px;
 left: -10px;
 animation: moveUp 30s alternate infinite;
}

.vcamp-text-block-ten .city-list li {
 font-family: "gorditabold";
 font-size: 100px;
 line-height: 1.3em;
 letter-spacing: -2px;
 color: rgba(255, 255, 255, 0.25);
}

/*^^^^^^^^^^^^^^^^^^^^^ Team Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.team-block-three {
 text-align: center;
 margin-top: 55px;
}

.team-block-three .name {
 font-family: "gorditabold";
 font-size: 20px;
 text-transform: uppercase;
 letter-spacing: 2px;
 margin: 35px 0 2px;
}

.team-block-three .designation {
 font-size: 16px;
 color: #989898;
 padding-bottom: 15px;
}

.team-block-three .social-icon li a {
 width: 32px;
 height: 32px;
 font-size: 17px;
 background: var(--heading);
 border-radius: 50%;
 line-height: 30px;
 color: #fff;
 margin: 0 6px;
 transition: all 0.3s ease;
}

.team-block-three .social-icon li a:hover {
 background: var(--red-one);
}

.team-block-three:hover .name a {
 text-decoration: underline;
 color: var(--heading);
}

/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.blog-section-six {
 padding: 150px 0 130px;
}

.blog-section-six .blog-meta-three {
 background: transparent;
 border-top: 1px solid #f5f5f5;
 border-bottom: 1px solid #f5f5f5;
 border-radius: 0;
 padding: 35px 60px 36px 25px;
}

.blog-section-six .blog-meta-three.featured-post {
 background: rgba(255, 255, 255, 0.08);
 border-color: transparent;
}

.blog-section-six .blog-meta-three .tag {
 background: rgba(255, 255, 255, 0.1);
}

.blog-section-six .blog-meta-three .title {
 font-family: "gorditabold";
 font-size: 36px;
 color: #fff;
 line-height: 1.46em;
 margin-bottom: 50px;
}

.blog-section-six .blog-meta-three .post-info {
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-two {
 position: relative;
 z-index: 5;
 margin-top: 135px;
}

/*----------Slide----------*/
.carousel-container {
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 1;
 /* Asegura que el carrusel esté por encima de otros elementos */
}

.banner-container {
 position: relative;
 transition: opacity 0.5s ease;
 opacity: 1;
}

.banner-slide {
 // position: absolute;
 // top: 0;
 // left: 0;
 // width: 100%;
 // height: 100%;
 opacity: 0;
 transition: opacity 0.5s ease-in-out;
}

.banner-slide.active {
 opacity: 1;
 visibility: visible;
}

.banner-slide.banner-hidden {
 display: none;
 // opacity: 0;
}

.slider-button {
 position: absolute;
 top: 50%;
 transform: translateY(-50%);
 padding: 10px 15px;
 font-size: 16px;
 background-color: #ccc;
 border: none;
 border-radius: 4px;
 cursor: pointer;
 z-index: 1;
}

.left-button {
 left: 20px;
}

.right-button {
 right: 20px;
}

/*----------Buttons navigation----------*/
.navigation-dots {
 position: absolute;
 bottom: 1vh;
 left: 50%;
 transform: translateX(-50%);
 display: flex;
}

.dot {
 width: 12px;
 height: 12px;
 border-radius: 50%;
 background-color: #797979;
 margin: 5px;
 cursor: pointer;
}

.active-dot {
 background-color: #00fbc8;
}

/*----------Banner Price List----------*/
.price-list {
 font-family: "avenirblack";
 background: #e7e1f2;
 padding: 50px 0;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 gap: 100px;
}

.price-list .title {
 color: #8163ff;
 text-align: center;
 width: 40%;

 h2 {
  color: #8163ff;
  font-weight: bold;
  font-size: 60px;
 }

 p {
  font-size: 30px;
 }
}

.price-list .body {
 display: flex;
 // justify-content: space-between;
 gap: 40px;
}

.price-list .body .month-promo {
 background: #8163ff;
}

.price-list .body .month {
 position: relative;
 width: 300px;
 // min-height: calc(70vh - 150px);
 min-height: calc(100vh - 450px);
 color: #8163ff;
 background: #ffffff;
 border-radius: 30px;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 gap: 10px;
 padding: 30px;

 a {
  width: max-content;
  font-family: "avenirbook";
  font-weight: bold;
  background: #8163ff;
  border-radius: 30px;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 50px;
 }

 .promo {
  position: absolute;
  top: -40px;
  left: 100px;
  background: #ffc700;
  color: #8163ff;
  padding: 5px 15px;
  font-family: "avenirblack";
 }
}

.price-list .body .month .info {
 font-family: "avenirblack";
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;

 h3 {
  color: #8163ff;
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding-bottom: 40px;
 }

 .option-price {
  display: flex;
  flex-direction: column;
  align-items: center;

  p:last-of-type {
   width: max-content;
   padding: 0 35px;
   font-size: 22px;
   border-bottom: 2px solid;
  }

  .price {
   width: max-content;
   font-weight: bold;
   font-size: 55px;
   letter-spacing: 0.5px;
  }
 }

 .description {
  width: 100%;
  font-size: 17px;
 }
}

/*----------Banner Price List----------*/

/*----------Banner Promo One----------*/
.hero-banner-promo-one {
 background: linear-gradient(to left, #7255e7, #ad00ff);
 position: relative;
 margin-top: 75;
 transition: opacity 900ms ease 0s, visibility 900ms ease 0s;
}

.hero-banner-promo-one .body {
 display: flex;
 gap: 10px;
 font-family: "avenirroman";
 padding: 0 60px;
}

.hero-banner-promo-one .body .section-text {
 display: flex;
 flex-direction: column;
 justify-content: center;
 // align-items: center;
 gap: 20px;
 color: #ffffff;
 width: 50%;
 padding: 40px;

 h2 {
  width: 50%;
  font-family: "avenirblack";
  color: #ffffff;
  font-size: 50px;
 }

 p {
  width: 45%;
  margin: 0;
  font-size: 15px;
 }

 .first-p {
  width: 55%;
  font-size: 22px;
 }

 .content-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
 }
}

.hero-banner-promo-one .body .section-text .content-button {
 display: flex;
 justify-content: center;
 gap: 10px;
 font-size: 18px;
 width: 60%;
 padding: 4px;
 border: 1px solid #000;
 border-radius: 10px;
 background: #ffffff;

 input {
  color: #000;
 }

 a {
  width: 100%;
  text-align: center;
  padding: 1px 10px;
  border: 1px solid #000;
  border-radius: 5px;
 }

 .btn-one {
  font-size: 24px;
  padding: 10px;
  color: #ffffff;
  background: #000000;
 }
}

.hero-banner-promo-one .body .section-banner {
 width: 50%;
 max-height: 80vh;
 min-height: 80vh;
 display: flex;
 // justify-content: center;
 // align-items: center;
 justify-content: end;
 gap: 30px;

 .first-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
 }

 .second-column {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
 }

 .image-one,
 .image-two,
 .image-three,
 .image-four,
 .image-five,
 .image-six {
  width: 200px;
  height: auto;
 }
}

/*----------Banner Promo Two----------*/
.hero-banner-promo-two {
 background: #f7c500;
 position: relative;
 margin-top: 75;
}

.hero-banner-promo-two .body {
 display: flex;
 gap: 10px;
 font-family: "avenirblack";
 padding: 50px 80px;
}

.hero-banner-promo-two .body .section-text {
 display: flex;
 flex-direction: column;
 // align-items: center;
 gap: 20px;
 width: 40%;

 h2 {
  font-size: 35px;
 }

 p {
  width: 80%;
  margin: 0;
  font-size: 14px;
  // color: #FFFFFF;
 }
}

.hero-banner-promo-two .body .section-text .content-button {
 display: flex;
 flex-direction: column;
 gap: 10px;
 font-size: 14px;

 a {
  width: 60%;
  text-align: center;
  padding: 5px 20px;
  border: 1px solid #000;
  border-radius: 5px;
 }

 .btn-one {
  color: #ffffff;
  background: #8163ff;
 }

 .btn-two {
  color: #8163ff;
  background: #ffffff;
  border-color: #ff2335;
 }
}

.hero-banner-promo-two .body .section-banner {
 width: 60%;
 // min-height: 600px;
 padding: 10px 0;
 position: relative;
 display: flex;
 align-items: center;

 .purple-square {
  position: absolute;
  top: 0;
  width: 40%;
  height: 70%;
  background: #7255e7;
  border-radius: 10px;
 }

 .banner {
  position: absolute;
  right: 0;
  width: 95%;
  height: 80%;
 }
}

/*----------Banner Promo Three--------*/
.hero-banner-promo-three {
 background: linear-gradient(to right, #eeeeee, #f3a8ff, #8163ff);
 position: relative;
 margin-top: 93px;
}

.hero-banner-promo-three .body {
 display: flex;
 font-family: "avenirblack";
 padding: 20px;
}

.hero-banner-promo-three .body .section-text {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 gap: 20px;
 width: 40%;

 h2 {
  width: 85%;
  font-family: "avenirblack";
  font-size: 70px;
  text-align: center;
 }

 p {
  text-align: center;
  width: 80%;
  margin: 0;
  font-size: 24px;
 }
}

.hero-banner-promo-three .body .section-text .content-button {
 // display: flex;
 // gap: 1px;
 font-size: 14px;
 width: 80%;
 padding: 2px 5px;
 border: 1px solid #000;
 border-radius: 5px;
 background: #ffffff;

 input {
  color: #000;
 }

 a {
  width: 100%;
  text-align: center;
  padding: 1px 5px;
  border: 1px solid #000;
  border-radius: 5px;
 }

 .btn-one {
  font-size: 24px;
  padding: 12px;
  color: #ffffff;
  background: #000000;
 }
}

.hero-banner-promo-three .body .first-image,
.hero-banner-promo-three .body .second-image {
 display: flex;
 justify-content: center;
 width: 30%;
 // min-height: 72vh;
 // max-height: 72vh;

 img {
  max-width: 100%;
  max-height: 100%;
 }
}

/*----------Banners Promo Section END----------*/

.hero-banner-eight {
 position: relative;
 z-index: 5;
 margin-top: 135px;
}

.hero-banner-eight .social-elemnet {
 position: absolute;
 z-index: 9;
 left: -5px;
 top: 44%;
 transform: rotate(-90deg) translate(0, 0);
}

.hero-banner-eight .social-elemnet li {
 font-family: "gorditamedium";
 color: #000;
 margin: 0 5px;
}

.hero-banner-eight .social-elemnet li a:hover {
 color: var(--red-one);
}

.hero-banner-eight .scroll-bar {
 width: 3px;
 height: 100px;
 background: #000;
 position: absolute;
 top: 45%;
 right: 50px;
 transform: translateY(-50%);
 z-index: 9;
}

.hero-banner-eight .scroll-bar:before {
 content: "";
 position: absolute;
 height: 50%;
 width: 100%;
 left: 0;
 bottom: 0;
 background: var(--red-one);
}

.hero-banner-eight .banner-content {
 padding: 60px 11vw;
 max-width: 2000px;
 margin: 0 auto;
}

.hero-banner-eight .text-wrapper {
 max-width: 610px;
}

.hero-banner-eight .hero-heading {
 font-family: "avenirblack";
 line-height: 1.1em;
 letter-spacing: -1px;
 font-size: 70px;
}

.hero-banner-eight .hero-sub-heading {
 letter-spacing: -1px;
 padding: 15px 0 5px;
 font-family: "avenirroman";
 font-size: 23px;
}

.hero-banner-eight .term-text {
 font-size: 20px;
 // color: #979797;
 padding-top: 14px;
 color: #000;
 // font-weight: 600;
 font-family: "avenirbook";
}

.hero-banner-eight .term-text .sign-up-text {
 font-weight: bold;
 font-size: 20px;
 color: #8163ff;
}

.hero-banner-eight .term-text a {
 color: var(--heading);
}

// .hero-banner-eight .term-text a:hover {
//   text-decoration: underline;
// }

// .hero-banner-eight .img-wrapper img {
//  transform: rotate(4.3deg);
// }
.hero-banner-eight .img-wrapper:before {
 content: "";
 position: absolute;
 width: 94%;
 height: 100%;
 left: -19%;
 bottom: -6%;
 z-index: -1;
 //  background: var(--red-one);
 //  transform: rotate(-7.16deg);
}

.hero-banner-eight .img-wrapper {
 margin-bottom: 6%;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Eleven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-eleven .meta-info-text {
 font-size: 36px;
 line-height: 1.55em;
 letter-spacing: -1px;
 margin-bottom: 0;
 font-family: "avenirblack";
}

.vcamp-text-block-eleven .meta-info-text span {
 display: inline-block;
 color: #8163ff; //var(--red-one);
 // text-decoration: underline;
}

.vcamp-text-block-eleven .main-content {
 position: relative;
 z-index: 1;
 margin: 190px 0 90px;
}

.vcamp-text-block-eleven .main-content .left-img-meta {
 position: absolute;
 width: 45%;
 height: 140%;
 left: 0;
 top: 50%;
 transform: translateY(-50%);
}

.vcamp-text-block-eleven .main-content .left-img-meta img {
 max-height: 100%;
 min-height: 100%;
 object-fit: cover;
 object-position: top center;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Twelve ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-twelve {
 position: relative;
 z-index: 1;
}

.vcamp-text-block-twelve .text-wrapper .video-icon {
 width: 65px;
 height: 65px;
 border-radius: 50%;
 background: var(--heading);
 padding: 15px 16px 18px 13px;
 position: relative;
 animation: ripple 3s linear infinite;
}

.vcamp-text-block-twelve .text-wrapper .video-icon::before,
.vcamp-text-block-twelve .text-wrapper .video-icon::after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 border-radius: 50%;
 animation: inherit;
 animation-delay: 1s;
}

.vcamp-text-block-twelve .text-wrapper .video-icon::after {
 animation-delay: 3s;
}

.vcamp-text-block-twelve .text-wrapper .meta-info-text {
 font-size: 20px;
 line-height: 1.75em;
 margin-bottom: 40px;
}

.vcamp-text-block-twelve .text-wrapper ul li {
 line-height: 33px;
 color: #474747;
 position: relative;
 margin-bottom: 25px;
 padding-left: 35px;
}

.vcamp-text-block-twelve .text-wrapper ul li:before {
 content: url(../images/icon/icon_75.svg);
 position: absolute;
 left: 0;
 top: 1px;
}

.vcamp-text-block-twelve .text-wrapper ul li span {
 font-family: "gorditamedium";
 color: #000;
 display: inline-block;
 position: relative;
}

.vcamp-text-block-twelve .text-wrapper ul li span:before {
 content: "";
 position: absolute;
 left: 0;
 bottom: 5px;
 height: 2px;
 width: 100%;
 background: var(--red-one);
}

.vcamp-text-block-twelve .right-img-meta {
 position: absolute;
 width: 47%;
 height: 100%;
 right: 0;
 top: 50%;
 transform: translateY(-50%);
}

.vcamp-text-block-twelve .right-img-meta .main-img {
 max-height: 100%;
 min-height: 100%;
 object-fit: cover;
 object-position: top center;
}

/*--- Dark BG ----*/
.vcamp-text-block-twelve.dark-bg,
.vcamp-text-block-twelve.dark-bg .text-wrapper ul li {
 color: rgba(255, 255, 255, 0.7);
}

.vcamp-text-block-twelve.dark-bg .text-wrapper ul li span {
 color: #fff;
}

.vcamp-text-block-twelve.dark-bg .text-wrapper ul li:before {
 content: url(../images/icon/icon_97.svg);
}

.vcamp-text-block-twelve .right-img-meta .video-icon {
 width: 65px;
 height: 65px;
 border-radius: 50%;
 background: var(--red-one);
 padding: 15px 16px 18px 13px;
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 animation: ripple 3s linear infinite;
}

.vcamp-text-block-twelve .right-img-meta .video-icon::before,
.vcamp-text-block-twelve .right-img-meta .video-icon::after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 border-radius: 50%;
 animation: inherit;
 animation-delay: 1s;
}

.vcamp-text-block-twelve .right-img-meta .video-icon::after {
 animation-delay: 3s;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-six {
 position: relative;
 z-index: 5;
 margin-top: 135px;
 background: radial-gradient(
  53.3% 53.3% at 50% 46.7%,
  #feffff 0%,
  #e4efef 100%
 );
}

.hero-banner-six .social-elemnet {
 position: absolute;
 z-index: 9;
 left: 10px;
 top: 47%;
 transform: rotate(-90deg) translate(0, 0);
}

.hero-banner-six .social-elemnet li {
 font-family: "gorditamedium";
 color: #000;
 margin: 0 5px;
}

.hero-banner-six .social-elemnet li a:hover {
 color: var(--red-one);
}

.hero-banner-six .scroll-bar {
 width: 3px;
 height: 100px;
 background: #000;
 position: absolute;
 top: calc(50% - 45px);
 right: 65px;
 transform: translateY(-50%);
 z-index: 9;
}

.hero-banner-six .scroll-bar:before {
 content: "";
 position: absolute;
 height: 60%;
 width: 100%;
 left: 0;
 top: 0;
 background: #fff;
}

.hero-banner-six .scroll-bar span {
 position: absolute;
 font-family: "gorditamedium";
 text-transform: uppercase;
 letter-spacing: 2px;
 font-size: 14px;
 transform: rotate(-90deg);
 top: 137%;
 left: -34px;
 color: #000;
}

.hero-banner-six .product-text {
 position: relative;
 z-index: 2;
}

.hero-banner-six .product-text h6 {
 font-size: 18px;
 letter-spacing: 3px;
 text-transform: uppercase;
 color: #676767;
 transition: all 0.5s ease-in-out;
}

.hero-banner-six .product-text h2 {
 font-size: 140px;
 font-weight: 500;
 line-height: 0.92em;
 padding: 20px 0 30px;
 transition: all 0.8s ease-in-out;
}

.hero-banner-six .product-text p {
 transition: all 1s ease-in-out;
}

.hero-banner-six .product-text [class*="theme-btn-"] {
 transition: all 1.3s ease-in-out;
}

.hero-banner-six .section-no {
 font-family: "gorditamedium";
 font-size: 20px;
 color: #000;
 transform: rotate(-90deg);
 position: absolute;
 bottom: 50px;
 left: 60px;
}

.hero-banner-six .product-text {
 padding: 150px 0;
}

.hero-banner-six .product-text *,
.hero-banner-six .product-img {
 transform: translateY(50px);
}

.hero-banner-six .slick-active .product-text *,
.hero-banner-six .slick-active .product-img {
 transform: translateY(0);
}

/*^^^^^^^^^^^^^^^^^^^^^ Product Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.product-slider-arrow-one li {
 width: 50px;
 height: 50px;
 border-radius: 50%;
 text-align: center;
 line-height: 50px;
 color: var(--heading);
 font-size: 28px;
 margin-left: 8px;
 cursor: pointer;
 transform: scale(0.8);
 transition: all 0.3s ease-in-out;
}

.product-slider-arrow-one li:hover {
 background: var(--heading);
 color: #fff;
 transform: scale(1);
}

.product-block-one {
 position: relative;
 background: #f5f5f5;
 height: 470px;
 z-index: 5;
}

.product-block-one:hover .product-img {
 transform: scale(0.95);
}

.product-block-one .category-tag {
 background: #fff;
 line-height: 33px;
 font-size: 12px;
 letter-spacing: 1px;
 text-transform: uppercase;
 color: #000;
 padding: 0 18px;
 position: absolute;
 right: 30px;
 bottom: 20px;
 z-index: 1;
 transition: all 0.3s ease-in-out;
}

.product-block-one .category-tag:hover {
 background: var(--red-one);
 color: #fff;
 border-radius: 5px;
}

.product_slider_space {
 margin: 0 -20px;
}

.product_slider_space .item {
 padding: 0 20px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Shop New Collection Section ^^^^^^^^^^^^^^^^^^^^^^^^*/
.shop-new-collection-section .banner-text-meta {
 position: relative;
 padding: 210px 65px;
 z-index: 1;
 overflow: hidden;
}

.shop-new-collection-section .banner-text-meta .fancy-text {
 font-family: "Recoleta";
 font-size: 24px;
 color: var(--red-two);
 line-height: initial;
}

.shop-new-collection-section .banner-text-meta h3 {
 font-family: "gorditamedium";
 font-size: 30px;
 letter-spacing: 2px;
 text-transform: uppercase;
 padding: 15px 0 35px;
}

.shop-new-collection-section .banner-text-meta .promo-img {
 position: absolute;
 right: 0;
 bottom: 0;
 z-index: -1;
 max-width: 66%;
 max-height: 100%;
}

/*^^^^^^^^^^^^^^^^^^^^^ Product Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.product-block-two .img-holder {
 position: relative;
 background: #f5f5f5;
 height: 470px;
 z-index: 5;
 margin-bottom: 22px;
}

.product-block-two .img-holder.style-two {
 height: auto;
}

.product-block-two .img-holder .tag-one {
 font-family: "gorditamedium";
 text-transform: uppercase;
 color: #fff;
 font-size: 14px;
 letter-spacing: 0px;
 padding: 0 14px;
 line-height: 25px;
 border-radius: 3px;
 background: var(--red-one);
 position: absolute;
 right: 20px;
 top: 20px;
}

.product-block-two .img-holder .tag-two {
 text-transform: uppercase;
 color: #fff;
 font-size: 13px;
 letter-spacing: 0px;
 padding: 0 14px;
 line-height: 25px;
 border-radius: 3px;
 background: #3c3c3c;
 position: absolute;
 right: 20px;
 top: 20px;
}

.product-block-two .img-holder .cart-icon {
 display: block;
 width: 35px;
 line-height: 35px;
 box-shadow: 0 0 30px rgba(0, 0, 0, 0.09);
 border-radius: 50%;
 background: #fff;
 color: #000;
 text-align: center;
 position: absolute;
 right: 20px;
 top: 20px;
 font-size: 18px;
 transition: all 0.3s ease-in-out;
 opacity: 0;
 transform: scale(0.5);
}

.product-block-two .img-holder .cart-icon:hover {
 color: #fff;
 background: var(--red-one);
}

.product-block-two .img-holder .cart-button {
 font-size: 14px;
 letter-spacing: 1px;
 text-transform: uppercase;
 position: absolute;
 left: 20px;
 right: 20px;
 bottom: 20px;
 line-height: 48px;
 background: #1f1f1f;
 text-align: center;
 color: #fff;
 opacity: 0;
 transform: translateY(10px);
 transition: all 0.4s ease-in-out;
}

.product-block-two:hover .img-holder .cart-button {
 opacity: 1;
 transform: translateY(0);
}

.product-block-two .img-holder .cart-button:hover {
 background: var(--red-one);
}

.product-block-two .product-title {
 letter-spacing: 0px;
 color: #9f9f9f;
 font-size: 17px;
 transition: all 0.3s ease-in-out;
}

.product-block-two .rating li {
 font-size: 14px;
 margin-left: 5px;
 color: #b3b3b3;
}

.product-block-two .rating li .bi-star-fill {
 color: #ffcb65;
}

.product-block-two .price {
 font-size: 24px;
 color: #000;
 margin-top: 10px;
}

.product-block-two:hover .product-img {
 transform: scale(0.95);
}

.product-block-two:hover .cart-icon {
 opacity: 1;
 transform: scale(1);
}

.product-block-two:hover .product-title {
 color: #000;
 text-decoration: underline;
}

/*^^^^^^^^^^^^^^^^^^^^^ Product Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.product-section-three {
 background: var(--light-blue);
}

.product-block-three {
 background: #fff;
 padding: 30px 15px 60px;
 text-align: center;
}

.product-block-three .img-holder {
 position: relative;
 height: 470px;
 z-index: 5;
 margin-bottom: 35px;
}

.product-block-three .img-holder img {
 max-height: 100%;
}

.product-block-three .img-holder .cart-icon {
 display: block;
 width: 35px;
 line-height: 35px;
 box-shadow: 0 0 30px rgba(0, 0, 0, 0.09);
 border-radius: 50%;
 background: #000;
 color: #fff;
 text-align: center;
 position: absolute;
 right: 20px;
 top: 20px;
 font-size: 18px;
 transition: all 0.3s ease-in-out;
 opacity: 0;
 transform: scale(0.5);
}

.product-block-three .img-holder .cart-icon:hover {
 color: #fff;
 background: var(--red-one);
}

.product-block-three .product-title {
 letter-spacing: 0px;
 color: #9f9f9f;
 font-size: 17px;
 margin: 5px 0 10px;
 transition: all 0.3s ease-in-out;
}

.product-block-three .rating li {
 font-size: 14px;
 margin: 0 3px;
 color: #b3b3b3;
}

.product-block-three .rating li .bi-star-fill {
 color: #ffcb65;
}

.product-block-three .price {
 font-size: 24px;
 color: #000;
}

.product-block-three:hover .product-img {
 transform: scale(0.95);
}

.product-block-three:hover .cart-icon {
 opacity: 1;
 transform: scale(1);
}

.product-block-three:hover .product-title {
 color: #000;
 text-decoration: underline;
}

.product-block-three .img-holder .tag-one {
 font-family: "gorditamedium";
 text-transform: uppercase;
 color: #fff;
 font-size: 14px;
 letter-spacing: 0px;
 padding: 0 14px;
 line-height: 25px;
 border-radius: 3px;
 background: var(--red-one);
 position: absolute;
 right: 0;
 top: 0;
}

.product-block-three .img-holder .tag-two {
 text-transform: uppercase;
 color: #fff;
 font-size: 13px;
 letter-spacing: 0px;
 padding: 0 14px;
 line-height: 25px;
 border-radius: 3px;
 background: #0f0f0f;
 position: absolute;
 right: 0;
 top: 0;
}

/*^^^^^^^^^^^^^^^^^^^^^ One Page Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
#page-scroll-container-two {
 min-height: 100vh;
 position: relative;
}

.page-scroll-element-two .social-elemnet {
 position: fixed;
 z-index: 9;
 left: -20px;
 top: 47%;
 transform: rotate(-90deg) translate(0, 0);
}

.page-scroll-element-two .social-elemnet li {
 font-family: "gorditamedium";
 color: #fff;
 margin: 0 5px;
}

.page-scroll-element-two .social-elemnet li a:hover {
 color: var(--red-one);
}

.page-scroll-element-two .scroll-bar {
 width: 3px;
 height: 100px;
 background: rgba(255, 255, 255, 0.26);
 position: fixed;
 top: 50%;
 right: 49px;
 transform: translateY(-50%);
 z-index: 9;
}

.page-scroll-element-two .scroll-bar:before {
 content: "";
 position: absolute;
 height: 60%;
 width: 100%;
 left: 0;
 bottom: 0;
 background: #fff;
 animation: jumpTwo 3s infinite linear;
}

.page-scroll-element-two .section-no {
 width: 100px;
 height: 100px;
 text-align: center;
 line-height: 100px;
 font-family: "gorditamedium";
 letter-spacing: 3px;
 font-size: 20px;
 color: #fff;
 transform: rotate(-90deg);
 position: fixed;
 bottom: 0;
 left: 0;
 border-bottom: 1px solid #fff;
 border-right: 1px solid #fff;
}

.page-scroll-element-two .chat-icon {
 width: 100px;
 height: 100px;
 text-align: center;
 line-height: 100px;
 color: #fff;
 font-size: 25px;
 position: fixed;
 right: 0;
 bottom: 0;
 z-index: 9;
 border-top: 1px solid #fff;
 border-left: 1px solid #fff;
 transition: all 0.3s ease-in-out;
}

.page-scroll-element-two .chat-icon:hover {
 color: var(--red-one);
}

#page-scroll-container-two .section {
 background: #121212;
 padding-top: 100px;
}

.spl-section-two .main-wrapper {
 padding: 0 12vw;
}

.spl-section-two .main-wrapper .text-holder {
 max-width: 512px;
}

.spl-section-two .main-wrapper .text-holder .item-title {
 font-size: 130px;
 line-height: 0.93em;
 color: #fff;
}

.spl-section-two .main-wrapper .product-info {
 margin: 50px -15px 50px;
}

.spl-section-two .main-wrapper .product-info > li {
 width: 33.333%;
 padding: 0 15px;
 margin-bottom: 20px;
}

.spl-section-two .main-wrapper .product-info .label-title {
 font-family: "gorditamedium";
 font-size: 16px;
 letter-spacing: 5px;
 text-transform: uppercase;
 margin-bottom: 18px;
 color: rgba(255, 255, 255, 0.3);
}

.spl-section-two .main-wrapper .product-info .price {
 font-size: 24px;
 color: #fff;
 letter-spacing: 0px;
 line-height: initial;
}

.spl-section-two .size-custome-input li label {
 position: relative;
 font-size: 20px;
 color: #fff;
 transition: all 0.25s ease-in-out;
}

.spl-section-two .size-custome-input li {
 position: relative;
 margin-right: 20px;
}

.spl-section-two .size-custome-input li:last-child {
 margin-right: 0;
}

.spl-section-two .size-custome-input li input[type="radio"] {
 position: absolute;
 opacity: 0;
 z-index: 1;
 width: 100%;
 height: 100%;
 left: 0;
 cursor: pointer;
}

.spl-section-two .size-custome-input li input[type="radio"]:checked + label {
 color: var(--red-one);
}

.spl-section-two .color-custome-input li {
 width: 26px;
 height: 26px;
 border-radius: 50%;
 position: relative;
 margin-right: 15px;
}

.spl-section-two .color-custome-input li:last-child {
 margin-right: 0;
}

.spl-section-two .color-custome-input li input[type="radio"] {
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 opacity: 0;
 cursor: pointer;
 z-index: 1;
}

.spl-section-two .color-custome-input li label {
 position: absolute;
 width: 100%;
 height: 100%;
 border-radius: 50%;
 top: 0;
 left: 0;
 z-index: -1;
}

.spl-section-two .color-custome-input li label:before {
 content: "";
 position: absolute;
 top: -5px;
 right: -5px;
 bottom: -5px;
 left: -5px;
 border: 2px solid #fff;
 border-radius: 50%;
 opacity: 0;
 transition: all 0.25s ease-in-out;
}

.spl-section-two
 .color-custome-input
 li
 input[type="radio"]:checked
 + label:before {
 opacity: 1;
}

.spl-section-two .text-holder .shop-btn {
 width: 200px;
 line-height: 53px;
 border: 1px solid #ffd085;
 text-align: center;
 font-family: "gorditamedium";
 font-size: 15px;
 text-transform: uppercase;
 letter-spacing: 2px;
 color: #fff;
 position: relative;
 background: #000000;
}

.spl-section-two .text-holder .shop-btn:after {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 left: 4px;
 top: 4px;
 z-index: -1;
 background: #ffd085;
 opacity: 0;
 transition: all 0.25s ease-in-out;
}

.spl-section-two .text-holder .shop-btn:hover:after {
 opacity: 1;
}

.spl-section-two .text-holder .add-to-fav {
 font-size: 24px;
 color: #fff;
 margin: 0 20px 0 55px;
}

.spl-section-two .text-holder .add-cart-btn {
 font-family: "gorditalight";
 font-size: 42px;
 color: #fff;
}

.spl-section-two .text-holder .add-to-fav:hover,
.spl-section-two .text-holder .add-to-fav:active,
.spl-section-two .text-holder .add-to-fav:focus,
.spl-section-two .text-holder .add-cart-btn:hover {
 color: var(--red-one);
}

.spl-section-two .main-wrapper .text-holder {
 max-width: 512px;
 position: relative;
 z-index: 2;
}

.spl-section-two .main-wrapper .product-img-holder {
 background: #ffd085;
 max-width: 535px;
 height: 670px;
 position: relative;
 z-index: 1;
}

.spl-section-two .main-wrapper .product-img-holder .product-img {
 max-width: initial;
 position: relative;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
}

.spl-section-two .main-wrapper .product-img-holder .offer-img {
 position: absolute;
 right: 30px;
 bottom: 30px;
 z-index: -1;
}

/*^^^^^^^^^^^^^^^^^^^^^ Inside Page Banner Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/
.inside-hero-nine {
 background: #fff;
 padding: 175px 0 0;
}

.inside-hero-nine .main-wrapper {
 padding-bottom: 80px;
}

.inside-hero-nine .page-title {
 font-size: 72px;
 font-weight: 500;
 margin-bottom: 30px;
}

.inside-hero-nine .main-wrapper p {
 font-size: 24px;
}

.inside-hero-nine .breadcrumb-area {
 padding: 3px 0 23px;
}

.breadcrumb-style-one .breadcrumb-item a,
.breadcrumb-style-one .breadcrumb-item {
 font-size: 17px;
 color: #949494;
}

.breadcrumb-style-one .breadcrumb-item.active {
 font-family: "gorditamedium";
 color: #000;
}

.breadcrumb-style-one .breadcrumb-item + .breadcrumb-item::before {
 content: url(../images/icon/icon_77.svg);
 margin-top: 1px;
}

.breadcrumb-style-one .breadcrumb-item a:hover {
 text-decoration: underline;
}

.inside-hero-nine .share-dropdown .dropdown-toggle {
 font-family: "gorditamedium";
 font-size: 17px;
 color: #000;
}

.inside-hero-nine .share-dropdown .dropdown-toggle::after {
 content: "+";
 border: none;
 vertical-align: middle;
 transition: all 0.2s ease;
}

.inside-hero-nine .share-dropdown .dropdown-toggle.show::after {
 transform: rotate(45deg);
}

.inside-hero-nine .share-dropdown .dropdown-menu {
 min-width: 120px;
 background: #fff;
 border: none;
 border-radius: 5px;
 padding: 10px;
 box-shadow: 0px 25px 50px rgba(213, 216, 223, 0.5);
}

.inside-hero-nine .share-dropdown .dropdown-menu .social-icon a {
 width: 26px;
 height: 26px;
 border-radius: 5px;
 background: #212121;
 color: #fff;
 line-height: 26px;
 text-align: center;
 font-size: 15px;
 transition: all 0.2s ease;
}

.inside-hero-nine .share-dropdown .dropdown-menu .social-icon a:hover {
 background: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ Product Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.shop-filter-one .selectize-input {
 min-width: 170px;
 line-height: 45px;
 border-radius: 3px;
 border: 1px solid #e1e1e1;
 box-shadow: none;
 outline: none;
 background: transparent !important;
 padding: 0 15px 0 15px;
}

.shop-filter-one .selectize-input input,
.shop-filter-one .selectize-input .item {
 font-size: 15px;
 color: #4f4f4f;
}

.shop-filter-one .selectize-dropdown {
 background: #fff;
 border: 1px solid #e1e1e1;
 border-top: none;
 box-shadow: 0px 50px 100px 0px rgba(229, 232, 235, 0.2);
 cursor: pointer;
}

.shop-filter-one .selectize-dropdown .option {
 font-size: 14px;
 color: #4f4f4f;
 line-height: 26px;
}

.shop-filter-one .selectize-dropdown .option:hover,
.shop-filter-one .selectize-dropdown .active {
 color: #fff;
 background: var(--red-one);
}

.shop-filter-one .selectize-control.single .selectize-input:after {
 right: 8px;
}

/*---Dark Version ---*/
.dark-layout .shop-filter-one .selectize-dropdown {
 background: #212121;
}

.dark-layout .shop-filter-one .selectize-dropdown .option {
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Product Section Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.product-section-five {
 background: var(--light-blue);
 padding: 150px 0 140px;
}

.shop-filter-sidebar {
 background: #fff;
 padding: 38px 0 0;
}

.shop-filter-sidebar .search-area {
 padding: 0 40px 35px;
}

.shop-filter-sidebar .search-area form {
 height: 60px;
 position: relative;
}

.shop-filter-sidebar .search-area input {
 width: 100%;
 height: 100%;
 border-radius: 5px;
 background: #f6f6f6;
 border: none;
 padding: 0 50px 0 20px;
 font-size: 17px;
 font-style: italic;
}

.shop-filter-sidebar .search-area button {
 position: absolute;
 width: 50px;
 right: 0;
 top: 0;
 bottom: 0;
 border-radius: 5px;
 color: var(--heading);
 font-size: 20px;
 transition: all 0.3s ease-in-out;
}

.shop-filter-sidebar .search-area button:hover {
 color: var(--red-one);
}

.shop-filter-accordion .accordion-item {
 border: none;
 border-top: 1px solid #efefef;
 border-radius: 0;
 background: transparent;
}

.shop-filter-accordion .accordion-button {
 font-family: "gorditamedium";
 font-size: 22px;
 padding: 32px 40px;
 box-shadow: none;
 border-radius: 0;
 color: var(--heading);
 background: transparent;
 transition: all 0.3s ease-in-out;
}

.shop-filter-accordion .accordion-button:not(.collapsed) {
 padding-bottom: 20px;
}

.shop-filter-accordion .accordion-body {
 padding: 0 40px 30px;
}

.shop-filter-accordion .accordion-body p {
 font-size: 20px;
 line-height: 1.8em;
 margin-bottom: 0;
}

.shop-filter-accordion .accordion-button::after {
 content: "+";
 font-family: "gorditaregular";
 font-size: 28px;
 background-image: none;
 width: auto;
 height: auto;
}

.shop-filter-accordion .accordion-button:not(.collapsed)::after {
 content: "-";
}

.shop-filter-sidebar .category-filter .dropdown-toggle {
 text-align: left;
 width: 100%;
 color: #060606;
 letter-spacing: -1px;
 line-height: 50px;
 display: block;
 position: relative;
 transition: all 0.3s ease-in-out;
}

.shop-filter-sidebar .category-filter .dropdown-toggle::after {
 display: none;
}

.shop-filter-sidebar .category-filter .dropdown-toggle::before {
 content: "\f282";
 position: absolute;
 margin: 0;
 border: none;
 font-size: 14px;
 right: 0;
 top: 0;
 font-family: "bootstrap-icons";
 font-weight: 700;
 transition: transform 0.3s ease-in-out;
}

.shop-filter-sidebar .category-filter .dropdown-toggle.show::before {
 transform: rotate(180deg);
}

.shop-filter-sidebar .category-filter .dropdown-toggle.show,
.shop-filter-sidebar .category-filter .dropdown-toggle:hover {
 color: var(--red-one);
}

.shop-filter-sidebar .category-filter .dropdown-menu {
 position: static !important;
 border: none;
 transform: scale(1) !important;
 padding: 0 0 5px;
 background: transparent;
}

.shop-filter-sidebar .category-filter .dropdown-menu li {
 font-size: 16px;
 line-height: 31px;
 color: var(--heading);
 padding: 0 0 0 27px;
 background: transparent;
 cursor: pointer;
 transition: all 0.3s ease-in-out;
}

.shop-filter-sidebar .category-filter .dropdown-menu li:hover {
 color: var(--red-one);
 text-decoration: underline;
}

.shop-filter-sidebar .price-filter-input li {
 position: relative;
}

.shop-filter-sidebar .price-filter-input li label {
 position: relative;
 font-size: 16px;
 line-height: 40px;
 padding-left: 25px;
 position: relative;
 color: #000;
 transition: all 0.25s ease-in-out;
}

.shop-filter-sidebar .price-filter-input li label:before {
 content: "";
 width: 15px;
 height: 15px;
 line-height: 13px;
 border-radius: 2px;
 border: 1px solid rgba(0, 0, 0, 0.3);
 font-size: 11px;
 text-align: center;
 position: absolute;
 left: 0;
 top: 12px;
 transition: all 0.1s ease-in-out;
}

.shop-filter-sidebar
 .price-filter-input
 li
 input[type="checkbox"]:checked
 + label:before {
 content: "\f272";
 font-family: bootstrap-icons !important;
 background: var(--red-one);
 color: #fff;
 border-color: var(--red-one);
}

.shop-filter-sidebar .price-filter-input li input[type="checkbox"] {
 position: absolute;
 opacity: 0;
 z-index: 1;
 width: 100%;
 height: 100%;
 left: 0;
 cursor: pointer;
}

.shop-filter-sidebar
 .price-filter-input
 li
 input[type="checkbox"]:checked
 + label {
 color: var(--red-one);
}

.shop-filter-sidebar .color-custome-input li {
 width: 28px;
 height: 28px;
 border-radius: 50%;
 position: relative;
 margin: 0 15px 0 0;
}

.shop-filter-sidebar .color-custome-input li:last-child {
 margin-right: 0;
}

.shop-filter-sidebar .color-custome-input li input[type="radio"] {
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 opacity: 0;
 cursor: pointer;
 z-index: 1;
}

.shop-filter-sidebar .color-custome-input li label {
 position: absolute;
 width: 100%;
 height: 100%;
 border-radius: 50%;
 top: 0;
 left: 0;
}

.shop-filter-sidebar .color-custome-input li label:before {
 content: "";
 position: absolute;
 top: -5px;
 right: -5px;
 bottom: -5px;
 left: -5px;
 border: 2px solid #000;
 border-radius: 50%;
 opacity: 0;
 transition: all 0.25s ease-in-out;
}

.shop-filter-sidebar
 .color-custome-input
 li
 input[type="radio"]:checked
 + label:before {
 opacity: 1;
}

.shop-filter-sidebar .size-custome-input li label {
 font-family: "gorditamedium";
 position: relative;
 width: 32px;
 height: 32px;
 line-height: 30px;
 border: 1px solid #efefef;
 text-align: center;
 font-size: 15px;
 color: #000;
 transition: all 0.25s ease-in-out;
}

.shop-filter-sidebar .size-custome-input li {
 position: relative;
 margin-right: 12px;
}

.shop-filter-sidebar .size-custome-input li:last-child {
 margin-right: 0;
}

.shop-filter-sidebar .size-custome-input li input[type="radio"] {
 position: absolute;
 opacity: 0;
 z-index: 1;
 width: 100%;
 height: 100%;
 left: 0;
 cursor: pointer;
}

.shop-filter-sidebar
 .size-custome-input
 li
 input[type="radio"]:checked
 + label {
 background: var(--red-one);
 border-color: var(--red-one);
 color: #fff;
}

.product-section-five .product-block-three .img-holder {
 height: 330px;
}

.product-section-five .product-block-three {
 padding-bottom: 50px;
}

/*---- Dark Version ----*/
.product-section-five.dark-layout {
 background: #000000;
 color: rgba(255, 255, 255, 0.7);
}

.dark-layout ::-webkit-input-placeholder {
 color: rgba(255, 255, 255, 0.8);
}

.dark-layout :-ms-input-placeholder {
 color: rgba(255, 255, 255, 0.8);
}

.dark-layout ::placeholder {
 color: rgba(255, 255, 255, 0.8);
}

.dark-layout .shop-filter-sidebar {
 background: transparent;
 padding: 0;
}

.dark-layout .shop-filter-sidebar .search-area,
.dark-layout .shop-filter-accordion .accordion-button,
.dark-layout .shop-filter-accordion .accordion-body {
 padding-left: 0;
 padding-right: 0;
}

.dark-layout .shop-filter-sidebar .search-area input {
 background: transparent;
 border: 2px solid #fff;
 color: #fff;
}

.dark-layout .shop-filter-accordion .accordion-item {
 border-top-color: rgba(255, 255, 255, 0.15);
}

.dark-layout .shop-filter-sidebar .category-filter .dropdown-menu li,
.dark-layout .shop-filter-sidebar .price-filter-input li label {
 color: rgba(255, 255, 255, 0.7);
}

.dark-layout .shop-filter-sidebar .price-filter-input li label:before,
.dark-layout .shop-filter-sidebar .color-custome-input li label:before {
 border-color: rgba(255, 255, 255, 0.7);
}

.dark-layout .product-block-three {
 background: rgba(255, 255, 255, 0.09);
}

.dark-layout .product-block-three .img-holder .tag-two {
 background: rgba(255, 255, 255, 0.25);
}

.dark-layout .shop-filter-sidebar .search-area button,
.dark-layout .shop-filter-accordion .accordion-button,
.dark-layout .shop-filter-sidebar .category-filter .dropdown-toggle,
.dark-layout .shop-filter-sidebar .size-custome-input li label,
.dark-layout .shop-filter-one .selectize-input input,
.dark-layout .shop-filter-one .selectize-input .item,
.dark-layout .product-block-three .price {
 color: #fff;
}

.dark-layout .shop-filter-sidebar .category-filter .dropdown-toggle.show,
.dark-layout .shop-filter-sidebar .category-filter .dropdown-toggle:hover,
.dark-layout .shop-filter-sidebar .category-filter .dropdown-menu a:hover,
.dark-layout .product-block-three:hover .product-title {
 color: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ Product Section Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.product-section-six {
 padding: 120px 0 100px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Product Details One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.product-details-one .product-img-tab {
 border: none;
}

.product-details-one .product-img-tab .nav-link {
 width: 100%;
 background: #f6f6f6;
 border: 1px solid transparent;
 border-radius: 0;
 padding: 5px;
 height: 88px;
 margin-bottom: 12px;
}

.product-details-one .product-img-tab .nav-link img {
 max-height: 100%;
}

.product-details-one .product-img-tab .nav-link.active {
 background: #fff;
 border-color: #000;
}

.product-details-one .product-img-tab-content {
 background: #f6f6f6;
}

.product-details-one .product-info .stock-tag {
 display: inline-block;
 font-family: "gorditamedium";
 font-size: 14px;
 text-transform: uppercase;
 letter-spacing: 1px;
 background: #eaeaea;
 border-radius: 3px;
 line-height: 29px;
 padding: 0 13px;
}

.product-details-one .product-info .product-name {
 font-family: "gorditamedium";
 font-size: 42px;
 margin: 30px 0 10px;
}

.product-details-one .product-info .rating li {
 font-size: 13px;
 margin-right: 6px;
 color: #b3b3b3;
}

.product-details-one .product-info .rating li .bi-star-fill {
 color: #ffcb65;
}

.product-details-one .product-info .rating li a {
 font-size: 18px;
 color: #000;
 margin-left: 12px;
}

.product-details-one .product-info .rating li a:hover {
 text-decoration: underline;
}

.product-details-one .product-info .price {
 font-family: "gorditamedium";
 font-size: 28px;
 letter-spacing: -1px;
 color: #000;
 padding: 36px 0 16px;
}

.product-details-one .product-info .availability {
 color: #989ca2;
 letter-spacing: -0.5px;
}

.product-details-one .product-info .description-text {
 line-height: 35px;
 padding: 15px 0 10px;
}

.product-details-one .product-info .product-feature {
 margin-bottom: 20px;
}

.product-details-one .product-info .product-feature li {
 font-size: 20px;
 line-height: 37px;
 position: relative;
 padding-left: 30px;
}

.product-details-one .product-info .product-feature li:before {
 content: url(../images/icon/icon_82.svg);
 position: absolute;
 left: 0;
 top: 0;
}

.product-details-one .product-info .customize-order h6 {
 font-family: "gorditamedium";
 font-size: 18px;
 margin-bottom: 25px;
}

.product-details-one .product-info .quantity .button-group {
 border: 1px solid #e3e3e3;
 display: inline-block;
}

.product-details-one .product-info .quantity ul li {
 line-height: 40px;
 max-height: 40px;
}

.product-details-one .product-info .quantity ul li button {
 font-size: 25px;
 color: #c9c9c9;
 background: transparent;
 width: 32px;
 transition: 0.3s;

 &:hover {
  color: #aaa;
 }
}

.product-details-one .product-info .quantity ul .product-value {
 font-size: 18px;
 height: 40px;
 color: #313131;
 max-width: 45px;
 background: transparent;
 border: none;
 text-align: center;
 padding-left: 0;
}

.product-details-one .product-info .color-custome-input li {
 width: 16px;
 height: 16px;
 border-radius: 50%;
 position: relative;
 margin: 10px 10px 0 0;
}

.product-details-one .product-info .color-custome-input li:last-child {
 margin-right: 0;
}

.product-details-one .product-info .color-custome-input li input[type="radio"] {
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 opacity: 0;
 cursor: pointer;
 z-index: 2;
}

.product-details-one .product-info .color-custome-input li label {
 position: absolute;
 width: 100%;
 height: 100%;
 border-radius: 50%;
 top: 0;
 left: 0;
}

.product-details-one .product-info .color-custome-input li label:before {
 content: "";
 position: absolute;
 top: 4px;
 right: 4px;
 bottom: 4px;
 left: 4px;
 background: #fff;
 z-index: 1;
 border-radius: 50%;
 opacity: 0;
 transition: all 0.25s ease-in-out;
}

.product-details-one
 .product-info
 .color-custome-input
 li
 input[type="radio"]:checked
 + label:before {
 opacity: 1;
}

.product-details-one .product-info .size-custome-input li label {
 position: relative;
 width: 40px;
 height: 40px;
 line-height: 38px;
 border: 1px solid #e3e3e3;
 text-align: center;
 font-size: 16px;
 color: #b1b1b1;
 transition: all 0.25s ease-in-out;
}

.product-details-one .product-info .size-custome-input li {
 position: relative;
 margin-right: 12px;
}

.product-details-one .product-info .size-custome-input li:last-child {
 margin-right: 0;
}

.product-details-one .product-info .size-custome-input li input[type="radio"] {
 position: absolute;
 opacity: 0;
 z-index: 1;
 width: 100%;
 height: 100%;
 left: 0;
 cursor: pointer;
}

.product-details-one
 .product-info
 .size-custome-input
 li
 input[type="radio"]:checked
 + label {
 border-color: #000;
 color: #000;
}

.product-details-one .product-review-tab .nav-tabs {
 border-bottom: 1px solid #ebebeb;
}

.product-details-one .product-review-tab .nav-tabs .nav-link {
 font-family: "gorditamedium";
 font-size: 16px;
 text-transform: uppercase;
 color: #000;
 letter-spacing: 1px;
 padding: 0 0 25px 0;
 position: relative;
 margin: 0 55px 0 0;
 border: none;
 border-radius: 0;
}

.product-details-one .product-review-tab .nav-tabs .nav-link:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 2px;
 left: 0;
 bottom: -1px;
 z-index: 1;
 background: #000;
 transform: scale(0, 1);
 transition: all 0.3s ease;
}

.product-details-one .product-review-tab .nav-tabs .nav-link.active:before {
 transform: scale(1);
}

.product-details-one
 .product-review-tab
 .nav-tabs
 .nav-item:last-child
 .nav-link {
 margin-right: 0;
}

.product-details-one .product-review-tab .tab-content h5 {
 font-family: "gorditamedium";
 font-size: 20px;
 letter-spacing: -1px;
 margin-bottom: 18px;
}

.product-details-one .product-review-tab .tab-content p {
 color: rgba(0, 0, 0, 0.7);
 line-height: 37px;
}

.product-details-one .product-review-tab .tab-content .product-feature li {
 color: rgba(0, 0, 0, 0.7);
 position: relative;
 padding-left: 30px;
 margin-bottom: 20px;
}

.product-details-one
 .product-review-tab
 .tab-content
 .product-feature
 li:before {
 content: url(../images/icon/icon_83.svg);
 position: absolute;
 left: 0;
 top: 0;
}

.product-details-one .user-comment-area {
 padding-right: 200px;
}

.product-details-one .user-comment-area .single-comment {
 padding-bottom: 40px;
}

.product-details-one .user-comment-area .single-comment:last-child {
 padding-bottom: 0;
}

.product-details-one .user-comment-area .user-img {
 width: 60px;
 height: 60px;
 border-radius: 50%;
}

.product-details-one .user-comment-area .user-comment-data {
 padding-left: 30px;
 position: relative;
}

.product-details-one .user-comment-area .user-comment-data .name {
 font-family: "gorditamedium";
 font-size: 18px;
 margin-bottom: 4px;
}

.product-details-one .user-comment-area .rating li {
 font-size: 14px;
 margin: 0 3px;
 color: #b3b3b3;
}

.product-details-one .user-comment-area .rating li .bi-star-fill {
 color: #ffcb65;
}

.product-details-one .user-comment-area .user-comment-data p {
 padding-top: 8px;
 line-height: 33px;
}

.card-style-ten .icon {
 width: 65px;
 height: 65px;
 background: #161616;
 border-radius: 50%;
}

.card-style-ten:hover .icon img {
 transform: rotate(360deg);
}

.card-style-ten h4 {
 font-family: "gorditamedium";
 font-size: 24px;
 line-height: 1.37em;
 letter-spacing: -1px;
 margin-top: 40px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Cart Page ^^^^^^^^^^^^^^^^^^^^^^^^*/
.cart-list-form {
 position: relative;
}

.cart-list-form table {
 margin: 0;
}

.cart-list-form .table th {
 font-family: "gorditamedium";
 font-size: 15px;
 letter-spacing: 2px;
 border: none;
 border-bottom: 2px solid #000;
 padding: 0 0 32px;
 text-align: center;
 text-transform: uppercase;
 color: #1d1d1d;
}

.cart-list-form .table th:first-child {
 text-align: left;
}

.cart-list-form .table tbody td {
 padding: 0 0 70px;
 border: none;
 vertical-align: middle;
 text-align: center;
}

.cart-list-form .table tbody tr:first-child td {
 padding-top: 60px;
}

.cart-list-form .table tbody tr:last-child td {
 padding-bottom: 40px;
}

.cart-list-form .table .product-thumbnails {
 width: 85px;
}

.cart-list-form .table .product-thumbnails img {
 max-height: 100%;
}

.cart-list-form .table .product-img {
 display: block;
 max-width: 85px;
 max-height: 85px;
 overflow: hidden;
}

.cart-list-form .table .product-info {
 padding-left: 30px;
 text-align: left;
}

.cart-list-form .table .product-info .product-name {
 font-family: "gorditamedium";
 letter-spacing: -0.5px;
 font-size: 20px;
 color: #1f1f1f;
}

.cart-list-form .table .product-info .serial {
 font-size: 15px;
 color: rgba(31, 31, 31, 0.33);
 padding-bottom: 6px;
}

.cart-list-form .table .product-info ul li {
 font-family: "gorditamedium";
 display: inline-block;
 font-size: 15px;
 color: #404040;
 padding-right: 15px;
}

.cart-list-form .table .price {
 font-size: 20px;
 color: #1d1d1d;
}

.cart-list-form .table .quantity li {
 display: inline-block;
 line-height: 40px;
 max-height: 40px;
}

.cart-list-form .table .quantity li button {
 font-size: 24px;
 padding: 0;
 border: none;
 vertical-align: inherit;
 color: #1d1d1d;
 background: transparent;
}

.cart-list-form .table .quantity .product-value {
 font-size: 20px;
 color: #1d1d1d;
 width: 55px;
 background: transparent;
 border: none;
 text-align: center;
}

.cart-list-form .table .remove-product {
 color: #d6d6d6;
 font-size: 22px;
}

.cart-list-form .table .remove-product:hover {
 color: #000;
}

.cart-section .cart-footer {
 border-top: 2px solid #545454;
 margin-top: 28px;
 padding-top: 40px;
}

.cart-section .cart-footer .coupon-form input {
 width: 240px;
 height: 50px;
 border: none;
 border-bottom: 2px solid #545454;
 font-size: 16px;
 margin-right: 30px;
}

.cart-section .cart-footer .coupon-form ::placeholder {
 color: #c4c4c4;
 opacity: 1;
 /* Firefox */
}

.cart-section .cart-footer .coupon-form :-ms-input-placeholder {
 color: #c4c4c4;
}

.cart-section .cart-footer .coupon-form ::-ms-input-placeholder {
 color: #c4c4c4;
}

.cart-section .cart-footer .cart-total-section {
 text-align: right;
}

.cart-section .cart-footer .cart-total-table tr th {
 font-size: 18px;
 color: rgba(29, 29, 29, 0.33);
 font-weight: normal;
 padding-right: 26px;
}

.cart-section .cart-footer .cart-total-table tr td {
 font-size: 20px;
 color: #1d1d1d;
}

.cart-section .cart-footer .cart-total-table tr th,
.cart-section .cart-footer .cart-total-table tr td {
 padding-bottom: 16px;
}

.cart-section .cart-footer .theme-btn-seven {
 line-height: 50px;
 padding: 0 35px;
}

.cart-section .cart-footer .cart-total-section {
 padding-right: 82px;
}

/*^^^^^^^^^^^^^^^^^^^^^ CheckOut Page ^^^^^^^^^^^^^^^^^^^^^^^^*/
.checkout-toggle-area p {
 margin-bottom: 8px;
 color: rgba(0, 0, 0, 0.8);
}

.checkout-toggle-area p button {
 font-family: "gorditamedium";
 font-size: 16px;
 letter-spacing: 0px;
 color: #1a1a1a;
 background: transparent;
 display: inline-block;
 text-decoration: underline;
 transition: all 0.3s ease-in-out;
}

.checkout-toggle-area p button:hover {
 color: var(--red-one);
}

.checkout-toggle-area form input {
 width: 100%;
 height: 60px;
 font-size: 16px;
 border: 1px solid #d5d5d5;
 border-radius: 5px;
 padding: 0 30px;
 margin-bottom: 20px;
}

.checkout-toggle-area form input:focus {
 border-color: #777;
}

.checkout-toggle-area form .lost-passw {
 color: #636067;
 font-size: 14px;
 margin: 12px 0 35px;
}

.checkout-toggle-area form .lost-passw:hover {
 color: var(--red-one);
 text-decoration: underline;
}

.checkout-toggle-area form button {
 line-height: 50px;
}

.checkout-toggle-area form p {
 padding-top: 30px;
}

.checkout-form .main-title {
 font-family: "gorditamedium";
 font-size: 24px;
 letter-spacing: -1px;
 padding-bottom: 55px;
}

.checkout-form .single-input-wrapper {
 display: block;
 width: 100%;
 height: 46px;
 font-size: 16px;
 color: #1a1a1a;
 border: none;
 letter-spacing: 0px;
 border-bottom: 2px solid #e5e5e5;
 margin-bottom: 55px;
}

.checkout-form ::placeholder {
 color: #1a1a1a;
 opacity: 1;
 font-family: "gorditamedium";
}

.checkout-form :-ms-input-placeholder {
 color: #1a1a1a;
 font-family: "gorditamedium";
}

.checkout-form ::-ms-input-placeholder {
 color: #1a1a1a;
 font-family: "gorditamedium";
}

.checkout-form .single-input-wrapper:focus {
 border-bottom-color: #545454;
}

.checkout-form .form-select {
 width: 100%;
 line-height: 44px;
 border-radius: 0;
 border: none;
 border-bottom: 2px solid #e5e5e5;
 box-shadow: none;
 outline: none;
 padding: 0 15px 0 0;
 margin-bottom: 55px;
}

.checkout-form .selectize-control {
 margin-bottom: 60px;
}

.checkout-form .form-select,
.checkout-form .selectize-input .item {
 font-size: 16px;
 color: #1a1a1a;
}

.checkout-form .selectize-dropdown {
 background: #fff;
 border: 1px solid #e9e9e9;
 border-top: none;
 box-shadow: 0px 50px 100px 0px rgba(229, 232, 235, 0.2);
 cursor: pointer;
}

.checkout-form .selectize-dropdown .option {
 font-size: 14px;
 color: #1a1a1a;
 line-height: 22px;
}

.checkout-form .selectize-dropdown .option:hover,
.checkout-form .selectize-dropdown .active {
 color: #fff;
 background: #1a1a1a;
}

.checkout-form .selectize-control.single .selectize-input:after {
 right: 8px;
}

.checkout-form .checkbox-list li label {
 position: relative;
 font-family: "gorditamedium";
 font-size: 15px;
 letter-spacing: 0px;
 line-height: 15px;
 padding-left: 28px;
 color: #1a1a1a;
 cursor: pointer;
 margin: 0 0 24px;
}

.checkout-form .checkbox-list li input[type="checkbox"] {
 display: none;
}

.checkout-form .checkbox-list li label:before {
 content: "";
 width: 15px;
 height: 15px;
 line-height: 15px;
 border-radius: 2px;
 border: 1px solid #d5d5d5;
 font-size: 12px;
 text-align: center;
 position: absolute;
 left: 0;
 top: -1px;
}

.checkout-form .checkbox-list li input[type="checkbox"]:checked + label:before {
 content: "\f272";
 font-family: bootstrap-icons !important;
 background: #373737;
 color: #fff;
 border-color: #373737;
}

.checkout-form .checkbox-list {
 padding-bottom: 44px;
 margin-top: -24px;
}

.checkout-form .other-note-area p {
 font-family: "gorditamedium";
 font-size: 15px;
 color: #1a1a1a;
 margin-bottom: 6px;
}

.checkout-form .other-note-area textarea {
 width: 100%;
 border: 1px solid #e5e5e5;
 padding: 15px;
 resize: none;
 height: 145px;
}

.checkout-form .order-confirm-sheet .order-review {
 background: #f5f5f5;
 padding: 50px 40px;
}

.checkout-form .order-confirm-sheet .order-review .product-review {
 width: 100%;
}

.checkout-form
 .order-confirm-sheet
 .order-review
 .product-review
 tbody
 th
 span {
 font-family: "gorditamedium";
 font-size: 16px;
 color: #242424;
 font-weight: normal;
}

.checkout-form
 .order-confirm-sheet
 .order-review
 .product-review
 tbody
 tr:first-child
 th
 span {
 font-size: 18px;
}

.checkout-form .order-confirm-sheet .order-review .product-review tbody td {
 font-size: 17px;
 color: #1d1d1d;
 text-align: right;
}

.checkout-form .order-confirm-sheet .order-review .product-review tbody th,
.checkout-form .order-confirm-sheet .order-review .product-review tbody td {
 padding-bottom: 15px;
}

.checkout-form .order-confirm-sheet .order-review .product-review tfoot th {
 font-size: 16px;
 text-transform: uppercase;
 color: #242424;
 font-weight: 600;
}

.checkout-form .order-confirm-sheet .order-review .product-review tfoot td {
 text-align: right;
 font-size: 20px;
 color: #222222;
}

.checkout-form .order-confirm-sheet .order-review .product-review tfoot td,
.checkout-form .order-confirm-sheet .order-review .product-review tfoot th {
 border-top: 1px solid #e9e9e9;
 padding-top: 15px;
}

.checkout-form .order-confirm-sheet .order-review .payment-list li {
 padding: 0 0 12px 30px;
 position: relative;
}

.checkout-form .order-confirm-sheet .order-review .payment-list li p {
 font-size: 15px;
 line-height: 22px;
 margin-bottom: 12px;
}

.checkout-form .order-confirm-sheet .order-review .payment-list li label {
 position: relative;
 font-family: "gorditamedium";
 font-size: 18px;
 line-height: 15px;
 color: #1a1a1a;
 cursor: pointer;
 margin: 0 0 13px;
}

.checkout-form
 .order-confirm-sheet
 .order-review
 .payment-list
 li
 input[type="radio"] {
 position: absolute;
 opacity: 0;
 z-index: 1;
 width: 100%;
 height: 100%;
 cursor: pointer;
}

.checkout-form
 .order-confirm-sheet
 .order-review
 .payment-list
 li
 label:before {
 content: "";
 width: 15px;
 height: 15px;
 line-height: 14px;
 font-weight: 700;
 border-radius: 50%;
 border: 1px solid #d5d5d5;
 font-size: 10px;
 text-align: center;
 position: absolute;
 left: -30px;
 top: -1px;
}

.checkout-form
 .order-confirm-sheet
 .order-review
 .payment-list
 li
 input:checked
 + label:before {
 content: "\f272";
 font-family: bootstrap-icons !important;
 background: #373737;
 color: #fff;
 border-color: #373737;
}

.checkout-form .order-confirm-sheet .order-review .payment-list {
 padding: 30px 0 15px;
 border-bottom: 1px solid #e9e9e9;
}

.checkout-form .credit-card-form {
 margin-top: 12px;
 display: none;

 &.active {
  display: block;
 }
}

.checkout-form .credit-card-form h6 {
 font-size: 15px;
 margin-bottom: 5px;
}

.checkout-form .credit-card-form input {
 width: 100%;
 height: 40px;
 font-size: 14px;
 border: 1px solid rgba(0, 0, 0, 0.07);
 padding: 0 10px;
 border-radius: 3px;
 margin-bottom: 18px;
}

.checkout-form .credit-card-form span {
 padding: 0 5px;
 margin-bottom: 18px;
}

.checkout-form .order-confirm-sheet .policy-text {
 font-size: 15px;
 line-height: 22px;
 color: #979797;
 padding: 23px 0 4px;
}

.checkout-form .order-confirm-sheet .agreement-checkbox label {
 position: relative;
 font-size: 15px;
 line-height: 22px;
 color: #1a1a1a;
 cursor: pointer;
 padding-left: 33px;
 margin-bottom: 35px;
}

.checkout-form .order-confirm-sheet .agreement-checkbox input[type="checkbox"] {
 display: none;
}

.checkout-form .order-confirm-sheet .agreement-checkbox label:before {
 content: "";
 width: 15px;
 height: 15px;
 line-height: 14px;
 border-radius: 2px;
 border: 1px solid #d5d5d5;
 font-size: 10px;
 font-weight: 700;
 text-align: center;
 position: absolute;
 left: 0;
 top: 7px;
}

.checkout-form
 .order-confirm-sheet
 .agreement-checkbox
 input[type="checkbox"]:checked
 + label:before {
 content: "\f272";
 font-family: bootstrap-icons !important;
 background: #373737;
 color: #fff;
 border-color: #373737;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-nine {
 position: relative;
}

.hero-banner-nine .social-elemnet {
 position: absolute;
 z-index: 9;
 left: 10px;
 top: 47%;
 transform: rotate(-90deg) translate(0, 0);
}

.hero-banner-nine .social-elemnet li {
 font-family: "gorditamedium";
 color: #fff;
 margin: 0 5px;
}

.hero-banner-nine .social-elemnet li a:hover {
 color: var(--red-one);
}

.hero-banner-nine .scroll-bar {
 width: 3px;
 height: 100px;
 background: #fff;
 position: absolute;
 top: calc(50% - 45px);
 right: 65px;
 transform: translateY(-50%);
 z-index: 9;
}

.hero-banner-nine .scroll-bar:before {
 content: "";
 position: absolute;
 height: 60%;
 width: 100%;
 left: 0;
 top: 0;
 background: var(--red-one);
}

.hero-banner-nine .scroll-bar span {
 position: absolute;
 font-family: "gorditamedium";
 text-transform: uppercase;
 letter-spacing: 2px;
 font-size: 14px;
 transform: rotate(-90deg);
 top: 137%;
 left: -34px;
 color: #fff;
}

.hero_slider_three .bg-wrapper {
 background-size: cover;
 background-repeat: no-repeat;
 background-position: center;
 min-height: 100vh;
 padding: 150px 0;
}

.hero-banner-nine .hero-heading {
 font-size: 120px;
 line-height: 0.91em;
 letter-spacing: -0.5px;
 color: #fff;
}

.hero-banner-nine .hero-heading span {
 color: var(--red-one);
 position: relative;
 display: inline-block;
}

.hero-banner-nine .hero-heading span:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 4px;
 background: var(--red-one);
 left: 0;
 bottom: 2px;
}

.hero-banner-nine .hero-sub-heading {
 color: #fff;
 padding: 40px 0 50px;
}

.hero-banner-nine .video-icon {
 width: 75px;
 height: 75px;
 margin: 0 auto;
 border-radius: 50%;
 background: var(--red-one);
 padding: 19px 15px 18px 13px;
 position: relative;
 animation: ripple 3s linear infinite;
}

.hero-banner-nine .video-icon::before,
.hero-banner-nine .video-icon::after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 border-radius: 50%;
 animation: inherit;
 animation-delay: 1s;
}

.hero-banner-nine .video-icon::after {
 animation-delay: 3s;
}

.hero-banner-nine .section-no {
 font-family: "gorditamedium";
 font-size: 20px;
 color: #fff;
 letter-spacing: 3px;
 transform: rotate(-90deg);
 position: absolute;
 bottom: 50px;
 left: 60px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Ten ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-ten {
 background: url(../images/media/img_74.jpg) no-repeat center;
 background-size: cover;
 position: relative;
 min-height: 100vh;
 padding: 220px 0 150px;
}

.hero-banner-ten .hero-heading {
 line-height: 1em;
 color: #fff;
}

.hero-banner-ten .hero-heading span {
 color: var(--red-one);
 position: relative;
 display: inline-block;
}

.hero-banner-ten .hero-heading span:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 4px;
 background: var(--red-one);
 left: 0;
 bottom: -6px;
}

.hero-banner-ten .hero-sub-heading {
 color: #fff;
 padding: 23px 0 36px;
 line-height: 32px;
}

.hero-banner-ten .button-group a {
 width: 300px;
 line-height: 52px;
 text-align: center;
 border-radius: 28px;
 transition: all 0.3s ease-in-out;
}

.hero-banner-ten .button-group .btn-one {
 font-family: "gorditamedium";
 color: #fff;
 font-size: 17px;
 letter-spacing: -0.3px;
 background: rgba(255, 255, 255, 0.15);
}

.hero-banner-ten .button-group .btn-one:hover {
 background: var(--red-one);
}

.hero-banner-ten .button-group .btn-two {
 color: var(--red-one);
 font-size: 16px;
 letter-spacing: -0.3px;
}

.hero-banner-ten .button-group .btn-two:hover {
 text-decoration: underline;
}

.form-style-four {
 padding: 45px 50px;
 border-radius: 15px;
 background: #fff;
}

.form-style-four h3 {
 font-family: "gorditamedium";
 font-size: 38px;
 line-height: 1.26em;
 letter-spacing: -1px;
 text-align: center;
 margin-bottom: 24px;
}

.form-style-four label {
 font-size: 14px;
 color: rgba(0, 0, 0, 0.5);
 margin-bottom: 5px;
}

.form-style-four .input-group-meta input {
 display: block;
 width: 100%;
 height: 60px;
 max-width: 100%;
 border: 2px solid var(--heading);
 border-radius: 8px;
 padding: 0 20px;
 font-size: 17px;
}

.form-style-four .agreement-checkbox label {
 position: relative;
 font-size: 15px;
 color: #a9a9a9;
 cursor: pointer;
 padding-left: 22px;
 line-height: 18px;
 transition: all 0.1s ease-in-out;
}

.form-style-four .agreement-checkbox label a {
 color: #000;
 text-decoration: underline;
}

.form-style-four .agreement-checkbox input[type="checkbox"] {
 display: none;
}

.form-style-four .agreement-checkbox label:before {
 content: "";
 width: 13px;
 height: 13px;
 line-height: 11px;
 border-radius: 2px;
 border: 1px solid #c9c9c9;
 font-size: 10px;
 font-weight: 700;
 text-align: center;
 position: absolute;
 left: 0;
 top: 3px;
 transition: all 0.1s ease-in-out;
}

.form-style-four
 .agreement-checkbox
 input[type="checkbox"]:checked
 + label:before {
 content: "\f272";
 font-family: bootstrap-icons !important;
 background: #000;
 color: #fff;
 border-color: #000;
}

.form-style-four form .theme-btn-one {
 font-size: 14px;
 text-transform: uppercase;
 letter-spacing: 0.88px;
 color: #000;
 width: 100%;
 background: #fdd395;
}

.form-style-four form .theme-btn-one:hover {
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Eleven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.card-style-eleven .icon {
 width: 55px;
 height: 55px;
 margin: 0 auto 27px;
 border: 1px solid var(--red-one);
 border-radius: 50%;
 transition: all 0.3s ease-in-out;
}

.card-style-eleven p {
 font-size: 17px;
 margin: 22px 0 0;
}

.card-style-eleven:hover .icon {
 box-shadow: 0 0 35px rgba(0, 0, 0, 0.09);
 border-color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Subscribe Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.subscribe-section-one .title {
 font-size: 16px;
 text-transform: uppercase;
 color: rgba(0, 0, 0, 0.4);
 letter-spacing: 2px;
 margin-bottom: 40px;
}

.subscribe-section-one .subscribe-form-one form {
 position: relative;
 height: 66px;
 max-width: 540px;
}

.subscribe-section-one .subscribe-form-one form input {
 width: 100%;
 height: 100%;
 border: 2px solid var(--heading);
 font-size: 16px;
 padding: 0 150px 0 20px;
 border-radius: 7px;
 background: transparent;
}

.subscribe-section-one .subscribe-form-one form button {
 font-family: "gorditamedium";
 position: absolute;
 width: 135px;
 right: 0;
 top: 0;
 bottom: 0;
 border-left: 2px solid var(--heading);
 border-radius: 0 7px 7px 0;
 color: #000;
 transition: all 0.3s ease-in-out;
}

.subscribe-section-one .subscribe-form-one form button img {
 vertical-align: baseline;
}

.subscribe-section-one .subscribe-form-one p {
 font-size: 15px;
 color: #969696;
 padding-top: 15px;
 margin-bottom: 0;
}

.subscribe-section-one .subscribe-form-one p a {
 color: var(--heading);
}

.subscribe-section-one .subscribe-form-one p a:hover {
 text-decoration: underline;
}

.subscribe-section-one .subscribe-form-two p {
 font-size: 20px;
 color: var(--heading);
 padding: 0 0 25px;
}

.subscribe-section-one .subscribe-form-two p span {
 text-decoration: underline;
 color: var(--red-one);
}

.subscribe-section-one .subscribe-form-two form {
 height: 70px;
 position: relative;
 margin-bottom: 12px;
 max-width: 540px;
}

.subscribe-section-one .subscribe-form-two form input {
 width: 100%;
 height: 100%;
 border: none;
 background: #eeeeee;
 border-radius: 8px;
 padding: 0 125px 0 20px;
 font-size: 16px;
 color: var(--heading);
}

.subscribe-section-one .subscribe-form-two form ::placeholder {
 color: rgba(0, 0, 0, 0.5);
 opacity: 1;
 /* Firefox */
}

.subscribe-section-one .subscribe-form-two form :-ms-input-placeholder {
 color: rgba(0, 0, 0, 0.5);
}

.subscribe-section-one .subscribe-form-two form ::-ms-input-placeholder {
 color: rgba(0, 0, 0, 0.5);
}

.subscribe-section-one .subscribe-form-two form button {
 position: absolute;
 font-size: 16px;
 font-family: "gorditamedium";
 top: 8px;
 right: 8px;
 bottom: 8px;
 width: 115px;
 border-radius: 8px;
 background: var(--red-one);
 color: #fff;
 transition: all 0.3s ease-in-out;
}

.subscribe-section-one .subscribe-form-two form button.dark-btn {
 background: var(--heading);
}

.subscribe-section-one .subscribe-form-two form button.dark-btn:hover {
 background: var(--red-two);
}

.subscribe-section-one .subscribe-form-two .info {
 font-size: 14px;
 color: #979797;
}

/*^^^^^^^^^^^^^^^^^^^^^ Subscribe Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.subscribe-section-two {
 background: var(--light-cyan);
 position: relative;
}

.subscribe-section-two form {
 position: relative;
 height: 70px;
 max-width: 580px;
 margin: auto;
}

.subscribe-section-two form input {
 width: 100%;
 height: 100%;
 border: 2px solid var(--heading);
 font-size: 16px;
 padding: 0 150px 0 30px;
 border-radius: 35px;
 background: #fff;
}

.subscribe-section-two form button {
 position: absolute;
 width: 140px;
 background: var(--heading);
 right: 0;
 top: 0;
 bottom: 0;
 border-radius: 0 35px 35px 0;
 color: #fff;
 transition: all 0.3s ease-in-out;
}

.subscribe-section-two form button img {
 vertical-align: baseline;
}

/*^^^^^^^^^^^^^^^^^^^^^ Subscribe Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.subscribe-section-three {
 border: 2px solid #151515;
 border-radius: 28px;
}

.subscribe-section-three .subscribe-form form {
 height: 75px;
 position: relative;
 margin-bottom: 12px;
 max-width: 510px;
 margin-left: auto;
}

.subscribe-section-three .subscribe-form form input {
 width: 100%;
 height: 100%;
 border: none;
 background: #eeeeee;
 border-radius: 8px;
 padding: 0 150px 0 20px;
 font-size: 16px;
 color: var(--heading);
}

.subscribe-section-three .subscribe-form form ::placeholder {
 color: rgba(0, 0, 0, 0.5);
 opacity: 1;
 /* Firefox */
}

.subscribe-section-three .subscribe-form form :-ms-input-placeholder {
 color: rgba(0, 0, 0, 0.5);
}

.subscribe-section-three .subscribe-form form ::-ms-input-placeholder {
 color: rgba(0, 0, 0, 0.5);
}

.subscribe-section-three .subscribe-form form button {
 position: absolute;
 font-size: 16px;
 font-family: "gorditamedium";
 top: 8px;
 right: 8px;
 bottom: 8px;
 width: 140px;
 border-radius: 8px;
 background: #251f21;
 color: #fff;
 transition: all 0.3s ease-in-out;
}

.subscribe-section-three .subscribe-form form button:hover {
 background: var(--red-one);
}

.subscribe-section-three .subscribe-form p {
 text-align: right;
 font-size: 15px;
 color: #969696;
 margin-bottom: 0;
}

.subscribe-section-three .subscribe-form p a {
 color: var(--heading);
}

.subscribe-section-three .subscribe-form p a:hover {
 text-decoration: underline;
}

/*^^^^^^^^^^^^^^^^^^^^^ Case Study Section Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.case-study-six {
 padding: 90px 0;
 max-width: 2000px;
 overflow: hidden;
}

.case-study-six .slider-wrapper {
 margin: 0 -20vw;
}

.case_slider_two {
 margin: 0 -25px;
}

.case_slider_two .item {
 padding: 0 25px;
}

.case-block-five .img-holder {
 border-radius: 32px;
 overflow: hidden;
 position: relative;
 margin-bottom: 36px;
}

.case-block-five .img-holder:before {
 content: "";
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 background: rgba(0, 0, 0, 0.4);
 position: absolute;
 z-index: 1;
 transition: all 0.3s ease-in-out;
}

.case-block-five .text {
 opacity: 0;
 transition: all 0.3s ease-in-out;
}

.case-block-five .text .tag {
 font-family: "gorditamedium";
 font-size: 12px;
 text-transform: uppercase;
 letter-spacing: 4px;
 color: var(--red-one);
 margin-bottom: 12px;
}

.case-block-five .text a {
 font-size: 36px;
 line-height: 1.27em;
 color: #fff;
 letter-spacing: -1px;
 max-width: 600px;
}

.case-block-five .text a:hover {
 text-decoration: underline;
}

.slick-center .case-block-five .img-holder:before {
 opacity: 0;
}

.slick-center .case-block-five .text {
 opacity: 1;
}

.case-study-six .slider-arrows li {
 width: 50px;
 height: 50px;
 border-radius: 50%;
 text-align: center;
 line-height: 50px;
 color: #fff;
 font-size: 28px;
 margin-left: 8px;
 cursor: pointer;
 transform: scale(0.8);
 transition: all 0.3s ease-in-out;
}

.case-study-six .slider-arrows li:hover {
 background: var(--red-one);
 transform: scale(1);
}

/*^^^^^^^^^^^^^^^^^^^^^ Case Study Section Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/

.case-block-six {
 overflow: hidden;
 position: relative;
}

.case-block-six .title a {
 font-size: 30px;
 font-weight: 500;
 color: #fff;
 text-transform: uppercase;
 margin-bottom: 15px;
 transition: all 0.3s ease-in-out;
}

.case-block-six .title span {
 text-decoration: underline;
}

.case-block-six .hover-valina {
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background: rgba(0, 0, 0, 0.7);
 padding: 30px;
 opacity: 0;
 transition: all 0.3s ease-in-out;
}

.case-block-six:hover .hover-valina {
 opacity: 1;
}

.case-block-six .hover-valina:before,
.case-block-six .hover-valina:after {
 content: "";
 position: absolute;
 top: 30px;
 right: 30px;
 bottom: 30px;
 left: 30px;
 opacity: 0;
 transition: all 0.4s ease-in-out;
}

.case-block-six .hover-valina:before {
 border-top: 1px solid #fff;
 border-bottom: 1px solid #fff;
 -webkit-transform: scale(0, 1);
 transform: scale(0, 1);
}

.case-block-six .hover-valina:after {
 border-right: 1px solid #fff;
 border-left: 1px solid #fff;
 -webkit-transform: scale(1, 0);
 transform: scale(1, 0);
}

.case-block-six:hover .hover-valina:before,
.case-block-six:hover .hover-valina:after {
 opacity: 1;
 -webkit-transform: scale(1);
 transform: scale(1);
}

.case-block-six .hover-valina > div {
 text-align: center;
 position: relative;
 z-index: 1;
 top: 50%;
 transform: translateY(-50%);
}

.case-block-six .hover-valina .title a {
 opacity: 0;
 -webkit-transform: translate3d(0, -20px, 0);
 transform: translate3d(0, -20px, 0);
}

.case-block-six .hover-valina p {
 opacity: 0;
 font-size: 16px;
 color: #fff;
 padding-bottom: 15px;
 -webkit-transform: translate3d(0, 20px, 0);
 transform: translate3d(0, 20px, 0);
 transition: all 0.3s ease-in-out;
 transition-delay: 0.15s;
}

.case-block-six .hover-valina .zoom {
 font-size: 22px;
 color: #fff;
 opacity: 0;
 -webkit-transform: translate3d(0, 20px, 0);
 transform: translate3d(0, 20px, 0);
 transition: all 0.3s ease-in-out;
 transition-delay: 0.2s;
}

.case-block-six:hover .hover-valina .title a,
.case-block-six:hover .hover-valina p,
.case-block-six:hover .hover-valina .zoom {
 opacity: 1;
 -webkit-transform: translate3d(0, 0, 0);
 transform: translate3d(0, 0, 0);
}

.case-block-six .img-box img {
 width: 100%;
 transition: all 1s ease-in-out;
}

.case-block-six:hover .img-box > img {
 -webkit-transform: scale3d(1.1, 1.1, 1);
 transform: scale3d(1.1, 1.1, 1);
}

/*^^^^^^^^^^^^^^^^^^^^^ Form Section One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.form-section-one .form-style-five {
 border: 1px solid #ededed;
 border-radius: 20px;
 padding: 48px 0 50px;
}

.form-style-five .input-group-meta label {
 font-size: 14px;
 color: rgba(0, 0, 0, 0.5);
 margin-bottom: 5px;
}

.form-style-five .input-group-meta input {
 display: block;
 width: 100%;
 height: 60px;
 max-width: 100%;
 border: 2px solid var(--heading);
 border-radius: 10px;
 padding: 0 20px 0 55px;
 font-size: 17px;
}

.form-style-five .input-group-meta .icon-placeholder {
 position: absolute;
 left: 25px;
 bottom: 19px;
}

.form-style-five .agreement-checkbox label {
 position: relative;
 font-size: 15px;
 color: var(--heading);
 cursor: pointer;
 letter-spacing: -0.5px;
 padding-left: 22px;
 line-height: 18px;
 text-align: left;
 transition: all 0.1s ease-in-out;
}

.form-style-five .agreement-checkbox label a {
 color: var(--p-color);
 text-decoration: underline;
}

.form-style-five .agreement-checkbox input[type="checkbox"] {
 display: none;
}

.form-style-five .agreement-checkbox label:before {
 content: "";
 width: 13px;
 height: 13px;
 line-height: 9px;
 border-radius: 2px;
 border: 2px solid rgba(0, 0, 0, 0.3);
 font-size: 10px;
 font-weight: 700;
 text-align: center;
 position: absolute;
 left: 0;
 top: 3px;
 transition: all 0.1s ease-in-out;
}

.form-style-five
 .agreement-checkbox
 input[type="checkbox"]:checked
 + label:before {
 content: "\f272";
 font-family: bootstrap-icons !important;
 background: #000;
 color: #fff;
 border-color: #000;
}

/*^^^^^^^^^^^^^^^^^^^^^ Form Section Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.form-section-two {
 background: url(../images/media/img_73.jpg) no-repeat center;
 background-size: cover;
 min-height: 100vh;
 padding: 120px 0;
}

.form-section-two.user-data-page .form-wrapper {
 width: 100%;
 padding: 60px 60px 75px;
 background: #fff;
 border-radius: 20px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Team Section Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.team-block-four .img-meta {
 border-radius: 32px;
 overflow: hidden;
 margin-bottom: 12px;
}

.team-block-four p {
 font-size: 15px;
 color: #b6b6b6;
 letter-spacing: -0.2px;
 margin-bottom: 10px;
}

.team-block-four .name {
 letter-spacing: -0.4px;
}

/*^^^^^^^^^^^^^^^^^^^^^ FAQ Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.faq-search-filter {
 height: 60px;
 position: relative;
 overflow: hidden;
}

.faq-search-filter input {
 width: 100%;
 height: 100%;
 border: 2px solid #000;
 border-radius: 6px;
 font-style: italic;
 padding: 0 70px 0 20px;
}

.faq-search-filter.radius input {
 border-radius: 35px;
 padding-left: 35px;
}

.faq-search-filter button {
 position: absolute;
 top: 0;
 right: 0;
 bottom: 0;
 width: 68px;
 color: #000;
 font-size: 20px;
}

.faq-search-filter button:hover {
 color: var(--red-one);
}

.accordion-style-four .accordion-item {
 border: none;
 border-radius: 0;
}

.accordion-style-four .accordion-button {
 font-family: "gorditamedium";
 font-size: 20px;
 line-height: 1.4em;
 letter-spacing: -0.5px;
 padding: 20px 0 20px 40px;
 box-shadow: none;
 border-radius: 0;
 color: var(--heading);
 background: transparent;
 transition: all 0.3s ease-in-out;
}

.accordion-style-four .accordion-button:not(.collapsed) {
 padding-bottom: 15px;
}

.accordion-style-four .accordion-body {
 padding: 0 10px 0 40px;
}

.accordion-style-four .accordion-body p {
 font-size: 17px;
 line-height: 1.8em;
}

.accordion-style-four .accordion-button::after {
 content: "+";
 position: absolute;
 left: 0;
 top: 19px;
 font-family: "gorditaregular";
 font-size: 28px;
 background-image: none;
 width: auto;
 height: auto;
}

.accordion-style-four .accordion-button:not(.collapsed)::after {
 content: "-";
 color: var(--red-one);
}

.faq-section-three .request-faq h2 {
 font-family: "gorditamedium";
 font-size: 36px;
 margin-bottom: 25px;
}

/*^^^^^^^^^^^^^^^^^^^^^ FAQ Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.faq-section-four .bg-wrapper {
 background: var(--light-blue);
}

.faq-section-four .wrapper {
 max-width: 1570px;
 margin: 0 auto;
 padding: 60px 12px 100px;
}

.faq-section-four .faq-block {
 background: #fff;
 border-radius: 10px;
 padding: 40px 45px 10px;
 margin-bottom: 40px;
}

.faq-section-four .faq-block h4 {
 letter-spacing: -0.3px;
 margin-bottom: 32px;
}

.faq-section-four .faq-block p {
 font-size: 17px;
 line-height: 33px;
 margin-bottom: 28px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Thirteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.ui-list-item-one li {
 font-size: 20px;
 color: #000;
 margin-bottom: 47px;
 padding-left: 40px;
 position: relative;
}

.ui-list-item-one li:before {
 content: url(../images/icon/icon_95.svg);
 position: absolute;
 left: 0;
 top: -1px;
}

.vcamp-text-block-thirteen .img-media {
 border-radius: 30px;
 overflow: hidden;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Fourteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.ui-list-item-two li {
 font-size: 18px;
 color: #000;
 margin-bottom: 20px;
 padding-left: 30px;
 position: relative;
}

.ui-list-item-two li:before {
 content: url(../images/icon/icon_96.svg);
 position: absolute;
 left: 0;
 top: -1px;
}

.vcamp-text-block-fourteen .img-wrapper img {
 transform: rotate(4.3deg);
}

.vcamp-text-block-fourteen .img-wrapper:before {
 content: "";
 position: absolute;
 width: 69%;
 height: 98%;
 left: 4%;
 bottom: -6%;
 z-index: -1;
 background: var(--red-one);
 transform: rotate(-6.16deg);
}

.vcamp-text-block-fourteen .img-wrapper {
 margin-bottom: 6%;
 padding-left: 19%;
 padding-right: 10%;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Fifteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.ui-list-item-three li {
 font-size: 20px;
 color: #000;
 margin-bottom: 47px;
 padding-left: 40px;
 position: relative;
}

.dark-bg .ui-list-item-three li {
 color: #fff;
}

.ui-list-item-three li:before {
 content: "\f26e";
 font-family: bootstrap-icons;
 width: 18px;
 height: 18px;
 text-align: center;
 line-height: 18px;
 border-radius: 50%;
 background: var(--red-one);
 position: absolute;
 left: 0;
 top: 4px;
 font-size: 18px;
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Counter Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.counter-block-four .icon {
 width: 45px;
}

.counter-block-four .icon img {
 margin-left: auto;
}

.counter-block-four .text {
 width: calc(100% - 45px);
 padding-left: 27px;
}

.counter-block-four .text .main-count {
 font-size: 60px;
 line-height: initial;
 margin: -18px 0 2px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Counter Section Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.counter-block-five .icon {
 height: 50px;
}

.counter-block-five .icon img {
 max-height: 100%;
}

.counter-block-five .main-count {
 font-size: 60px;
 line-height: initial;
 margin: 22px 0 -5px;
}

.dark-bg .counter-block-five .main-count,
.dark-bg .counter-block-five p {
 color: #fff;
}

.dark-bg .counter-block-five {
 padding: 80px 12px 90px;
 border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-bg [class*="col-"]:last-child .counter-block-five {
 border: none;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Fancy Banner Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-banner-eight form {
 max-width: 630px;
 height: 80px;
 position: relative;
}

.fancy-banner-eight form input {
 width: 100%;
 height: 100%;
 background: #f0f0f0;
 border: none;
 border-radius: 7px;
 padding: 0 200px 0 20px;
}

.fancy-banner-eight form button {
 position: absolute;
 font-family: "gorditamedium";
 width: 200px;
 top: 8px;
 right: 8px;
 bottom: 8px;
 background: var(--heading);
 color: #fff;
 font-size: 17px;
 border-radius: 7px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Footer Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-footer-three {
 padding: 70px 0;
}

.vcamp-footer-three .footer-nav a {
 color: #fff;
 font-size: 18px;
 margin: 3px 1.6vw;
 transition: all 0.3s ease-in-out;
}

.vcamp-footer-three .social-icon a {
 font-size: 18px;
 margin-left: 20px;
 color: #fff;
 transition: all 0.3s ease-in-out;
}

.vcamp-footer-three .footer-nav a:hover,
.vcamp-footer-three .social-icon a:hover {
 color: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ Footer Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-footer-four .inner-content {
 padding: 60px 0;
 position: relative;
}

.vcamp-footer-four .footer-nav {
 margin: 0 -20px;
}

.vcamp-footer-four .footer-nav li > a {
 color: #000;
 font-size: 18px;
 margin: 3px 20px;
 transition: all 0.3s ease-in-out;
}

.vcamp-footer-four .footer-nav .logo {
 margin: auto;
}

.vcamp-footer-four .footer-nav li > a:hover,
.vcamp-footer-four.dark-bg .footer-nav li > a:hover {
 color: var(--red-one);
}

.vcamp-footer-four.dark-bg .footer-nav li > a {
 color: rgba(255, 255, 255, 0.75);
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Twelve ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-feature-section-twelve {
 background: url(../images/media/img_90.jpg) no-repeat center;
 background-size: cover;
 position: relative;
 z-index: 1;
}

.vcamp-feature-section-twelve:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 z-index: -1;
 mix-blend-mode: multiply;
 background-color: #eb0000;
}

.vcamp-feature-section-twelve .card-style-one {
 margin: 0;
 background: #fff;
 padding: 55px 20px 40px 40px;
 height: 100%;
}

.vcamp-feature-section-twelve .card-style-one .icon.style-two {
 border-radius: 50%;
}

.vcamp-feature-section-twelve .card-style-one p {
 padding-right: 0;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Thirteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.card-style-twelve .num {
 font-family: "gorditalight";
 font-size: 60px;
 color: #e1e1e1;
 line-height: initial;
}

.card-style-twelve h4 {
 font-size: 20px;
 font-family: "gorditabold";
 margin: 14px 0 25px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Eleven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-eleven {
 padding: 180px 0 225px;
 position: relative;
 z-index: 1;
}

.hero-banner-eleven .shape-one {
 left: 0;
 bottom: 22%;
}

.hero-banner-eleven .banner-intro {
 max-width: 1515px;
 padding: 0 12px;
 margin: 0 auto;
 position: relative;
}

.hero-banner-eleven .banner-intro .brand-logo {
 position: absolute;
 right: 12px;
 top: 50%;
 transform: translateY(-50%);
}

.hero-banner-eleven .banner-intro .heading {
 max-width: 1008px;
}

.hero-banner-eleven .banner-intro .name {
 font-weight: normal;
 color: #fff;
 font-size: 130px;
}

.hero-banner-eleven .banner-intro h2 {
 font-size: 130px;
 font-weight: 300;
 color: #fff;
 margin: -25px 0 35px;
}

.hero-banner-eleven .banner-intro h2 em {
 font-style: normal;
}

.hero-banner-eleven .banner-intro p {
 color: #fff;
 font-size: 24px;
 letter-spacing: 8px;
 text-transform: uppercase;
 text-align: left;
}

/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.portfolio-gallery-one {
 position: relative;
 z-index: 1;
}

.portfolio-gallery-one .wrapper {
 max-width: 1674px;
 padding: 0 12px;
}

.portfolio-gallery-one .scroll-btn {
 width: 85px;
 height: 85px;
 border: 1px solid rgba(255, 255, 255, 0.15);
 border-radius: 50%;
 margin: 100px auto 0;
 animation: jumpTwo 5s infinite linear;
}

.portfolio-gallery-one .shape-one {
 left: 6%;
 bottom: 14%;
}

.portfolio-gallery-one .scroll-btn:hover {
 background: var(--red-one);
}

.portfolio-gallery-one .img-meta {
 overflow: hidden;
 position: relative;
}

.portfolio-gallery-one .img-meta:before {
 content: "";
 position: absolute;
 background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #121212 100%);
 left: 0;
 right: 0;
 bottom: 0;
 height: 54%;
}

.portfolio-gallery-one .img-meta .hover-content {
 position: absolute;
 bottom: 0;
 left: 0;
 right: 0;
 padding: 40px 25px 30px;
 z-index: 1;
}

.portfolio-gallery-one .img-meta .hover-content span {
 display: block;
 text-transform: capitalize;
 color: rgba(255, 255, 255, 0.7);
 font-size: 15px;
 opacity: 0;
 transform: translateY(5px);
 transition-delay: 0.1s;
}

.portfolio-gallery-one .img-meta .c-name {
 font-size: 28px;
 font-weight: 300;
 color: #fff;
 opacity: 0;
 transform: translateY(5px);
}

.portfolio-gallery-one .img-meta .c-name:hover {
 text-decoration: underline;
}

.portfolio-gallery-one .img-meta:hover .hover-content .c-name,
.portfolio-gallery-one .img-meta:hover .hover-content span {
 opacity: 1;
 transform: translateY(0);
}

.portfolio-gallery-one .img-meta .arrow {
 position: absolute;
 top: 0;
 right: 0;
 width: 50px;
 height: 50px;
 font-size: 24px;
 color: #000;
 border-radius: 0 0 0 25px;
 background: #fff;
 z-index: 1;
 opacity: 0;
 transform: scale(0);
 transform-origin: 100% 0;
}

.portfolio-gallery-one .img-meta .arrow:hover {
 border-radius: 0 0 0 3px;
}

.portfolio-gallery-one .img-meta:hover .arrow {
 opacity: 1;
 transform: scale(1);
}

.portfolio-gallery-one .row [class*="col-"]:nth-child(even) {
 margin-top: 100px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Fifteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-fifteen {
 border-top: 1px solid rgba(255, 255, 255, 0.09);
 border-bottom: 1px solid rgba(255, 255, 255, 0.09);
 padding: 60px 0;
}

.vcamp-text-block-fifteen h2 {
 max-width: 1515px;
 margin: 0 auto;
 padding: 0 12px;
 font-size: 79px;
 font-weight: 300;
 position: relative;
 color: rgba(255, 255, 255, 0.25);
 line-height: 1.35em;
}

.vcamp-text-block-fifteen h2 span {
 color: rgba(255, 255, 255, 0.25);
 display: inline-block;
 position: relative;
 transition: all 0.3s ease-in-out;
}

.vcamp-text-block-fifteen h2 span:hover {
 color: #fff;
 cursor: pointer;
}

.vcamp-text-block-fifteen h2 .hover-img {
 max-width: 235px;
 width: 235px;
 position: absolute;
 left: 50%;
 bottom: 100%;
 z-index: 1;
 opacity: 0;
 visibility: hidden;
 transform: translateX(-50%) translateY(-5px);
 transition: all 0.3s ease-in-out;
}

.vcamp-text-block-fifteen h2 span:hover .hover-img {
 opacity: 1;
 visibility: visible;
 transform: translateX(-50%) translateY(0);
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Sixteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-text-block-sixteen {
 position: relative;
 z-index: 1;
}

.vcamp-text-block-sixteen .shape-two {
 bottom: 0;
 right: 0;
}

.vcamp-text-block-sixteen .quote-wrapper {
 position: relative;
 padding-left: 78px;
 margin-top: 84px;
}

.vcamp-text-block-sixteen .quote-wrapper p {
 font-family: "gorditalight";
 font-size: 32px;
 line-height: 1.68em;
 color: rgba(255, 255, 255, 0.7);
 margin-bottom: 65px;
}

.vcamp-text-block-sixteen .quote-wrapper p i {
 font-family: "gorditaregular";
 color: #fff;
 display: inline-block;
 text-decoration: underline;
}

.vcamp-text-block-sixteen .quote-wrapper .icon {
 position: absolute;
 left: 5px;
 top: 15px;
}

.vcamp-text-block-sixteen .img-wrapper {
 position: relative;
 z-index: 1;
 padding-left: 75px;
}

.vcamp-text-block-sixteen .img-wrapper .img-meta {
 display: inline-block;
 position: relative;
}

.vcamp-text-block-sixteen .img-wrapper .img-meta:before {
 content: "";
 position: absolute;
 bottom: 0;
 left: 0;
 right: 0;
 height: 56%;
 background: linear-gradient(180deg, rgba(24, 24, 24, 0) 0%, #121212 100%);
}

.vcamp-text-block-sixteen .img-wrapper .brand-logo {
 position: absolute;
 left: 0;
 bottom: 12%;
 z-index: 1;
}

.vcamp-text-block-sixteen .img-wrapper .shape-one {
 left: 0;
 bottom: -4%;
 animation: jumpTwo 8s infinite linear;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Thirteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.card-style-thirteen {
 border-top: 1px solid rgba(255, 255, 255, 0.09);
 padding: 65px 0;
}

.card-style-thirteen .title-wrapper {
 position: relative;
 z-index: 1;
}

.card-style-thirteen .title-wrapper h3 {
 font-size: 80px;
 color: #fff;
 font-weight: 300;
 position: relative;
 margin-bottom: 15px;
}

.card-style-thirteen .title-wrapper p {
 font-family: "gorditalight";
 font-size: 24px;
 color: rgba(255, 255, 255, 0.5);
}

.card-style-thirteen .title-wrapper .hover-img {
 position: absolute;
 z-index: -1;
 right: 15px;
 top: 50%;
 opacity: 0;
 visibility: hidden;
 transform: translateY(-50%);
}

.card-style-thirteen:hover .title-wrapper .hover-img {
 opacity: 1;
 visibility: visible;
}

.card-style-thirteen .arrow-icon {
 font-size: 40px;
 color: #fff;
 width: 95px;
 height: 95px;
 border: 1px solid #fff;
 border-radius: 50%;
 text-align: center;
 line-height: 95px;
}

.card-style-thirteen .arrow-icon:hover {
 background: #fff;
 color: #000;
}

.card-style-thirteen:last-child {
 border-bottom: 1px solid rgba(255, 255, 255, 0.09);
}

/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Two ^^^^^^^^^^^^^^^^^^^^^^^^*/
.portfolio-gallery-two {
 position: relative;
 z-index: 1;
}

.portfolio-gallery-two .shape-one {
 bottom: 0;
 right: 0;
}

.portfolio-gallery-two .main-wrapper {
 max-width: 1515px;
 padding: 0 12px;
 margin: 0 auto;
 position: relative;
}

.portfolio-gallery-two .row {
 margin: 0 -45px;
}

.portfolio-gallery-two .row [class*="col-"] {
 padding: 0 45px;
}

.portfolio-gallery-two .gallery-item {
 overflow: hidden;
 position: relative;
}

.portfolio-gallery-two .gallery-item:before {
 content: "";
 position: absolute;
 background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #121212 100%);
 left: 0;
 right: 0;
 bottom: 0;
 height: 48%;
}

.portfolio-gallery-two .gallery-item .hover-content {
 position: absolute;
 bottom: 0;
 left: 0;
 right: 0;
 padding: 40px 50px 22px;
 z-index: 1;
}

.portfolio-gallery-two .gallery-item .hover-content span {
 display: block;
 text-transform: capitalize;
 color: rgba(255, 255, 255, 0.7);
 font-size: 16px;
 margin-top: 5px;
}

.portfolio-gallery-two .gallery-item .c-name {
 font-size: 36px;
 font-weight: 300;
 color: #fff;
}

.portfolio-gallery-two .gallery-item .c-name:hover {
 text-decoration: underline;
}

.portfolio-gallery-two .gallery-item .arrow {
 font-family: "gorditalight";
 position: absolute;
 bottom: 45px;
 right: 50px;
 width: 60px;
 height: 60px;
 border-radius: 50%;
 background: #fff;
 font-size: 42px;
 text-align: center;
 line-height: 60px;
 z-index: 1;
 opacity: 0;
}

.portfolio-gallery-two .gallery-item .arrow:hover {
 background: var(--red-one);
 color: #fff;
}

.portfolio-gallery-two .gallery-item:hover .arrow {
 opacity: 1;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Twelve ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-twelve {
 margin: 0 auto;
 padding: 90px 0 70px;
 max-width: 2460px;
 overflow: hidden;
 border-top: 1px solid rgba(255, 255, 255, 0.09);
 border-bottom: 1px solid rgba(255, 255, 255, 0.09);
}

.feedback-section-twelve .slider-wrapper {
 margin: 0 -25vw;
}

.feedback_slider_eight {
 margin: 0 -75px;
}

.feedback_slider_eight .item {
 padding: 0 75px;
}

.testimonial-block-three {
 opacity: 0.3;
}

.slick-center .testimonial-block-three {
 opacity: 1;
}

.testimonial-block-three .icon {
 width: 100px;
 height: 100px;
 background: #fff;
 border-radius: 50%;
 margin: 0 auto 35px;
}

.testimonial-block-three p {
 font-size: 48px;
 line-height: 1.56em;
 font-weight: 300;
 color: #fff;
 margin-bottom: 32px;
}

.testimonial-block-three h6 {
 font-size: 20px;
 color: rgba(255, 255, 255, 0.5);
}

.feedback_slider_eight .slick-dots {
 text-align: center;
 margin-top: 60px;
}

.feedback_slider_eight .slick-dots li {
 display: inline-block;
}

.feedback_slider_eight .slick-dots li button {
 text-indent: -50000px;
 width: 10px;
 height: 10px;
 border-radius: 50%;
 border: 1px solid #fff;
 background: transparent;
 margin: 0 4px;
 transition: all 0.3s ease-in-out;
}

.feedback_slider_eight .slick-dots li.slick-active button {
 background: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Footer Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-footer-five {
 position: relative;
 z-index: 5;
}

.vcamp-footer-five .bg {
 position: absolute;
 bottom: 0;
 left: 0;
 right: 0;
 height: 59%;
 background: url(../images/media/img_116.jpg) no-repeat top center;
 background-size: cover;
 z-index: -1;
}

.vcamp-footer-five .bg:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 top: -1px;
 left: 0;
 background: linear-gradient(
  180deg,
  #121212 24.13%,
  rgba(0, 0, 0, 0.62) 79.94%
 );
}

.vcamp-footer-five .title-style-eleven .title {
 font-size: 80px;
 line-height: normal;
}

.vcamp-footer-five .arrow {
 width: 90px;
 height: 90px;
 background: #fff;
 border-radius: 50%;
 margin: 80px auto 0;
 transition: all 0.3s ease;
}

.vcamp-footer-five .arrow:hover {
 background: var(--red-one);

 img {
  filter: invert(1);
 }
}

.vcamp-footer-five .bottom-footer .social-site a {
 font-family: "gorditamedium";
 font-size: 16px;
 color: #fff;
 margin-left: 8px;
}

.vcamp-footer-five .bottom-footer .social-site a:hover {
 text-decoration: underline;
}

.vcamp-footer-five .bottom-footer p {
 font-size: 17px;
 color: #fff;
}

.vcamp-footer-five .bottom-footer p a:hover {
 color: var(--red-one);
}

.vcamp-footer-five .shape-one {
 top: 10%;
 left: 12%;
 width: 8.3%;
}

.vcamp-footer-five .shape-two {
 top: 11%;
 right: 0;
 width: 13.85%;
}

.vcamp-footer-five .shape-three {
 bottom: 20%;
 right: 13%;
 width: 9.14%;
}

.vcamp-footer-five .shape-four {
 bottom: 20%;
 left: 0;
 width: 15.85%;
}

.vcamp-footer-five .shape-five {
 bottom: 58%;
 left: 0;
}

/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
#isotop-gallery-wrapper {
 overflow: hidden;
}

.control-nav-four button,
.control-nav-four li {
 display: inline-block;
 color: #000;
 line-height: 40px;
 padding: 0 22px;
 margin: 0 0.9% 20px;
 cursor: pointer;
 transition: all 0.3s ease-in-out;
}

.control-nav-four button.mixitup-control-active,
.control-nav-four li.is-checked {
 background: var(--red-one);
 color: #fff;
}

.control-nav-five button,
.control-nav-five li {
 display: inline-block;
 position: relative;
 color: #000;
 margin: 0 2.5% 20px;
 cursor: pointer;
 transition: all 0.3s ease-in-out;
}

.control-nav-five button:before,
.control-nav-five li:before {
 content: "";
 width: 100%;
 height: 2px;
 background: var(--red-one);
 position: absolute;
 left: 0;
 bottom: 0;
 transform: scale(0, 1);
 transition: all 0.3s ease-in-out;
}

.control-nav-five button.mixitup-control-active,
.control-nav-five li.react-tabs__tab--selected,
.dark-bg .control-nav-five button.mixitup-control-active,
.dark-bg .control-nav-five li.react-tabs__tab--selected {
 color: var(--red-one);
}

.control-nav-five button.mixitup-control-active:before,
.control-nav-five li.react-tabs__tab--selected:before {
 transform: scale(1);
}

.control-nav-seven button,
.control-nav-seven li {
 display: inline-block;
 text-transform: uppercase;
 font-size: 15px;
 letter-spacing: 2px;
 color: rgba(0, 0, 0, 0.6);
 line-height: 27px;
 padding: 0 8px;
 margin: 0 0.9% 15px;
 cursor: pointer;
 position: relative;
 transition: all 0.3s ease-in-out;
}

.control-nav-seven button:first-child:before,
.control-nav-seven li:first-child:before {
 display: none;
}

.control-nav-seven button.mixitup-control-active,
.control-nav-seven li.react-tabs__tab--selected {
 background: var(--orange-one);
 color: #fff;

 &:focus {
  outline: none;
 }
}

.portfolio-gallery-three #isotop-gallery-wrapper {
 margin: 0 -25px;
}

.portfolio-gallery-three .img-holder {
 position: relative;
 overflow: hidden;
}

.portfolio-gallery-three .img-holder .expend {
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 background: rgba(0, 0, 0, 0.7);
 font-size: 35px;
 opacity: 0;
}

.portfolio-gallery-three .img-holder .expend i {
 display: block;
 width: 50px;
 height: 50px;
 line-height: 50px;
 background: #fff;
 color: #000;
 text-align: center;
 border-radius: 50%;
 transition: all 0.3s ease-in-out;
}

.portfolio-gallery-three .img-holder .arrow i {
 font-size: 22px;
}

.portfolio-gallery-three .img-holder .expend i:hover {
 background: var(--red-one);
 color: #fff;
}

.portfolio-gallery-three .gallery-item:hover .img-holder .expend {
 opacity: 1;
}

.portfolio-gallery-three .gallery-item:hover .img-holder .img-meta {
 transform: scale(1.1) rotate(5deg);
}

.portfolio-gallery-three .gallery-item .caption .tag {
 display: block;
 text-transform: uppercase;
 font-size: 13px;
 letter-spacing: 1px;
 color: rgba(0, 0, 0, 0.4);
 margin-bottom: 3px;
}

.portfolio-gallery-three .gallery-item .caption .pj-title {
 font-size: 24px;
 letter-spacing: -0.5px;
 color: #000;
}

.portfolio-gallery-three .gallery-item:hover .caption .pj-title {
 text-decoration: underline;
}

.portfolio-gallery-three .gallery-item .caption .arrow {
 font-size: 26px;
 color: #000;
}

.portfolio-gallery-three .gallery-item .caption .arrow:hover,
.portfolio-gallery-three.dark-bg .gallery-item .caption .arrow:hover {
 color: var(--red-one);
}

.portfolio-gallery-three.full-width .grid-sizer,
.portfolio-gallery-three.full-width .isotop-item {
 width: 33.3333%;
}

.load-more-item1 a {
 display: block;
 width: 70px;
 height: 70px;
 line-height: 68px;
 color: rgba(0, 0, 0, 0.8);
 font-size: 35px;
 border: 1px solid #ebebeb;
 border-radius: 50%;
 margin: 0 auto;
}

.load-more-item1 a:hover {
 background: #212121;
 color: #fff;
}

.load-more-item1 span {
 display: inline-block;
 color: rgba(0, 0, 0, 0.4);
}

/*----- Dark Bg -------*/
.portfolio-gallery-three.dark-bg .gallery-item .caption .arrow,
.portfolio-gallery-three.dark-bg .gallery-item .caption .pj-title,
.dark-bg .control-nav-four button,
.dark-bg .control-nav-four li,
.dark-bg .control-nav-five button,
.dark-bg .control-nav-five li,
.dark-bg .load-more-item1 a {
 color: #fff;
}

.portfolio-gallery-three.dark-bg .gallery-item .caption .tag,
.dark-bg .load-more-item1 span {
 color: rgba(255, 255, 255, 0.7);
}

.dark-bg .load-more-item1 a:hover {
 background: var(--red-one);
 border-color: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Fancy Banner Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-banner-nine .inner-content {
 border: 1px solid #000000;
 padding: 55px 12px;
 z-index: 5;
}

.fancy-banner-nine .inner-content .shape-one {
 left: -28px;
 top: -28px;
}

.fancy-banner-nine .inner-content:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 top: -5px;
 left: -5px;
 border: 1px solid #000000;
 z-index: -1;
}

.fancy-banner-nine .title {
 font-size: 75px;
 line-height: 1.25em;
 font-weight: 300;
}

.fancy-banner-nine .title span {
 position: relative;
 font-style: italic;
}

.fancy-banner-nine .title span:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 4px;
 left: 0;
 bottom: 10px;
 background: #000;
}

.fancy-banner-nine .arrow-btn {
 width: 120px;
 height: 120px;
 line-height: 120px;
 text-align: center;
 font-size: 50px;
 color: #fff;
 font-weight: 300;
 background: var(--red-one);
 border-radius: 0 50px 0 0;
}

.fancy-banner-nine .arrow-btn.org-color {
 background: var(--orange-one);
}

.fancy-banner-nine .arrow-btn:hover {
 border-radius: 50%;
}

/*---- Dark Bg ------*/
.fancy-banner-nine.dark-bg .inner-content,
.fancy-banner-nine.dark-bg .inner-content:before {
 border-color: #fff;
}

.fancy-banner-nine.dark-bg .title {
 color: #fff;
}

.fancy-banner-nine.dark-bg .title span:before {
 background: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.portfolio-gallery-four #isotop-gallery-wrapper {
 margin: 0 -20px;
}

.portfolio-gallery-four .grid-sizer,
.portfolio-gallery-four .isotop-item {
 width: 50%;
 padding: 0 20px;
}

.portfolio-gallery-four .isotop-item.item-xl {
 width: 100%;
}

.portfolio-gallery-four.full-width .grid-sizer,
.portfolio-gallery-four.full-width .isotop-item {
 width: 25%;
}

.portfolio-gallery-four.full-width .isotop-item.item-xl {
 width: 50%;
}

.portfolio-gallery-four .img-holder {
 position: relative;
 overflow: hidden;
 z-index: 5;
}

.portfolio-gallery-four .img-holder .overlay-icon {
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 2;
}

.portfolio-gallery-four .img-holder .video-icon {
 width: 80px;
 height: 80px;
 line-height: 80px;
 background: #000;
 border-radius: 50%;
 color: #fff;
 font-size: 45px;
 text-align: center;
 animation: ripple 3s linear infinite;
}

.portfolio-gallery-four .img-holder .video-icon::before,
.portfolio-gallery-four .img-holder .video-icon::after {
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 border-radius: 50%;
 animation: inherit;
 animation-delay: 1s;
}

.portfolio-gallery-four .img-holder .video-icon::after {
 animation-delay: 3s;
}

.portfolio-gallery-four .img-holder .caption {
 position: absolute;
 left: 0;
 right: 0;
 bottom: 0;
 height: 40%;
 z-index: 1;
 padding: 15px 5px 40px 50px;
 background: linear-gradient(
  180deg,
  rgba(14, 14, 14, 0) 0%,
  rgba(0, 0, 0, 0.7) 100%
 );
}

.portfolio-gallery-four .grid-3column .img-holder .caption,
.portfolio-gallery-four .grid-4column .img-holder .caption,
.portfolio-gallery-four .grid-5column .img-holder .caption {
 padding: 15px 5px 15px 20px;
}

.portfolio-gallery-four.full-width .img-holder .caption {
 padding: 15px 5px 20px 30px;
}

.portfolio-gallery-four .img-holder .caption .tag {
 text-transform: uppercase;
 font-size: 13px;
 letter-spacing: 1px;
 color: rgba(255, 255, 255, 0.7);
 margin-bottom: 4px;
}

.portfolio-gallery-four .img-holder .caption .pj-title {
 font-family: "gorditamedium";
 font-size: 22px;
 letter-spacing: -0.5px;
 color: #fff;
}

.portfolio-gallery-four .grid-3column .img-holder .caption .pj-title,
.portfolio-gallery-four .grid-4column .img-holder .caption .pj-title,
.portfolio-gallery-four .grid-5column .img-holder .caption .pj-title {
 font-size: 20px;
}

.portfolio-gallery-four .img-holder .zoom-icon {
 display: block;
 width: 50px;
 height: 50px;
 line-height: 50px;
 background: #fff;
 color: #000;
 font-size: 28px;
 text-align: center;
 border-radius: 50%;
 opacity: 0;
 transition: all 0.3s ease-in-out;
}

.portfolio-gallery-four .img-holder .zoom-icon:hover {
 background: var(--red-one);
 color: #fff;
}

.portfolio-gallery-four .gallery-item:hover .zoom-icon {
 opacity: 1;
}

.portfolio-gallery-four .img-holder .caption .pj-title:hover {
 text-decoration: underline;
}

.portfolio-gallery-four .gallery-item:hover .img-holder .caption {
 height: 100%;
}

.portfolio-gallery-four .gallery-item:hover .img-holder .img-meta {
 transform: scale3d(1.1, 1.1, 1);
}

/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Five ^^^^^^^^^^^^^^^^^^^^^^^^*/
.control-nav-six button,
.control-nav-six li {
 display: inline-block;
 color: #000;
 line-height: 40px;
 padding: 0 22px;
 margin: 0 0.9% 20px;
 cursor: pointer;
 transition: all 0.3s ease-in-out;
}

.control-nav-six li.react-tabs__tab--selected,
.control-nav-six li.react-tabs__tab--selected {
 background: #000;
 color: #fff;
}

.dark-bg .control-nav-six li,
.dark-bg .control-nav-six li {
 color: #fff;
}

.dark-bg .control-nav-six li.react-tabs__tab--selected,
.dark-bg .control-nav-six li.react-tabs__tab--selected {
 background: var(--red-one);
 outline: none;
}

.portfolio-gallery-five #isotop-gallery-wrapper {
 margin: 0 -20px;
}

.portfolio-gallery-five .isotop-item,
.portfolio-gallery-five .grid-sizer {
 width: 100%;
}

.portfolio-gallery-five .isotop-item.item-xl {
 width: 66.66%;
}

.hvr-shutter-out .img-holder {
 position: relative;
 overflow: hidden;
 z-index: 5;
}

.hvr-shutter-out .img-holder:before {
 content: "";
 position: absolute;
 background: rgba(0, 0, 0, 0.8);
 top: 50%;
 bottom: 50%;
 left: -45%;
 right: -45%;
 transform: rotate(45deg);
 transition: all 0.5s ease-in-out;
 z-index: 2;
}

.hvr-shutter-out .img-holder:hover:before {
 top: -45%;
 bottom: -45%;
}

.hvr-shutter-out .img-holder .caption {
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 opacity: 0;
 transition-delay: 0s;
 z-index: 3;
 transition: all 0.35s ease;
}

.hvr-shutter-out .img-holder .caption .tag {
 display: inline-block;
 text-transform: uppercase;
 font-size: 13px;
 letter-spacing: 1.2px;
 color: rgba(255, 255, 255, 0.9);
 margin-bottom: 3px;
}

.hvr-shutter-out .img-holder .caption .pj-title {
 font-size: 22px;
 letter-spacing: -0.5px;
 color: #fff;
}

.hvr-shutter-out .img-holder .caption .pj-title:hover {
 text-decoration: underline;
}

.hvr-shutter-out .img-holder:hover .caption {
 opacity: 1;
 transition-delay: 0.105s;
}

.hvr-shutter-out .img-holder .img-meta {
 position: relative;
 z-index: 1;
}

.hvr-shutter-out .img-holder:hover .img-meta {
 transform: scale(1.1) rotate(5deg);
}

.hvr-push-down .img-holder {
 position: relative;
 overflow: hidden;
 z-index: 5;
}

.hvr-push-down .img-holder .caption {
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 z-index: 3;
 opacity: 0;
 background: var(--red-one);
 transition: all 0.5s ease;
 -webkit-transform: translateY(-100%);
 transform: translateY(-100%);
}

.hvr-push-down .img-holder .caption .tag {
 display: inline-block;
 text-transform: uppercase;
 font-size: 12px;
 letter-spacing: 1.2px;
 color: rgba(255, 255, 255, 0.85);
 margin-bottom: 1px;
}

.hvr-push-down .img-holder .caption .pj-title {
 font-size: 22px;
 letter-spacing: -0.5px;
 color: #fff;
}

.hvr-push-down .img-holder .caption .pj-title:hover {
 text-decoration: underline;
}

.hvr-push-down .img-holder:hover .caption {
 opacity: 1;
 -webkit-transform: translate(0, 0);
 transform: translate(0, 0);
}

.hvr-push-down .img-holder:hover .img-meta {
 -webkit-transform: translateY(100%);
 transform: translateY(100%);
}

/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
/*2 Clumn*/
.grid-2column {
 margin: 0 -20px;
}

.grid-2column .isotop-item,
.grid-2column .grid-sizer {
 width: 50%;
 padding: 0 20px;
}

/*3 clumn*/
.grid-3column {
 margin: 0 -20px;
}

.grid-3column .isotop-item,
.grid-3column .grid-sizer {
 width: 33.33%;
 padding: 0 20px;
}

/*4 clumn*/
.grid-4column {
 margin: 0 -20px;
}

.grid-4column .isotop-item,
.grid-4column .grid-sizer {
 width: 25%;
 padding: 0 20px;
}

/*5 clumn*/
.grid-5column {
 margin: 0 -15px;
}

.grid-5column .isotop-item,
.grid-5column .grid-sizer {
 width: 20%;
 padding: 0 15px;
}

.galley-item-wrapper .img-holder {
 position: relative;
 overflow: hidden;
 z-index: 5;
}

.galley-item-wrapper .img-holder .img-meta {
 position: relative;
 z-index: 1;
}

.galley-item-wrapper .img-holder .icon-group {
 position: absolute;
 left: 0;
 right: 0;
 top: calc(50% - 26px);
 z-index: 1;
}

.galley-item-wrapper .img-holder .icon-group a,
.galley-item-wrapper .img-holder .icon-group i {
 display: block;
 width: 45px;
 height: 45px;
 line-height: 45px;
 background: #fff;
 color: #000;
 font-size: 33px;
 text-align: center;
 border-radius: 50%;
}

.galley-item-wrapper .img-holder .icon-group a.arrow i {
 font-size: 20px;
 display: block;
}

.galley-item-wrapper .img-holder .icon-group a:hover,
.galley-item-wrapper .img-holder .icon-group i:hover {
 background: var(--red-one);
 color: #fff;
}

.dark-bg .galley-item-wrapper .img-holder .icon-group a:hover,
.dark-bg .galley-item-wrapper .img-holder .icon-group i:hover {
 background: #212121;
}

.hvr-overlay-right .img-holder:before {
 content: "";
 position: absolute;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
 z-index: 2;
 background: rgba(0, 0, 0, 0.8);
 transform: translateX(-100%);
 transition: all 0.35s ease;
}

.dark-bg .hvr-overlay-right .img-holder:before {
 background: rgba(255, 39, 89, 0.9);
}

.hvr-overlay-right .img-holder .caption {
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 z-index: 3;
 opacity: 0;
 padding: 0 5px 18px;
 transition: all 0.35s ease;
}

.hvr-overlay-right .img-holder .caption .tag {
 display: inline-block;
 text-transform: uppercase;
 font-size: 12px;
 letter-spacing: 1.2px;
 margin-top: -5px;
 color: rgba(255, 255, 255, 0.85);
}

.hvr-overlay-right .img-holder .caption .pj-title {
 font-size: 22px;
 letter-spacing: -0.5px;
 color: #fff;
}

.hvr-overlay-right .img-holder .caption .pj-title:hover {
 text-decoration: underline;
}

.hvr-overlay-right .gallery-item:hover .img-holder:before {
 -webkit-transform: translate(0, 0);
 transform: translate(0, 0);
}

.hvr-overlay-right .gallery-item:hover .caption {
 opacity: 1;
 transition-delay: 0.21s;
}

.hvr-overlay-right .gallery-item:hover .img-holder .img-meta {
 transform: scale(1.1) rotate(5deg);
}

/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.portfolio-gallery-seven .portfolio-sidebar {
 width: 250px;
 padding: 60px 5px 30px 40px;
}

.portfolio-gallery-seven .control-nav-five button,
.portfolio-gallery-seven .control-nav-five li {
 margin: 0 auto 14px 0;
}

.portfolio-gallery-seven .portfolio-sidebar .social-icon a {
 font-size: 18px;
 color: #bcbcbc;
 margin-right: 18px;
 transition: all 0.3s ease;
}

.portfolio-gallery-seven .portfolio-sidebar .social-icon li:last-child a {
 margin-right: 0;
}

.portfolio-gallery-seven .portfolio-sidebar .social-icon a:hover {
 color: var(--red-one);
}

.portfolio-gallery-seven .main-gallery-wrapper {
 width: calc(100% - 250px);
 padding: 60px 65px 100px 85px;
}

.portfolio-gallery-seven .main-gallery-wrapper .title2 {
 font-family: "gorditamedium";
 font-size: 36px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/
.gap15 {
 margin: 0 -15px;
}

.gap15 .item {
 padding: 0 15px;
}

.gap10 {
 margin: 0 -10px;
}

.gap10 .item {
 padding: 0 10px;
}

.gallery-slider-arrow1 li {
 position: absolute;
 top: 50%;
 transform: translateY(-50%);
 width: 50px;
 height: 50px;
 background: #fff;
 border-radius: 50%;
 line-height: 50px;
 text-align: center;
 color: #000;
 font-size: 22px;
 z-index: 1;
 cursor: pointer;
 box-shadow: 0 0 30px rgba(0, 0, 0, 0.06);
 transition: all 0.3s ease;
}

.gallery-slider-arrow1 li.prev_btn1 {
 left: -25px;
}

.gallery-slider-arrow1 li.next_btn1 {
 right: -25px;
}

.gallery-slider-arrow1 li:hover {
 background: #000;
 color: #fff;
}

/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Details One ^^^^^^^^^^^^^^^^^^^^^^^^*/
.pr-details-one .social-icon a {
 width: 44px;
 height: 44px;
 border: 1px solid #e7e7e7;
 border-radius: 50%;
 text-align: center;
 line-height: 42px;
 color: #000;
 margin: 0 5px;
 transition: all 0.3s ease-in-out;
}

.pr-details-one .social-icon a:hover {
 background: #000;
 color: #fff;
}

.pr-details-one .social-icon-two a {
 font-size: 20px;
 color: #212121;
 margin-right: 25px;
 transition: all 0.3s ease-in-out;
}

.pr-details-one .social-icon-two li:last-child a {
 margin-right: 0;
}

.pr-details-one .social-icon-two a:hover {
 color: var(--red-one);
}

.pr-details-one .text-wrapper h4 {
 margin-bottom: 25px;
}

.pr-details-one .text-wrapper p {
 font-size: 17px;
 margin-bottom: 48px;
 line-height: 2.11em;
 color: rgba(0, 0, 0, 0.7);
}

.pr-details-one .text-wrapper [class*="theme-btn-"] {
 line-height: 55px;
 padding: 0 32px;
}

.pr-details-one .pt-title {
 font-family: "gorditamedium";
 font-size: 13px;
 text-transform: uppercase;
 color: rgba(0, 0, 0, 0.3);
 letter-spacing: 1px;
}

.pr-details-one .pt-text {
 font-size: 17px;
 color: rgba(0, 0, 0, 0.7);
}

.pr-pagination-one .pr-dir {
 font-size: 13px;
 letter-spacing: 2.6px;
 color: rgba(62, 62, 62, 0.5);
 text-transform: uppercase;
}

.pr-pagination-one .pr-name {
 font-family: "gorditamedium";
 font-size: 22px;
 color: #1a1a1a;
 margin-top: 4px;
}

.pr-pagination-one i {
 font-size: 35px;
 transition: all 0.3s ease-in-out;
}

.pr-pagination-one a:hover i {
 color: var(--red-one);
}

.pr-details-one .pr-pagination-one.ps-absolute {
 position: absolute;
 right: 0;
 bottom: 0;
}

/*--- Dark Style ---*/
.dark-bg .pr-details-one .social-icon a {
 background: rgba(255, 255, 255, 0.1);
 border: none;
 line-height: 44px;
 color: rgba(255, 255, 255, 0.4);
}

.dark-bg .pr-details-one .social-icon a:hover {
 background: var(--red-one);
 color: #fff;
}

.dark-bg .pr-details-one .text-wrapper h4,
.dark-bg .pr-details-one .pt-title,
.dark-bg .pr-pagination-one .pr-name,
.dark-bg .pr-pagination-one i,
.dark-bg .pr-details-one .social-icon-two a {
 color: #fff;
}

.dark-bg .pr-details-one .text-wrapper p,
.dark-bg .pr-details-one .pt-text,
.dark-bg .pr-pagination-one .pr-dir {
 color: rgba(255, 255, 255, 0.7);
}

.dark-bg .pr-details-one .social-icon-two a:hover {
 color: var(--red-one);
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Twelve ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-twelve {
 position: relative;
}

.hero-banner-twelve .scroll-bar {
 width: 3px;
 height: 80px;
 background: #dddddd;
 position: absolute;
 top: 50%;
 left: 0;
 transform: translateY(-50%);
 z-index: 9;
}

.hero-banner-twelve .scroll-bar:before {
 content: "";
 position: absolute;
 height: 80%;
 width: 100%;
 left: 0;
 bottom: 0;
 background: #000;
 animation: jumpTwo 3s infinite linear;
}

.hero-banner-twelve .social-elemnet {
 position: absolute;
 z-index: 9;
 right: -60px;
 top: 40%;
 transform: rotate(-90deg) translate(0, 0%);
}

.hero-banner-twelve .social-elemnet li {
 color: rgba(0, 0, 0, 0.4);
 margin: 0 2px;
}

.hero-banner-twelve .social-elemnet li a:hover {
 color: #000;
}

.hero-banner-twelve .hero-heading {
 font-size: 106px;
 line-height: 1.02em;
 font-weight: 300;
 position: relative;
}

.hero-banner-twelve .hero-heading:before {
 content: url(../images/shape/shape_64.svg);
 position: absolute;
 left: -22px;
 top: -68px;
}

.hero-banner-twelve .ct-text {
 font-size: 15px;
 letter-spacing: 2px;
 color: rgba(0, 0, 0, 0.4);
 text-transform: uppercase;
}

.hero-banner-twelve .mail-info {
 font-size: 28px;
 color: #000;
 text-decoration: underline;
}

.hero-banner-twelve .mail-info:hover {
 color: var(--orange-one);
}

.hero-banner-twelve .avatar-holder {
 position: absolute;
 right: 0;
 top: 0;
 height: 100%;
 z-index: -1;
}

.hero-banner-twelve .avatar-holder img {
 height: 100%;
 object-fit: cover;
 object-position: top center;
}

.hero-banner-twelve .feature-project .ft-tag {
 font-size: 12px;
 text-transform: uppercase;
 letter-spacing: 1px;
 color: rgba(0, 0, 0, 0.4);
 position: relative;
 padding-left: 25px;
 line-height: 12px;
}

.hero-banner-twelve .feature-project .ft-tag:before {
 content: "";
 position: absolute;
 width: 15px;
 height: 1px;
 background: #adadad;
 left: 0;
 top: 5px;
}

.hero-banner-twelve .feature-project .ft-title a {
 font-family: "gorditamedium";
 font-size: 24px;
 color: #000;
 margin: 15px 0 8px;
}

.hero-banner-twelve .feature-project .ft-title:hover a {
 text-decoration: underline;
}

.hero-banner-twelve .feature-project .exp-btn {
 font-family: "gorditamedium";
 font-size: 18px;
 color: var(--orange-one);
 text-decoration: underline;
 margin-top: 40px;
 transition: all 0.3s ease-in-out;
}

.hero-banner-twelve .feature-project .exp-btn:hover {
 color: #000;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Thirteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-thirteen {
 position: relative;
}

.feedback-section-thirteen .shape_1 {
 left: 18%;
 top: -1%;
}

.feedback-section-thirteen .shape_2 {
 left: 9%;
 top: 40%;
}

.feedback-section-thirteen .shape_3 {
 left: 17%;
 top: 89%;
}

.feedback-section-thirteen .shape_4 {
 right: 18%;
 top: -1%;
}

.feedback-section-thirteen .shape_5 {
 right: 7%;
 top: 40%;
}

.feedback-section-thirteen .shape_6 {
 right: 16%;
 top: 89%;
}

.feedback-section-thirteen .shape_7 {
 left: 18%;
 top: -4%;
}

.feedback-section-thirteen .shape_8 {
 right: 15%;
 top: 99%;
}

.testimonial-block-four p {
 font-size: 42px;
 line-height: 1.78em;
 font-weight: 300;
 color: #171717;
 margin-bottom: 40px;
}

.testimonial-block-four h6 {
 font-size: 20px;
 color: #000;
}

.testimonial-block-four h6 span {
 color: rgba(0, 0, 0, 0.4);
}

.feedback_slider_nine .slick-dots {
 text-align: center;
 margin-top: 65px;
}

.feedback_slider_nine .slick-dots li {
 display: inline-block;
}

.feedback_slider_nine .slick-dots li button {
 text-indent: -50000px;
 width: 10px;
 height: 10px;
 border-radius: 50%;
 border: 1px solid #000;
 background: transparent;
 margin: 0 4px;
 transition: all 0.3s ease-in-out;
}

.feedback_slider_nine .slick-dots li.slick-active button {
 background: #000;
}

/*^^^^^^^^^^^^^^^^^^^^^ Footer Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-footer-six {
 padding: 80px 0 20px;
}

.vcamp-footer-six .social-icon li {
 margin: 0 12px;
}

.vcamp-footer-six .social-icon li:hover img {
 transform: translateY(-2px);
}

.vcamp-footer-six .mail-info a {
 font-size: 20px;
 color: #000;
}

.vcamp-footer-six .mail-info a:hover {
 text-decoration: underline;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Thirteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-thirteen {
 position: relative;
 background: url(../images/shape/bg-3.png) no-repeat center;
 background-size: cover;
 padding: 225px 0 35px;
 color: #fff;
 z-index: 2;
}

.hero-banner-thirteen .hero-heading {
 font-size: 100px;
 line-height: 1.1em;
 color: #fff;
}

.hero-banner-thirteen .demo-text {
 font-size: 24px;
 display: inline-block;
 padding-top: 40px;
 margin-top: 160px;
 border-top: 2px solid #fff;
}

.hero-banner-thirteen .right-content-wrapper {
 position: absolute;
 right: 11%;
 top: 20%;
 width: 29%;
}

.hero-banner-thirteen .right-content-wrapper .circle-text {
 top: -7%;
 left: 50%;
 z-index: 1;
 transform: translateX(-50%);
 width: 96%;
}

.hero-banner-thirteen .right-content-wrapper .main-img {
 border-radius: 300px 300px 0 0;
}

.hero-banner-thirteen .right-content-wrapper .slide-count {
 font-size: 24px;
 margin-top: 25px;
}

.hero-banner-thirteen .right-content-wrapper .rating-box {
 position: absolute;
 z-index: 1;
 left: -16%;
 top: 51%;
 text-align: center;
 width: 32.5%;
}

.hero-banner-thirteen .right-content-wrapper .rating-box img {
 animation: rotated 50s infinite linear;
}

.hero-banner-thirteen .right-content-wrapper .rating-box .rate {
 color: #000;
 font-size: 24px;
 line-height: 1.08em;
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
}

.hero-banner-thirteen .right-content-wrapper .rating-box .rate span {
 font-size: 0.66em;
}

.hero_slider_four .slick-dots {
 position: absolute;
 right: 0;
 bottom: 6px;
 padding: 0;
 margin-bottom: 0;
 width: auto;
}

.hero_slider_four .slick-dots li {
 display: inline-block;
 vertical-align: middle;
}

.hero_slider_four .slick-dots li button {
 text-indent: -50000px;
 width: 6px;
 height: 6px;
 border-radius: 50%;
 background: #ffd9d9;
 margin: 0 0 0 8px;
 transition: all 0.1s ease-in-out;
}

.hero_slider_four .slick-dots li.slick-active button {
 width: auto;
 height: 4px;
 background: #fff;
 border-radius: 50%;
}

.hero-banner-thirteen .right-content-wrapper .circle-shape {
 top: -23%;
 left: -54%;
 max-width: 191%;
 z-index: 0;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Fourteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.card-style-fourteen {
 border-top: 1px solid #000000;
 padding-left: 30px;
}

.card-style-fourteen:last-child {
 border-bottom: 1px solid #000000;
}

.card-style-fourteen .icon {
 width: 38px;
 margin-top: -25px;
}

.card-style-fourteen .title-wrapper {
 padding-left: 40px;
}

.card-style-fourteen .title-wrapper h4 a {
 color: #000;
 letter-spacing: -0.5px;
 margin-bottom: 9px;
 transition: all 0.3s ease-in-out;
}

.card-style-fourteen .title-wrapper p {
 font-size: 16px;
 margin: 0;
 color: #4f4f4f;
}

.card-style-fourteen .arrow {
 font-size: 30px;
 color: #000;
 padding: 64px 25px;
 margin-left: auto;
 position: relative;
 z-index: 1;
 transition: all 0.3s ease-in-out;
}

.card-style-fourteen .arrow:before {
 content: "";
 position: absolute;
 top: 0;
 right: 0;
 width: 100%;
 height: 100%;
 z-index: -1;
 background: #ff5332;
 transform: scale(0, 1);
 transform-origin: 100% 0;
 transition: all 0.3s ease-in-out;
}

.card-style-fourteen:hover .arrow:before {
 transform: scale(1);
}

.card-style-fourteen:hover .arrow {
 color: #fff;
}

.card-style-fourteen .title-wrapper h4 a:hover {
 color: #ff7961;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Fifteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-feature-section-fifteen {
 background: #fffce6;
 padding: 120px 0 150px;
}

.vcamp-feature-section-fifteen .sub-text {
 font-size: 20px;
 line-height: 2em;
 margin: 10px 0 45px;
}

.vcamp-feature-section-fifteen .inner-wrapper {
 border: 1px solid #000;
 z-index: 1;
 border-right: none;
}

.vcamp-feature-section-fifteen .inner-wrapper:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 left: 0;
 top: 0;
 border: 1px solid #000;
 z-index: -1;
 transform: translate(10px, 10px);
 transition: all 0.3s ease-in-out;
}

.card-style-fifteen {
 background: #fff;
 padding: 62px 45px 62px;
 border-right: 1px solid #000;
 position: relative;
 overflow: hidden;
}

.card-style-fifteen .main-count {
 font-size: 72px;
 line-height: initial;
 color: #fff;
 -webkit-text-stroke: 1px #ff4b2c;
}

.card-style-fifteen h4 {
 font-size: 28px;
 margin: 30px 0 32px;
}

.card-style-fifteen p {
 line-height: 32px;
}

.card-style-fifteen p span {
 font-family: "gorditamedium";
 color: #000;
}

.card-style-fifteen .arrow {
 width: 54px;
 height: 50px;
 line-height: 50px;
 background: #313131;
 color: #fff;
 font-size: 22px;
 position: absolute;
 right: 0;
 bottom: 0;
 z-index: 1;
 transform: translate(100%, 100%);
 opacity: 0;
}

.card-style-fifteen .arrow:hover {
 background: #ff5332;
}

.card-style-fifteen:hover .arrow {
 opacity: 1;
 transform: translate(0px);
}

/*^^^^^^^^^^^^^^^^^^^^^ Portfolio Gallery Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/
.portfolio-gallery-nine .gallery-item .img-holder:before {
 content: "";
 position: absolute;
 left: 0%;
 right: 0%;
 top: 48.38%;
 bottom: 0%;
 transition: all 0.3s ease-in-out;
 background: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0.0001) 0%,
  rgba(0, 0, 0, 0.544058) 62%,
  rgba(0, 0, 0, 0.615498) 100%
 );
}

.portfolio-gallery-nine .gallery-item:hover .img-holder:before {
 top: 0;
}

.portfolio-gallery-nine .gallery-item .caption {
 position: absolute;
 bottom: 0;
 left: 0;
 right: 0;
 z-index: 1;
 padding: 0 30px 30px;
}

.portfolio-gallery-nine .gallery-item .caption .arrow {
 width: 65px;
 height: 65px;
 border-radius: 50%;
 background: rgba(255, 255, 255, 0.15);
 text-align: center;
 line-height: 65px;
 color: #fff;
 font-size: 22px;
 margin-bottom: 30px;
}

.portfolio-gallery-nine .gallery-item .caption .pj-title {
 font-size: 32px;
 line-height: 1.25em;
 color: #fff;
}

.portfolio-gallery-nine .gallery-item:hover .caption .arrow {
 background: #ff5332;
}

.portfolio-gallery-nine .gallery-item:hover .caption .pj-title {
 color: #ff5332;
}

.portfolio-slider-five .item {
 padding: 0 15px;
}

.portfolio-gallery-nine .slider-arrows li {
 width: 50px;
 height: 50px;
 border-radius: 50%;
 text-align: center;
 line-height: 50px;
 color: #000;
 font-size: 28px;
 margin-left: 3px;
 cursor: pointer;
 transform: scale(0.8);
 transition: all 0.3s ease-in-out;
}

.portfolio-gallery-nine .slider-arrows li:hover {
 background: #ff5332;
 color: #fff;
 transform: scale(1);
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Text Block Eighteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.accordion-style-five .accordion-item {
 border: none;
 border-bottom: 1px solid #000;
 border-radius: 0;
}

.accordion-style-five .accordion-button {
 font-family: "Zen Dots", cursive;
 font-size: 19px;
 padding: 25px 0;
 box-shadow: none;
 border-radius: 0;
 color: var(--heading);
 background: transparent;
 transition: all 0.3s ease-in-out;
}

.accordion-style-five .accordion-button:not(.collapsed) {
 padding-bottom: 15px;
}

.accordion-style-five .accordion-body {
 padding: 0 10px 10px 0;
}

.accordion-style-five .accordion-body p {
 font-size: 16px;
 line-height: 30px;
}

.accordion-style-five .accordion-button::after {
 content: "+";
 font-family: "gorditaregular";
 font-size: 28px;
 background-image: none;
 width: auto;
 height: auto;
}

.accordion-style-five .accordion-button:not(.collapsed)::after {
 content: "-";
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Fancy Banner Ten ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-banner-ten {
 background: url(../images/media/img_131.jpg) no-repeat center;
 background-size: cover;
 height: 650px;
 position: relative;
 z-index: 2;
}

.fancy-banner-ten .video-icon {
 width: 85px;
 height: 85px;
 border-radius: 50%;
 background: rgba(0, 0, 0, 0.25);
 font-size: 50px;
 padding-left: 5px;
 color: #fff;
 transition: all 0.3s ease-in-out;
}

.fancy-banner-ten .video-icon:hover {
 background: #ff5332;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Fourteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-fourteen {
 position: relative;
 background: url(../images/shape/bg-3.png) no-repeat center;
 background-size: cover;
 padding: 225px 0 85px;
 margin-top: -135px;
 z-index: 1;
}

.testimonial-block-five p {
 font-size: 48px;
 line-height: 1.56em;
 color: #fff;
 margin-bottom: 48px;
}

.testimonial-block-five h6 {
 font-size: 20px;
 color: #fff;
}

.testimonial-block-five h6 span {
 font-family: "gorditaregular";
 color: rgba(255, 255, 255, 0.6);
}

.feedback-section-fourteen .feedback_slider_nine .slick-dots {
 margin-top: 40px;
}

.feedback-section-fourteen .feedback_slider_nine .slick-dots li button {
 border: 1px solid #fff;
}

.feedback-section-fourteen .slick-dots li.slick-active button {
 background: #fff;
}

.feedback-section-fourteen .rating-box {
 position: absolute;
 z-index: 1;
 left: 4%;
 bottom: 7%;
 text-align: center;
}

.feedback-section-fourteen .rating-box img {
 animation: rotated 50s infinite linear;
}

.feedback-section-fourteen .rating-box .rate {
 color: #000;
 font-size: 24px;
 line-height: 1.08em;
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
}

.feedback-section-fourteen .rating-box .rate span {
 font-size: 0.66em;
}

/*^^^^^^^^^^^^^^^^^^^^^ Team Section Six ^^^^^^^^^^^^^^^^^^^^^^^^*/
.team-section-six {
 background: #fffce6;
}

.team-block-five {
 position: relative;
 z-index: 1;
 background: #fff;
 border: 1px solid #000;
}

.team-block-five:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 left: 0;
 top: 0;
 border: 1px solid #000;
 z-index: -2;
 transform: translate(7px, 7px);
 transition: all 0.3s ease-in-out;
}

.team-block-five:hover:before {
 transform: translate(0px, 0px);
}

.team-block-five .text {
 text-align: center;
 border-top: 1px solid #000;
 background: #fff;
 padding: 35px 5px 25px;
}

.team-block-five .text h6 {
 font-family: "gorditamedium";
 font-size: 20px;
 margin-bottom: 5px;
 transition: all 0.3s ease-in-out;
}

.team-block-five .text .info {
 font-size: 15px;
 color: #b6b6b6;
 transition: all 0.3s ease-in-out;
}

.team-block-five:hover .text {
 background: #ff5332;
}

.team-block-five:hover .text h6 {
 color: #fff;
}

.team-block-five:hover .text .info {
 color: rgba(255, 255, 255, 0.7);
}

/*^^^^^^^^^^^^^^^^^^^^^ Blog Section Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.blog-meta-six .tag {
 font-family: "gorditamedium";
 display: inline-block;
 line-height: 27px;
 letter-spacing: 2px;
 background: #ff5332;
 color: #fff;
 font-size: 11px;
 text-transform: uppercase;
 padding: 0 9px;
 position: absolute;
 left: 30px;
 bottom: 20px;
}

.blog-meta-six .post-info {
 font-family: "gorditamedium";
 font-size: 16px;
 color: var(--heading);
 margin-top: 32px;
}

.blog-meta-six .post-info .date {
 font-family: "gorditaregular";
 color: #aaaaaa;
}

.blog-meta-six .title {
 font-family: "gorditamedium";
 font-size: 32px;
 line-height: 1.28em;
 color: var(--heading);
 display: block;
 margin: 25px 0;
 width: 90%;
 transition: all 0.3s ease-in-out;
}

.blog-meta-six .read-more {
 font-size: 15px;
 color: #000;
}

.blog-meta-six .read-more i {
 vertical-align: middle;
}

.blog-meta-six:hover .title,
.blog-meta-six .read-more:hover {
 color: #ff5332;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Fancy Banner Eleven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-banner-eleven {
 background: url(../images/media/img_132.jpg) no-repeat center;
 background-size: cover;
 position: relative;
 z-index: 1;
 padding: 75px 0;
}

.fancy-banner-eleven:before {
 content: "";
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 background: rgba(0, 0, 0, 0.55);
 z-index: -1;
}

.fancy-banner-eleven .form-wrapper {
 max-width: 495px;
 margin-left: auto;
}

.fancy-banner-eleven .form-wrapper input {
 color: #fff;
 width: 100%;
 background: transparent;
 border: none;
 border-bottom: 2px solid #fff;
 padding-bottom: 15px;
 margin-bottom: 8px;
}

.fancy-banner-eleven .form-wrapper button {
 position: absolute;
 right: 0;
 top: 5px;
 color: #ff4d2e;
 font-size: 20px;
}

.fancy-banner-eleven .form-wrapper .info {
 font-size: 15px;
 color: #fff;
}

.fancy-banner-eleven .form-wrapper .info a {
 color: #ff5332;
}

.fancy-banner-eleven .form-wrapper .info a:hover {
 text-decoration: underline;
}

/*^^^^^^^^^^^^^^^^^^^^^ Footer Seven ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-footer-seven {
 background: #1a1530;
 padding: 100px 0 0;
 color: #fff;
}

.vcamp-footer-seven .intro {
 font-size: 33px;
 // line-height: 1.25em;
 color: #fff;
 margin-bottom: 35px;
 font-family: "avenirblack";
}

.vcamp-footer-seven .intro .text-purple {
 color: #8163ff;
}

.vcamp-footer-seven .intro .text-green {
 color: #00fbc8;
}

.vcamp-footer-seven .intro span {
 color: #ff5d41;
 text-decoration: underline;
}

.vcamp-footer-seven .social-icon a {
 font-size: 35px;
 margin-right: 20px;
 transition: all 0.2s ease-in-out;
}

.vcamp-footer-seven .social-icon a:hover {
 color: #ff5332;
}

.vcamp-footer-seven .title {
 font-size: 26px;
 // font-family: Arial, Helvetica, sans-serif;
 letter-spacing: 1px;
 // text-transform: uppercase;
 color: #ffffff;
 margin-bottom: 25px;
 font-weight: bold;
 font-family: "avenirblack";
}

.vcamp-footer-seven .title.sm {
 font-size: 14px;
 letter-spacing: 0.7px;
 margin-bottom: 12px;
}

.vcamp-footer-seven .footer-list a {
 font-size: 22px;
 line-height: 40px;
 color: #fff;
 transition: all 0.2s ease-in-out;
 font-family: "avenirroman";
}

.vcamp-footer-seven .address p {
 line-height: 33px;
 margin-bottom: 25px;
}

.vcamp-footer-seven .address .email {
 font-family: "gorditalight";
 font-size: 32px;
 color: #fff;
 text-decoration: underline;
 margin-bottom: 45px;
}

.vcamp-footer-seven .address .call {
 font-size: 20px;
}

.vcamp-footer-seven .bottom-footer {
 border-top: 1px solid rgba(255, 255, 255, 0.08);
 margin-top: 55px;
 padding-top: 25px;
}

.vcamp-footer-seven .bottom-footer p {
 font-size: 18px;
 color: rgba(255, 255, 255, 0.7);
 margin: 0;
 font-family: "avenirblack";
}

.vcamp-footer-seven .bottom-footer .footer-nav a {
 font-size: 18px;
 color: rgba(255, 255, 255, 0.7);
 transition: all 0.2s ease-in-out;
 margin-left: 20px;
 font-family: "avenirblack";
}

.vcamp-footer-seven .footer-list a:hover,
.vcamp-footer-seven .address .email:hover,
.vcamp-footer-seven .address .call:hover,
.vcamp-footer-seven .bottom-footer .footer-nav a:hover {
 color: #ff5332;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Fourteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-fourteen {
 position: relative;
}

.hero-banner-fourteen .scroll-bar {
 width: 3px;
 height: 80px;
 background: #dddddd;
 position: absolute;
 top: 50%;
 left: 0;
 transform: translateY(-50%);
 z-index: 9;
}

.hero-banner-fourteen .scroll-bar:before {
 content: "";
 position: absolute;
 height: 80%;
 width: 100%;
 left: 0;
 bottom: 0;
 background: #000;
 animation: jumpTwo 3s infinite linear;
}

.hero-banner-fourteen .social-elemnet {
 position: absolute;
 z-index: 9;
 right: -60px;
 top: 40%;
 transform: rotate(-90deg) translate(0, 0%);
}

.hero-banner-fourteen .social-elemnet li {
 color: rgba(0, 0, 0, 0.4);
 margin: 0 2px;
}

.hero-banner-fourteen .social-elemnet li a:hover {
 color: #000;
}

.hero-banner-fourteen .hero-heading {
 font-size: 120px;
 line-height: 1em;
 font-weight: 300;
 position: relative;
}

.hero-banner-fourteen .hero-heading:before {
 content: url(../images/shape/shape_64.svg);
 position: absolute;
 left: -22px;
 top: -80px;
}

.hero-banner-fourteen .hero-heading span {
 color: #fff;
 text-shadow: 0 0 1px #000;
 -webkit-text-stroke-width: 1px;
 -webkit-text-stroke-color: black;
}

.hero-banner-fourteen .ct-text {
 font-size: 15px;
 letter-spacing: 2px;
 color: rgba(0, 0, 0, 0.4);
 text-transform: uppercase;
}

.hero-banner-fourteen .mail-info {
 font-size: 28px;
 color: #000;
 text-decoration: underline;
}

.hero-banner-fourteen .mail-info:hover {
 color: var(--orange-one);
}

.hero-banner-fourteen .shape-one {
 margin: -55px 0 0 27px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Feature Section Sixteen ^^^^^^^^^^^^^^^^^^^^^^^^*/
.vcamp-feature-section-sixteen .service-title {
 font-size: 17px;
 letter-spacing: 2px;
 color: #b6b6b6;
 text-transform: uppercase;
}

.card-style-sixteen .card-title {
 font-family: "gorditamedium";
 font-size: 24px;
 color: var(--heading);
}

.card-style-sixteen ul li {
 line-height: 39px;
 color: var(--heading);
}

.card-style-sixteen ul li:hover {
 text-decoration: underline;
}

.card-style-sixteen h4 {
 font-size: 28px;
 margin: 30px 0 32px;
}

.card-style-sixteen p {
 line-height: 32px;
}

.card-style-sixteen p span {
 font-family: "gorditamedium";
 color: #000;
}

.card-style-sixteen .arrow {
 width: 54px;
 height: 50px;
 line-height: 50px;
 background: #313131;
 color: #fff;
 font-size: 22px;
 position: absolute;
 right: 0;
 bottom: 0;
 z-index: 1;
 transform: translate(100%, 100%);
 opacity: 0;
}

.card-style-sixteen .arrow:hover {
 background: #ff5332;
}

.card-style-sixteen:hover .arrow {
 opacity: 1;
 transform: translate(0px);
}

/*^^^^^^^^^^^^^^^^^^^^^ Vcamp Fancy Banner Twelve ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-banner-twelve .contact-btn {
 width: 170px;
 height: 170px;
 border-radius: 50%;
 background: #222222;
 margin: 0 auto;
}

.fancy-banner-twelve .contact-btn:hover {
 background: var(--orange-one);
}

.fancy-banner-twelve .floating-text {
 position: absolute;
 left: 0;
 bottom: 0;
 font-size: 110px;
 font-weight: 300;
 text-transform: uppercase;
 letter-spacing: 1.1px;
 line-height: initial;
 color: #d8d8d8;
 z-index: -1;
 text-align: center;
 white-space: nowrap;
 overflow: hidden;
}

.fancy-banner-twelve .floating-text span {
 display: inline-block;
 animation: marquee 100s linear infinite;
}

.faq-nav-one .list-group li {
 list-style: none;
}

// LOGIN

.new-item {
 flex: 1;
 flex-basis: 50%;
 /* o width: 50%; */
}

.illustration-holder1 {
 width: 100%;
}

.illustration-holder2 {
 width: 100%;
}

.illustration-holder1 img {
 width: auto;
 height: auto;
 width: 100%;
 height: 100vh;
 object-fit: contain;
 /* o object-fit: cover; */
 object-position: right;
 /* añade esta línea para alinear a la derecha */
}

.illustration-holder2 img {
 width: auto;
 height: auto;
 // width: 100%;
 // height: 100vh;
 object-fit: contain;
 /* o object-fit: cover; */
 object-position: right;
 /* añade esta línea para alinear a la derecha */
}

.image-center {
 display: flex;
 justify-content: center;
}

.image2 {
 display: flex;
 justify-content: left;
}
